import React, { Component } from "react";
import Table from "../common/table/table";
import { makeTableButton, combineButtons } from "../../utils/buttons";
import { CLink } from "../common/customs/customLinks";
import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";

class TeamsTable extends Component {
  columns = [
    {
      path: "logo",
      label: "",
      content: (t) => <TeamLogo team={t} name={t.name} size={35} />,
    },
    {
      path: "name",
      label: "Team Name",
      content: (t) => (
        <CLink path={`/teams?info&q=${t._id}`}>
          {t.name}
          {this.props.thisID === t._id ? <b> - (my team)</b> : ""}
        </CLink>
      ),
    },
    {
      path: "divisionName",
      label: "Division",
      content: (t) => (
        <CLink path={`/divisions?info&q=${t.divisionID}`}>
          {t.divisionName}
        </CLink>
      ),
    },
    {
      path: "teamColor1",
      label: "First",
      content: (t) =>
        t.teamColor1 ? (
          <div
            className="color-square"
            style={{ backgroundColor: t.teamColor1 }}
          />
        ) : null,
    },
    {
      path: "teamColor2",
      label: "Second",
      content: (t) =>
        t.teamColor2 ? (
          <div
            className="color-square"
            style={{ backgroundColor: t.teamColor2 }}
          />
        ) : null,
    },
    { path: "groupName", label: "Group Name" },
    { path: "seed", label: "Seed" },
    {
      path: "teamCaptainName",
      label: "Captain/Manager",
      content: (t) =>
        t.teamCaptainID ? (
          <CLink path={`/players?info&q=${t.teamCaptainID.playerID}`}>
            {allowables.splitName(t.teamCaptainID.name)}
          </CLink>
        ) : (
          ""
        ),
    },
  ];

  constructor(props) {
    super(props);
    const user = this.props.getCurrentUser();
    let buttons = [];
    if (user && user.role?.includes("admin")) {
      buttons.push(makeTableButton("delete", props.onDelete));
      if (user.role.includes("owner"))
        buttons.push(makeTableButton("purge", props.onPurge));
      buttons.push(makeTableButton("edit", "teams"));
      if (!this.props.noButtons) this.columns.push(combineButtons(buttons));
    }
  }

  render() {
    const { teamsByPage, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={teamsByPage}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
    );
  }
}

export default TeamsTable;
