import React from "react";

const Approvals = ({
  match,
  approvalRequired,
  approvals,
  onApproval,
  teamOrder,
}) => {
  if (!approvalRequired) return null;
  return (
    <div style={{ marginBottom: 10 }}>
      <div className="row">
        {teamOrder.map((team) => {
          return (
            <div className="col" key={team}>
              <button
                className={
                  "btn btn-block btn-" + (approvals[team] ? "info" : "dark")
                }
                onClick={() => onApproval(team)}
              >
                {match[team + "TeamName"] +
                  (approvals[team] ? " Approved" : " Not Approved")}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Approvals;
