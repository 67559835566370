import React from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

import { CLink } from "../common/customs/customLinks";

const PaymentStatus = ({ org, player, user }) => {
  if (!user || !user.role || !user.role.includes("player") || !player._id)
    return null;
  const paymentDue =
    org.stripe.amount > 0 &&
    ((org.stripe.whoPays === "Team" &&
      player.teamID &&
      player.teamID.teamCaptainID === user._id &&
      (!player.teamID.payment ||
        (player.teamID.payment.amountPaid !== player.teamID.payment.amountDue &&
          player.teamID.payment.amountPaid < org.stripe.amount))) ||
      (org.stripe.whoPays === "Player" &&
        (!player.payment ||
          (player.payment.amountPaid !== player.payment.amountDue &&
            player.payment.amountPaid < org.stripe.amount))))
      ? true
      : false;

  return (
    <React.Fragment>
      {paymentDue ? (
        <CLink path="/payments" style={{ margin: 5 }}>
          <ImCheckboxUnchecked />
          &nbsp;&nbsp;&nbsp;Pay your league dues
        </CLink>
      ) : (
        <CLink path="/payments" style={{ margin: 5 }}>
          <ImCheckboxChecked />
          &nbsp;&nbsp;&nbsp;League dues paid
        </CLink>
      )}
    </React.Fragment>
  );
};

export default PaymentStatus;
