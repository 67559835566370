import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IconContext } from "react-icons";

import BasicModal from "./../common/pageComponents/basicModal";
import SecondaryHeader from "./../common/pageComponents/secondaryHeader";

const Penalties = ({
  match,
  penalties,
  onChangePenalties,
  teamOrder,
  onSubmit,
}) => {
  const [open, setOpen] = useState(false);
  const changePenalties = (team, inc) => {
    let currentValue = penalties[team];
    currentValue += inc;
    onChangePenalties(team, currentValue);
  };

  const doSubmit = () => {
    setOpen(false);
    onSubmit();
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-block btn-sm btn-secondary"
        onClick={() => setOpen(true)}
      >
        Go To Penalties
      </button>
      <BasicModal
        isOpen={open}
        onClose={setOpen}
        header={
          <div className="bg-info">
            <h4 className="text-center">
              <b>Penalties</b>
            </h4>
          </div>
        }
      >
        <div className="row">
          {teamOrder.map((team) => {
            return (
              <div className="col text-center" key={team}>
                <SecondaryHeader>{match[team + "TeamName"]}</SecondaryHeader>
                <br />
                <IconContext.Provider value={{ className: "icon-light" }}>
                  <button
                    className="btn btn-success btn-lg"
                    onClick={() => changePenalties(team, 1)}
                  >
                    <FaPlus />
                  </button>
                  <br />
                  <br />
                  <h3>{penalties[team]}</h3>
                  <br />
                  <button
                    className="btn btn-danger btn-lg"
                    onClick={() => changePenalties(team, -1)}
                  >
                    <FaMinus />
                  </button>
                </IconContext.Provider>
              </div>
            );
          })}
        </div>
        <br />
        <p className="text-center">
          If you still need to handle approvals you can close the penalties
          screen, you will not lose your input
        </p>
        <button className="btn btn-block btn-info" onClick={doSubmit}>
          Submit Scorecard
        </button>
      </BasicModal>
    </React.Fragment>
  );
};

export default Penalties;
