import React from "react";

import allowables from "../../utils/allowables";
import { nameButtonText } from "../../utils/matchFunctions";
import { CBack } from "../common/customs/customLinks";
import TeamLogo from "../common/pageComponents/teamLogo";

const PreMatchSegment = ({
  matchStarted,
  startedMatchID,
  originalMatch,
  startMatch,
  toggleModal,
  teamOrder,
  teams,
  players,
  spectate,
}) => {
  const suspendedPlayers = players
    ? [
        ...players.home.filter((p) => p.suspendedFlag > 0),
        ...players.away.filter((p) => p.suspendedFlag > 0),
      ]
    : [];

  if (matchStarted && startedMatchID !== originalMatch._id)
    return (
      <React.Fragment>
        <p className="text-center">
          <b>
            Please complete or cancel scorecard already in progress before
            starting a new match
          </b>
        </p>
        <br />
        <button
          className="btn btn-info btn-block btn-md"
          onClick={() =>
            (window.location = `/assignedmatches?q=${startedMatchID}`)
          }
        >
          Go To Current Match
        </button>
        <br />
        <button
          className="btn btn-dark btn-block btn-md"
          onClick={() => toggleModal("cancelMatchOpen")}
        >
          Cancel Current Match
        </button>
      </React.Fragment>
    );
  if (!matchStarted && originalMatch.matchAccepted === 0)
    return (
      <React.Fragment>
        <h1 className="text-center">
          {teamOrder.map((t, i) => {
            return (
              <React.Fragment key={t}>
                {teams[t]?.teamColor1 && (
                  <div className="row d-flex justify-content-center">
                    <div
                      className="color-strip text-center"
                      style={{ backgroundColor: teams[t].teamColor1 }}
                    />
                  </div>
                )}
                <b>{originalMatch[t + "TeamName"]}</b>
                {teams[t]?.teamColor2 ? (
                  <div className="row d-flex justify-content-center">
                    <div
                      className="color-strip text-center"
                      style={{ backgroundColor: teams[t].teamColor2 }}
                    />
                  </div>
                ) : (
                  <br />
                )}
                {i === 0 && (
                  <React.Fragment>
                    <div
                      className="row"
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <div className="col text-right">
                        {originalMatch[teamOrder[0] + "TeamLogo"] && (
                          <TeamLogo
                            team={originalMatch[teamOrder[0] + "TeamLogo"]}
                            name={originalMatch[teamOrder[0] + "TeamName"]}
                          />
                        )}
                      </div>
                      <div className="col-3 text-center">
                        <small className="text-muted">
                          {allowables.atOrVs(teamOrder)}
                        </small>
                      </div>
                      <div className="col text-left">
                        {originalMatch[teamOrder[1] + "TeamLogo"] && (
                          <TeamLogo
                            team={originalMatch[teamOrder[1] + "TeamLogo"]}
                            name={originalMatch[teamOrder[1] + "TeamName"]}
                          />
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </h1>
        <hr />
        {originalMatch.dateTime && (
          <h3 className="text-center">
            {allowables.dateTime(originalMatch.dateTime).date +
              " " +
              allowables.dateTime(originalMatch.dateTime).time}
          </h3>
        )}
        {originalMatch.fieldName && (
          <h5 className="text-center">
            <small>Field:</small> <b>{originalMatch.fieldName}</b>
          </h5>
        )}
        {suspendedPlayers.length > 0 && (
          <div>
            <hr />
            <h4 className="text-center">
              <u>Suspended Players</u>
            </h4>
            {suspendedPlayers.map((p) => (
              <React.Fragment key={p._id}>
                <div className="row">
                  <div className="col">{nameButtonText(p)}</div>
                  <div className="col">
                    {p.teamName}: {p.suspendedFlag} Match
                    {p.suspendedFlag > 1 ? "es" : ""}
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))}
          </div>
        )}
        {startMatch && (
          <button className="btn btn-info btn-block" onClick={startMatch}>
            {originalMatch.matchComplete === 0
              ? "Start Scorecard"
              : "Edit Scorecard"}
          </button>
        )}
        <br />
        {!spectate && <CBack block={true}>Go Back</CBack>}
        <br />
        <br />
        {/* <CLink path="/help?refereeing" color={true}>
          How to referee a match
        </CLink> */}
      </React.Fragment>
    );

  return null;
};

export default PreMatchSegment;
