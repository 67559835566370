import React, { Component } from "react";
import StandingsTable from "./standingsTable";
import { getStandings } from "../../services/matchService";
import NonFormSelect from "../common/form/nonFormSelect";
import InfoTooltip from "../common/tooltip/infoTooltip";
import ExcelDownload from "../common/pageComponents/excelDownload";
import QRCodeRender from "../common/pageComponents/qrCode";
import HeaderContext from "../../context/headerContext";
import SeasonDisplay from "./seasonDisplay";

class Standings extends Component {
  static contextType = HeaderContext;
  state = {
    matches: [],
    standings: [],
    groupStandings: [],
    groups: [],
    divisions: this.props.divisions,
    selectedDivision: this.props.divisionID,
  };

  downloadColumns = [
    "position",
    "name",
    "points",
    "wins",
    "losses",
    "ties",
    "gamesPlayed",
    "goalsScored",
    "goalsConceded",
    "goalDifference",
  ];

  getRankingsList = () => {
    let rankingsList = ["Goal/Point Difference", "Goals/Points Scored"];
    if (this.props.org.misc && this.props.org.misc.rankingType === "Wins")
      rankingsList.unshift("Mini-League");
    else rankingsList.push("Mini-League");
    rankingsList.unshift("Points or Win/Loss Record");
    return rankingsList;
  };

  async componentDidMount() {
    if (this.props.divisionID)
      await this.handleChange({ target: { value: this.props.divisionID } });
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleChange = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { value: divisionID } = event.target;
    if (divisionID === "") return this.context.setLoading(false);
    const response = await getStandings(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.setState({
        standings: response.data.standings,
        groupStandings: response.data.groupStandings,
        groups: response.data.groups,
        selectedDivision: divisionID,
      });
      this.props.onDivisionChange(divisionID);
    }
    this.context.setLoading(false);
  };

  render() {
    const { divisions, selectedDivision, standings, groups, groupStandings } =
      this.state;
    const currentDivision = divisions.find((d) => d._id === selectedDivision);

    return (
      <div>
        <br />
        <SeasonDisplay season={currentDivision?.seasonID} />
        <div className="row">
          <div className="col">
            <NonFormSelect
              name="division"
              label="Select Division:&nbsp;"
              options={divisions}
              onChange={this.handleChange}
              value={selectedDivision}
            />
          </div>
          {standings.length !== 0 && (
            <React.Fragment>
              <div className="col-2">
                <InfoTooltip
                  header="Division Tables"
                  listHeader="Teams are sorted in the following order:"
                  listItems={this.getRankingsList()}
                  text={`Mini League: If two or more teams are tied on points 
                  they are separated using results between the tied teams. 
                If no distinction can be made then the ranking will be denoted by "=".
                Further ranking options will be decided by your league admin.`}
                  className="icon-md icon-info"
                  direction="bottom"
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={standings}
                  columns={this.downloadColumns}
                  dataName="standings"
                />
              </div>
            </React.Fragment>
          )}
          {this.props.qrLink && (
            <div className="col-3">
              <QRCodeRender
                value={`${this.props.qrLink}${selectedDivision}&option=standings`}
                size={100}
              />
            </div>
          )}
        </div>
        {standings.length === 0 && groups.length === 0 && selectedDivision && (
          <p>
            There have been no regular season or group matches completed in this
            division.
          </p>
        )}
        {standings.length !== 0 && (
          <StandingsTable
            data={standings}
            onSort={null}
            sortColumn={this.sortColumn}
            onChange={this.handleChange}
            sport={divisions[0].sport.toLowerCase()}
            type={this.props.org.misc?.rankingType}
          />
        )}
        {groupStandings.length !== 0 &&
          groups.map((g) => (
            <div key={g}>
              <ExcelDownload
                data={groupStandings[g]}
                columns={this.downloadColumns}
                dataName={`Group: ${g} standings`}
              />
              <StandingsTable
                data={groupStandings[g]}
                onSort={null}
                sortColumn={this.sortColumn}
                onChange={this.handleChange}
                label={"Group: " + g}
                sport={divisions[0].sport.toLowerCase()}
                type={this.props.org.misc?.rankingType}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default Standings;
