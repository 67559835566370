import React, { Component } from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";

import { getCurrentUser } from "../../../services/userService";
import OwnerPaymentHome from "./ownerPaymentHome";
import PlayerPaymentHome from "./playerPaymentHome";
import NotFound from "../../notFound";
import { navigateBack } from "../../common/customs/customLinks";
import HeaderContext from "../../../context/headerContext";

class PaymentsRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    component: NotFound,
    user: getCurrentUser(),
  };

  componentDidMount() {
    const { user } = this.state;
    if (
      !user ||
      (!user.role.includes("player") && !user.role.includes("owner"))
    )
      return navigateBack(this.props.history, this.context);
    if (user.role.includes("owner"))
      this.setState({ component: OwnerPaymentHome });
    if (user.role.includes("player"))
      this.setState({ component: PlayerPaymentHome });
  }

  render() {
    return (
      <ElementsConsumer>
        {({ stripe }) => (
          <this.state.component
            {...this.props}
            user={this.state.user}
            stripe={stripe}
          />
        )}
      </ElementsConsumer>
    );
  }
}

export default PaymentsRedirect;
