import React from "react";
import ReactGA from "react-ga4";
import Form from "../common/form/form";
import Joi from "joi-browser";
import {
  getDivision,
  saveDivision,
  deleteDivision,
} from "../../services/divisionService";
import {
  getTeamsByDivision,
  purgeTeam,
  deleteTeam,
} from "../../services/teamService";
import { getSeasons } from "../../services/seasonsService";
import toast from "../../utils/toast";
import TeamsTable from "../teams/teamsTable";
import { CLink, navigateBack, navigateTo } from "../common/customs/customLinks";
import CustomConfirm from "../common/customs/customConfirm";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";
import InfoComponent from "../common/info/infoComponent";

class DivisionForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      maxPlayersPerTeam: "",
    },
    errors: {},
    baseState: {
      name: "",
      maxPlayersPerTeam: "",
    },
    source: "edit",
    teams: [],
    org: {},
    seasons: [],
    deleteOpen: false,
    deleteTeamOpen: false,
    deleteTeamDialog: "",
    purgeOneOpen: false,
    purgeTwoOpen: false,
    purgeDialog: "",
  };

  schema = {
    name: Joi.string().required().min(3).max(50).label("Division Name"),
    seasonID: Joi.string().required().label("Season"),
    maxPlayersPerTeam: Joi.number()
      .integer()
      .optional()
      .allow("")
      .min(1)
      .max(99)
      .label("Maximum Players per Team"),
  };

  async componentDidMount(forceEdit) {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    if (this.props.org) this.setState({ org: this.props.org });
    let { source, divisionID } = this.props;
    source = forceEdit ? "edit" : source;
    const seasonsRes = await getSeasons(
      { callback: this.indicateProgress, bar: 2 },
      divisionID !== "new" && source.includes("info")
    );
    if (seasonsRes.status === 200) this.setState({ seasons: seasonsRes.data });
    if (divisionID === "new") return this.context.setLoading(false);

    const division = await getDivision(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (division.status !== 200)
      return this.props.history.replace("/not-found");

    if (source.includes("info")) {
      source = "info";
      const teamsRes = await getTeamsByDivision(divisionID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      if (teamsRes.status === 200) this.setState({ teams: teamsRes.data });
      else toast.error(teamsRes.data);
    }

    this.setState({
      data: this.mapToViewModel(division.data),
      baseState: division.data,
      source,
    });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  navigate = (path) => {
    this.props.history.push({
      pathname: path.split("?")[0],
      search: path.split("?")[1],
      state: { from: this.props.history.location.pathname },
    });
    this.context.updateHeader();
  };

  mapToViewModel(division) {
    return {
      name: division.name || "",
      seasonID: division.seasonID || "",
      maxPlayersPerTeam: division.maxPlayersPerTeam || "",
    };
  }

  getInfoData = () => {
    const season = this.state.seasons.find(
      (s) => s._id === this.state.baseState.seasonID
    );

    return {
      ...this.state.baseState,
      seasonName: season?.name,
      location: season?.location,
    };
  };

  handleSwitchToEditMode = () => {
    const editPath =
      this.props.history.location.pathname +
      this.props.history.location.search.replace("info&", "");
    navigateTo(editPath, this.props.history, this.context);
    this.componentDidMount(true);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await deleteDivision(this.props.divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      return navigateBack(this.props.history, this.context);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setDeleteDialog = (team) => {
    this.setState({
      deleteTeamDialog: `Are you sure you want to delete ${team.name}?`,
      deleteTeamOpen: true,
      selectedTeam: team,
    });
  };

  handleDeleteTeam = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedTeam: team } = this.state;
    const response = await deleteTeam(team._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Team",
        action: "delete",
        label: "deleted team from teams table",
        nonInteraction: false,
      });
      const { data: teams } = await getTeamsByDivision(this.props.divisionID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      toast.success(`${team.name} deleted successfully`);
      this.setState({ teams });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setPurgeDialog = (team) => {
    this.setState({
      purgeDialog: `This will delete all of the players and matches from ${team.name}, along with the team itself.`,
      purgeOneOpen: true,
      selectedTeam: team,
    });
  };

  handlePurgeOne = () => {
    this.setState({ purgeTwoOpen: true });
  };

  handlePurge = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedTeam: team } = this.state;
    const response = await purgeTeam(team._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Team",
        action: "delete",
        label: "purged team from teams table",
        nonInteraction: false,
      });
      const { data: teams } = await getTeamsByDivision(this.props.divisionID, {
        callback: this.indicateProgress,
        bar: 1,
      });

      toast.success("Team purged succesfully.");
      this.setState({ teams });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const divisionID = this.props.divisionID;
    let data = { ...this.state.data };
    data.sport = this.state.org.sport;
    const response = await saveDivision(divisionID, data, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200)
      return this.props.history.replace("/divisions");
    this.setState({ apiError: response.data });
    toast.error(response.data);
    if (this.state.apiError) {
      this.setState({ data: this.mapToViewModel(this.state.data) });
    }
    this.context.setLoading(false);
  };

  render() {
    const {
      source,
      teams,
      deleteOpen,
      seasons,
      deleteTeamOpen,
      deleteTeamDialog,
      purgeOneOpen,
      purgeTwoOpen,
      purgeDialog,
    } = this.state;
    const disabled = source === "info" ? "disabled" : "";

    return (
      <div className="centered-small-input-area">
        {seasons.length === 0 ? (
          <div>
            <WarningHeader>
              You must create at least one season before adding a division
            </WarningHeader>
            <CLink
              path="/seasons?q=new"
              button={true}
              buttonStyle="btn-md btn-info btn-block"
            >
              Add New Season
            </CLink>
          </div>
        ) : (
          <div>
            {disabled ? (
              <InfoComponent
                data={this.getInfoData()}
                fields={[
                  {
                    name: "Season",
                    property: "seasonName",
                    link: `/seasons?info&q=${this.state.baseState.seasonID}`,
                  },
                  {
                    name: "Maximum Rostered Players",
                    property: "maxPlayersPerTeam",
                  },
                  { name: "Location", property: "location" },
                  {
                    name: "Editing Locked",
                    property: "editingLocked",
                    type: "checkbox",
                  },
                  {
                    name: "Rosters Locked",
                    property: "rostersLocked",
                    type: "checkbox",
                  },
                ]}
                handleSwitchToEditMode={this.handleSwitchToEditMode}
              />
            ) : (
              this.renderWholeForm(
                "Division",
                "/divisions",
                {
                  listHeader: "Required Fields",
                  listItems: ["Division Name", "Season"],
                  direction: "right",
                  text: `Each division you create will track standings, brackets and leaderboards separately.
                    A team needs to be tied to a division at all times.`,
                },
                <div>
                  {" "}
                  {this.renderInput(
                    "name",
                    "* Division Name",
                    "autofocus",
                    "text",
                    disabled,
                    "",
                    "",
                    {
                      header: "Division Name",
                      text: "This field is required. It will be used on team and standing screens to separate data.",
                      direction: "right",
                      className: "icon-mobile",
                    }
                  )}
                  {this.renderSelectByValueOption(
                    "seasonID",
                    `* Season`,
                    this.state.seasons,
                    "_id",
                    disabled,
                    {
                      header: "Season",
                      text: ``,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    null,
                    {
                      onClick: () =>
                        navigateTo(
                          "/seasons?q=new",
                          this.props.history,
                          this.context
                        ),
                      tooltipText: "Add New Season",
                      icon: "add",
                      class: "btn-info",
                    }
                  )}
                  {this.renderInput(
                    "maxPlayersPerTeam",
                    "Maximum Players per Team",
                    "",
                    "number",
                    disabled,
                    "99",
                    "1",
                    {
                      header: "Maximum Players per Team",
                      text: `The maximum number of players that can be on a single team. If blank no maximum will be applied.`,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    "1"
                  )}
                </div>
              )
            )}
            {source === "info" && (
              <React.Fragment>
                <br />
                <h3>Registered Teams</h3>
                <CLink
                  path="/teams?q=new"
                  button={true}
                  buttonStyle="btn-md btn-info"
                >
                  Add New Team
                </CLink>
                {teams.length > 0 ? (
                  <TeamsTable
                    teamsByPage={teams}
                    onDelete={this.setDeleteDialog}
                    onPurge={this.setPurgeDialog}
                    getCurrentUser={this.props.getCurrentUser}
                  />
                ) : (
                  <p>There are no teams registered for this division.</p>
                )}
              </React.Fragment>
            )}
            <CustomConfirm
              dialog={`Are you sure you want to delete ${this.state.baseState.name}?`}
              isOpen={deleteOpen}
              callback={this.handleDelete}
              close={this.toggleModal}
              id={"deleteOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={deleteTeamDialog}
              isOpen={deleteTeamOpen}
              callback={this.handleDeleteTeam}
              close={this.toggleModal}
              id={"deleteTeamOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={purgeDialog}
              isOpen={purgeOneOpen}
              callback={this.handlePurgeOne}
              close={this.toggleModal}
              id={"purgeOneOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={`Are you sure you wish to proceed? This cannot be undone...`}
              isOpen={purgeTwoOpen}
              callback={this.handlePurge}
              close={this.toggleModal}
              id={"purgeTwoOpen"}
              focused={true}
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default DivisionForm;
