import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { ToastContainer, toast } from "react-toastify";
import HttpsRedirect from "react-https-redirect";

import "./App.css";
import {
  getCurrentUser,
  getCookieAcceptance,
  acceptCookies,
  revokeCookieAcceptance,
  getUserAccounts,
  switchAccount,
} from "./services/userService";
import { getVersionByFull } from "./services/versionService";
import { getOrg } from "./services/orgService";
import NavBar from "./components/navbar";
import packageJSON from "../package.json";
import ContactingServer from "./components/common/loading/contactingServer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "./utils/paymentsUtil";
import OrgContext from "./context/orgContext";
import HeaderContext from "./context/headerContext";
import LoadingModal from "./components/common/loading/loadingModal";
import allowables from "./utils/allowables";
// import MobileAppDownloadPrompt from "./components/common/pageComponents/mobileAppDownloadPrompt";
import MainSwitchRouter from "./components/mainSwitchRouter";
import ErrorBoundary from "./components/errorBoundary";
import ContactUsModal from "./components/contactPolicies/contactUsModal";
import CookieAcceptancePrompt from "./components/common/pageComponents/cookieAcceptancePrompt";
import { cancelMatchInProgress } from "./utils/liveScoresUtil";
// eslint-disable-next-line no-unused-vars
import DevAlert from "./components/dev/devAlert";

const stripePromise = loadStripe(stripeKey());
ReactGA.initialize(process.env.REACT_APP_GA || "x", {
  testMode: process.env.NODE_ENV !== "production",
});

const appReloadCount = "appReloads";

class App extends Component {
  state = {
    upToDate: false,
    ready: false,
    org: null,
    user: null,
    pageHeader: null,
    showCookieAcceptance: false,
    cookiePromptActive: false,
    cookiesAccepted: false,
    cookiesDeclined: false,
    stickyTopNavBar: true,
    mobileAppPromptOpen: true,
    loading: false,
    progress: [0],
    contactUsOpen: false,
    userAccounts: [],
    allowStickyItems: true,
  };

  async componentDidMount() {
    // add listener for back button to update header
    window.addEventListener("popstate", () => {
      this.updateHeader();
    });

    // get user and look for org
    const user = getCurrentUser();
    if (user) {
      if (user.orgID) await this.refreshOrg();

      // get the profiles for the user
      let profileResponse = await getUserAccounts();
      if (profileResponse.status === 200)
        this.setState({ userAccounts: profileResponse.data });
    }
    this.setState({ user });

    // get lateset version
    const response = await getVersionByFull(packageJSON.version);
    if (response && response.status === 200) {
      if (
        packageJSON.version !== response.data.full ||
        response.data.status === "obsolete" ||
        response.data.status === "deprecated"
      ) {
        console.log("Gathering latest version from server");
        if (Number(localStorage.getItem(appReloadCount)) >= 10) {
          localStorage.setItem(appReloadCount, "0");
          if (response.data.status === "obsolete")
            return this.setState({ ready: true, upToDate: false });
        } else {
          localStorage.setItem(
            appReloadCount,
            Number(localStorage.getItem(appReloadCount)) + 1
          );
          return this.reloadApp(true);
        }
      }

      this.setState({
        upToDate: true,
        ready: true,
        cookiesAccepted: getCookieAcceptance(),
      });
      console.log(
        `Ultimate Scoreboard: running ${response.data.status} version ${packageJSON.version}`
      );
      console.log(`Deployed ${response.data.released}`);
      localStorage.setItem(appReloadCount, "0");
    } else {
      console.log(
        response
          ? `Version is not valid: ${packageJSON.version}.`
          : "Could not contact server."
      );
      this.setState({ ready: true });
    }

    this.updateHeader();
  }

  refreshOrg = async () => {
    const orgRes = await getOrg();
    if (orgRes && orgRes.status === 200) this.setState({ org: orgRes.data });
  };

  reloadApp = (bool) => {
    window.location.reload(bool);
  };

  handleAcceptCookies = () => {
    acceptCookies();
    this.setState({ cookiesAccepted: true });
  };

  handleDeclineCookies = () => {
    this.setState({ cookiesDeclined: true });
  };

  handleRejectCookies = () => {
    revokeCookieAcceptance(false);
    this.setState({ ready: true, upToDate: true, cookiesAccepted: false });
  };

  closePopup = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  logPageView = () => {
    // log pageview to google analytics (except for superadminview)
    if (!window.location.pathname.includes("superadminview")) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  };

  updateHeader = () => {
    const path = window.location.pathname;
    this.logPageView();
    const pageHeader = allowables.pageHeaderConversion(path);

    document.title = `Ultimate Scoreboard${
      pageHeader ? ` | ${pageHeader}` : ""
    }`;

    this.setState({
      pageHeader,
      stickyTopNavBar: path === "/",
    });
  };

  switchAccountInUse = async (account) => {
    await cancelMatchInProgress();
    this.setState({ loading: true, progress: [1] });
    const response = await switchAccount(account, {
      callback: (p) => this.setState({ progress: p }),
      bar: 0,
    });
    if (response.status === 200) {
      localStorage.clear();
      // restart user at profile page, remaining on same page causes too many issues
      return (window.location = "/profile");
    } else {
      toast.error(response.data);
    }
    this.setState({ loading: false });
  };

  setFullAppLoading = () => {
    this.setState({ ready: false });
  };

  render() {
    const {
      user,
      upToDate,
      ready,
      org,
      userAccounts,
      pageHeader,
      cookiePromptActive,
      cookiesAccepted,
      cookiesDeclined,
      stickyTopNavBar,
      // mobileAppPromptOpen,
      loading,
      progress,
      contactUsOpen,
      allowStickyItems,
    } = this.state;
    return (
      <ErrorBoundary user={user}>
        <DevAlert />
        <HeaderContext.Provider
          value={{
            pageHeader,
            updateHeader: this.updateHeader,
            refreshOrg: this.refreshOrg,
            loading,
            setLoading: (value) => this.setState({ loading: value }),
            progress,
            setProgress: (value) => this.setState({ progress: value }),
            toggleContactForm: () =>
              this.setState({ contactUsOpen: !contactUsOpen }),
            userAccounts,
            switchAccountInUse: this.switchAccountInUse,
            allowStickyItems,
            setAllowStickyItems: (bool) => {
              this.setState({ allowStickyItems: bool });
            },
          }}
        >
          <ToastContainer limit={2} />
          <CookieAcceptancePrompt
            cookiePromptActive={cookiePromptActive}
            cookiesAccepted={cookiesAccepted}
            cookiesDeclined={cookiesDeclined}
            onAcceptCookies={this.handleAcceptCookies}
            onDeclineCookies={this.handleDeclineCookies}
          />
          {!upToDate ? (
            <ContactingServer
              ready={ready}
              refresh={() => this.reloadApp(true)}
              upToDate={this.state.upToDate}
            />
          ) : (
            <HttpsRedirect>
              <Elements stripe={stripePromise}>
                <LoadingModal loading={loading} progress={progress} />
                <OrgContext.Provider value={org}>
                  <BrowserRouter>
                    {/* {user && !user.role?.includes("admin") && (
                      <MobileAppDownloadPrompt
                        isOpen={mobileAppPromptOpen}
                        id="mobileAppPromptOpen"
                        onClose={this.closePopup}
                      />
                    )} */}
                    <NavBar user={user} org={org} sticky={stickyTopNavBar} />
                    <br />
                    <main
                      className="container-fluid"
                      style={{
                        paddingLeft: 25,
                        paddingRight: 25,
                      }}
                    >
                      <div className="content">
                        <MainSwitchRouter org={org} />
                      </div>
                    </main>

                    <ContactUsModal
                      isOpen={contactUsOpen}
                      setOpen={() => this.closePopup("contactUsOpen")}
                      org={org}
                    />
                  </BrowserRouter>
                </OrgContext.Provider>
              </Elements>
            </HttpsRedirect>
          )}
        </HeaderContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
