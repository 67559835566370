import React from "react";
import toast from "../../../utils/toast";

import { getCurrentUser } from "../../../services/userService";
import allowables from "../../../utils/allowables";
import Tooltip from "../tooltip/tooltip";

const ReferralLink = () => {
  const copyToClipboard = () => {
    const user = getCurrentUser();
    navigator.clipboard.writeText(
      `${allowables.url}/register?ref=${user?.profileID?._id || "link_fail"}`
    );
    toast.success("Referral Link Copied to Clipboard");
  };

  return (
    <Tooltip
      content={
        <span>
          <p>
            <b>Get paid up to $150 for your referral</b>
          </p>
          <p>
            Do you know a league that could benefit from using Ultimate
            Scoreboard? Click the button to get your referral link and if the
            league signs up we'll pay you their first months subscription.
          </p>
          <small>
            Please see the Referrals section of the Ultimate Scoreboard Policies
            below for terms and conditions
          </small>
        </span>
      }
      direction="top"
    >
      <button
        onClick={copyToClipboard}
        className="btn btn-block btn-light btn-md"
      >
        Refer a League and get Paid
      </button>
    </Tooltip>
  );
};

export default ReferralLink;
