import React from "react";

export const freeTier = {
  name: "Free",
  id: "free",
  description: "up to 6 teams",
  cycle: "monthlyyearly",
  unit_amounts: {
    monthlyyearly: { unit_amount: 0 },
    monthly: { unit_amount: 0 },
    yearly: { unit_amount: 0 },
  },
};

export const getConnectedAccountFeeInfo = () => {
  return {
    stripePerTransaction: 0.3,
    stripePercentage: 2.9,
    usbPerTransaction: 2.0,
    usbPercentage: 0.5,
  };
};

export function getSubscriptionAmount(amount) {
  const amt = amount / 100;
  if (amt < 0) return `$${(amt * -1).toFixed(2)}`;
  else return `$${amt.toFixed(2)}`;
}

export function estimateProration(customer, currentTier, newTier, revert) {
  let newCharges =
    !revert && newTier.unit_amounts[newTier.cycle]
      ? newTier.unit_amounts[newTier.cycle].unit_amount
      : 0;
  let creditAmount = 0;
  let amountToBill = newCharges;
  let remainingCredit = 0;
  const customerBalance = customer.balance;
  if (currentTier && currentTier.status !== "past_due" && !revert) {
    const oldPrice = currentTier.unit_amounts[currentTier.cycle].unit_amount;
    const customerSub = customer.subscriptions.data[0];
    const periodEnd = new Date(
      customerSub?.current_period_end * 1000
    ).getTime();
    const subPeriod =
      (periodEnd - new Date(customerSub?.current_period_start * 1000)) /
      (1000 * 60 * 60 * 24);
    const daysRemaining =
      (periodEnd - new Date().getTime()) / (1000 * 60 * 60 * 24);
    creditAmount = (oldPrice / subPeriod) * daysRemaining;

    if (currentTier.cycle === newTier.cycle) {
      newCharges = (newCharges / subPeriod) * daysRemaining;
    }

    remainingCredit =
      creditAmount - newCharges <= 0 ? 0 : creditAmount - newCharges;
    amountToBill =
      newCharges - creditAmount <= 0 ? 0 : newCharges - creditAmount;
  }
  amountToBill =
    amountToBill + customerBalance <= 0 ? 0 : amountToBill + customerBalance;

  return {
    newCharges: getSubscriptionAmount(newCharges),
    creditAmount: getSubscriptionAmount(creditAmount),
    amountToBill: getSubscriptionAmount(amountToBill),
    remainingCredit: {
      exists: remainingCredit > 0,
      string: getSubscriptionAmount(remainingCredit),
    },
    customerBalance: getSubscriptionAmount(customerBalance),
  };
}

export function getTierDescription(tier, active) {
  const amount = tier.unit_amounts
    ? tier.unit_amounts[tier.cycle].unit_amount
    : tier.price;
  const child =
    !tier || tier.id === "free" ? (
      <div>
        {freeTier.name}: {freeTier.description}
      </div>
    ) : (
      <div>
        {tier.name}: {tier.description}
        <br />
        <i>
          Billed {tier.cycle} at {getSubscriptionAmount(amount)}
        </i>
      </div>
    );
  return active ? child : child;
}

export function stripeKey() {
  return process.env.NODE_ENV === "production"
    ? "pk_live_51ICSJ1KBPtxgKzXZ2i7hR9K25ORtK4kHvi8dETF37iZwFOt9ZkEoaudIZBCsNAvoNggo16YGXJ0XMZpZUzamw9Bn00lw63rsDd"
    : "pk_test_51ICSJ1KBPtxgKzXZQ6UjaNNo9rXD3syghaWeiUKjSSI5r04pCpgoHCWcj63Px7xJiDP3iMMd8UZdn66JmnVVNwwh00RvZblR8q";
}
