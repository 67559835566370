import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import { sendContactUsEmail } from "../../services/emailService";
import { getCurrentUser } from "../../services/userService";
import FinePrintModal from "./finePrintModal";
import allowables from "../../utils/allowables";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import HeaderContext from "../../context/headerContext";
import LeagueContactInfo from "../leagueWebpage/leagueContactInfo";
import MiniHeader from "../common/pageComponents/miniHeader";

class ContactUsModal extends Form {
  static contextType = HeaderContext;

  state = {
    data: {
      name: "",
      email: "",
      subject: "",
      body: "",
    },
    autofocus: "name",
    errors: {},
    apiError: null,
    finePrintOpen: false,
    isOpen: false,
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (user) {
      const data = {
        name: allowables.splitName(user.name),
        email: user.email,
        subject: "",
        body: "",
      };
      this.setState({ data, autofocus: "subject" });
    }
  }

  schema = {
    name: Joi.string().required().max(99).label("Name"),
    email: Joi.string().email().required().max(999).label("eMail"),
    subject: Joi.string().required().min(1).max(999).label("Subject"),
    body: Joi.string().required().min(10).max(2000).label("Text"),
  };

  textChange = (text) => {
    const data = { ...this.state.data };
    data.body = text;
    this.setState({ data });
  };

  handleReset = (event) => {
    if (event) event.preventDefault();
    this.setState({
      data: { name: "", email: "", subject: "", body: "" },
      errors: {},
    });
  };

  toggleFinePrint = (event) => {
    if (event) event.preventDefault();
    this.setState({ finePrintOpen: this.state.finePrintOpen ? false : true });
  };

  doSubmit = async () => {
    // temporarily hide the form to show the loading modal
    this.props.setOpen(false);
    this.context.setLoading(true);
    const res = await sendContactUsEmail(this.state.data);
    if (res.status === 200) {
      toast.success(res.data);
      this.handleReset();
    } else {
      toast.error(res.data);
      this.props.setOpen(true);
    }
    this.setState({ apiError: res.data });
    this.context.setLoading(false);
  };

  render() {
    const { isOpen, setOpen, org } = this.props;

    return (
      <BasicModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        style={{ content: { ...popupStyle.content, height: "65%" } }}
      >
        {/* contact section for league */}
        <LeagueContactInfo org={org} />
        <hr />
        <div>
          {/* contact form for Ultimate Scoreboard */}
          <MiniHeader>Contact Ultimate Scoreboard Support</MiniHeader>
          <div className="form-divided-section">
            <form onSubmit={this.handleSubmit}>
              {this.state.apiError && (
                <div className="alert alert-warning">{this.state.apiError}</div>
              )}
              {this.renderInput(
                "name",
                "Name",
                this.state.autofocus === "name" ? "autofocus" : ""
              )}
              {this.renderInput("email", "Email")}
              {this.renderInput(
                "subject",
                "Subject",
                this.state.autofocus === "subject" ? "autofocus" : ""
              )}
              <textarea
                rows="5"
                cols="50"
                style={{ width: "100%" }}
                name="Body"
                id="body"
                value={this.state.data.body}
                placeholder={`Enter your question...`}
                onChange={(e) => this.textChange(e.target.value)}
              />
              <div className="row">
                <div className="col">
                  {this.renderValidatedButton("Submit")}
                </div>
                <div className="col text-right">
                  {this.renderResetButton("Clear Form")}
                </div>
              </div>
            </form>
          </div>
          <FinePrintModal
            finePrintOpen={this.state.finePrintOpen}
            closeFinePrint={this.toggleFinePrint}
          />
        </div>
      </BasicModal>
    );
  }
}

export default ContactUsModal;
