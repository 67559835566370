import React, { Component } from "react";
import {
  loginUser,
  getCurrentUser,
  updatePassword,
  getUserAccounts,
} from "../../services/userService";
import toast from "../../utils/toast";
import { Redirect } from "react-router-dom";
import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import CombinedRegistrationForm from "./combinedRegistrationForm";
import AccountSelector from "./accountSelector";

class LoginForm extends Component {
  static contextType = HeaderContext;
  state = {
    data: {
      email: "",
      password: "",
    },
    token: true,
    emailOpen: false,
    resetEmail: "",
    accountSelectorOpen: false,
    userAccounts: [],
    user: null,
  };

  componentDidMount() {
    this.context.setLoading(true);
    const user = getCurrentUser();
    this.setState({ user });
    const newData = { ...this.state.data };
    if (this.props.location?.search) {
      const queryParams = this.props.location.search.split("&");
      let email = queryParams.find((q) => q.includes("email"));
      email = email && email.split("=")[1] ? email.split("=")[1] : "";
      let token = queryParams.find((q) => q.includes("token"));
      token = token && token.split("=")[1] ? token.split("=")[1] : "";
      newData.email = email;
      this.setState({ data: newData, token });
    } else this.setState({ token: null });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  doSubmit = async (data) => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const loginData = {
      email: data.email.trim().toLowerCase(),
      password: data.password,
    };

    if (this.state.token) {
      const update = await updatePassword(this.state.token, loginData, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (update.status !== 200) {
        this.context.setLoading(false);
        return toast.error(
          update.data.length < 10 ? update.data[0] : update.data
        );
      }
    }

    const response = await loginUser(loginData, {
      callback: this.indicateProgress,
      bar: 1,
    });

    if (response.status === 200) {
      const accounts = await getUserAccounts();
      if (
        accounts &&
        accounts.status === 200 &&
        (accounts.data?.length || 0) > 1
      ) {
        return this.setState({
          accountSelectorOpen: true,
          userAccounts: accounts.data,
        });
      }

      return (window.location =
        this.props.location?.state?.from?.pathname || "/profile");
    } else toast.error(response.data);

    this.context.setLoading(false);
  };

  render() {
    const { user } = this.state;
    if (user && !this.state.token && !this.state.accountSelectorOpen)
      return <Redirect to="/profile" />;

    return (
      <div>
        <div className="centered-small-input-area">
          <MiniHeader>
            {this.state.token ? "Enter New Password" : "Login"}
          </MiniHeader>
          <div className="form-divided-section">
            <CombinedRegistrationForm
              only={"login"}
              data={this.state.data}
              token={this.state.token}
              onSubmit={this.doSubmit}
            />
          </div>
        </div>
        <AccountSelector
          isOpen={this.state.accountSelectorOpen}
          setIsOpen={() => this.setState({ accountSelectorOpen: false })}
          userAccounts={this.state.userAccounts}
        />
      </div>
    );
  }
}

export default LoginForm;
