import React from "react";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";

import Tooltip from "../tooltip/tooltip";
import PaidFeature from "../tooltip/paidFeature";
import PasswordRequirements from "../../loginLogoutRegister/passwordRequirements";
import InfoTooltip from "../tooltip/infoTooltip";
import TooltipButton from "../tooltip/tooltipButton";
import IconRender from "../icons/iconRender";

const FormLabel = ({
  tooltip,
  paidFeature,
  label,
  htmlFor,
  type,
  updatedType,
  showPassword,
  hidePassword,
  labelStyle,
  labelButton,
}) => {
  return (
    <div className="row">
      <div className="col-xs">{tooltip && <InfoTooltip {...tooltip} />}</div>
      <div className="col-xs text-left">{paidFeature && <PaidFeature />}</div>
      <div className="col">
        <label htmlFor={htmlFor} style={labelStyle}>
          {label}
        </label>
      </div>
      {labelButton && (
        <div
          className="col-2 text-center"
          aria-label={`labelButton-${labelButton.tooltipText}`}
        >
          <TooltipButton
            buttonClass={`btn btn-sm ${labelButton.class}`}
            onClick={labelButton.onClick}
            tooltipText={labelButton.tooltipText}
            tooltipDirection="left"
            testId={`label-button-${label}`}
          >
            <IconRender name={labelButton.icon} />
          </TooltipButton>
        </div>
      )}
      {type === "password" && (
        <div className="col text-left">
          <Tooltip
            content={
              <div style={{ width: 150 }}>
                <PasswordRequirements />
                <small>
                  Click to {updatedType === "password" ? "show" : "hide"} text
                </small>
              </div>
            }
            direction="left"
          >
            {type === "password" && updatedType === "password" && (
              <FaEyeSlash onClick={showPassword} />
            )}
            {type === "password" && updatedType === "text" && (
              <FaRegEye onClick={hidePassword} />
            )}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default FormLabel;
