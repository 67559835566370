import React from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const ImageStatus = ({ org, user }) => {
  if (!user || !org || !org.misc || org.misc.userImages !== "Yes") return null;
  const imageUploaded = user.profileID && user.profileID.hasAvatar;

  return (
    <React.Fragment>
      {!imageUploaded ? (
        <div style={{ margin: 5 }}>
          <ImCheckboxUnchecked />
          &nbsp;&nbsp;&nbsp;Upload your profile image
        </div>
      ) : (
        <div style={{ margin: 5 }}>
          <ImCheckboxChecked />
          &nbsp;&nbsp;&nbsp;Profile image uploaded
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageStatus;
