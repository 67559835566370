import React from "react";
import { CLink } from "../common/customs/customLinks";
import IconRender from "../common/icons/iconRender";

const NextMatchStatus = ({ nextMatch, user }) => {
  if (!nextMatch) return null;

  const response = nextMatch.checkedIn?.find(
    (p) => p.playerID === user.playerID
  );

  return (
    <CLink path={`/matches?q=${nextMatch._id}`} style={{ margin: 5 }}>
      <IconRender name={`${response ? "" : "un"}checkedbox`} />
      &nbsp;&nbsp;&nbsp;
      {response
        ? "You have responded for your next match"
        : "Check in for your next match"}
      {response ? (
        <small>
          : <b>{response?.playing ? "IN" : "OUT"}</b>
        </small>
      ) : null}
    </CLink>
  );
};

export default NextMatchStatus;
