import React, { useContext } from "react";
import HeaderContext from "../../context/headerContext";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import { getAccountText } from "../../utils/textUtils";
import MainTitle from "../common/loading/mainTitle";

const AccountSelector = ({ isOpen, setIsOpen, userAccounts = [] }) => {
  const { switchAccountInUse } = useContext(HeaderContext);

  const handleChooseAccount = (account) => {
    setIsOpen(false);
    return switchAccountInUse(account);
  };

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={setIsOpen}
      style={{ content: { ...popupStyle.content, maxWidth: 500 } }}
      header={
        <div style={{ marginBottom: 15 }}>
          <h3 className="text-center">
            <b>Select Account</b>
          </h3>
          <MainTitle orientation="portrait" />
        </div>
      }
      shouldCloseOnOverlayClick={false}
      hideCloseButton={true}
    >
      <div>
        {userAccounts.map((ua, idx) => {
          return (
            <div
              className={"clickable pop-box pop-box-hover"}
              onClick={() => handleChooseAccount(ua)}
              key={idx}
            >
              {getAccountText(ua, idx)}
            </div>
          );
        })}
      </div>
    </BasicModal>
  );
};

export default AccountSelector;
