import React from "react";
import ReactGA from "react-ga4";

const MobileAppLinks = ({ standAlone, stack }) => {
  const androidLink = (
    <a
      onClick={() =>
        ReactGA.event({
          category: "Mobile App",
          action: "clicked android app link",
          nonInteraction: false,
        })
      }
      href="https://play.google.com/store/apps/details?id=com.ultimatescoreboard"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/appStores/google-play-badge.png"
        alt="Google Play Store Logo"
        className="app-store-logo"
      />
    </a>
  );

  const appleLink = (
    <a
      onClick={() =>
        ReactGA.event({
          category: "Mobile App",
          action: "clicked iOS app link",
          nonInteraction: false,
        })
      }
      href="https://apps.apple.com/app/ultimate-scoreboard-mobile/id1576931083"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/appStores/apple-app-store-badge-black.svg"
        alt="Apple App Store Logo"
        className="app-store-logo apple"
      />
    </a>
  );
  return (
    <div
      className="text-center"
      style={{
        ...(standAlone ? { width: 250, position: "absolute", right: 20 } : {}),
      }}
    >
      {!stack ? (
        <div className="row">
          <div className="col app-store-logo-container">{appleLink}</div>
          <div className="col app-store-logo-container">{androidLink}</div>
        </div>
      ) : (
        <div style={{ height: 50 }}>
          <div className="app-store-logo-container">{appleLink}</div>
          <br />
          <div className="app-store-logo-container">{androidLink}</div>
        </div>
      )}
      {standAlone && (
        <React.Fragment>
          <br />
        </React.Fragment>
      )}
    </div>
  );
};

export default MobileAppLinks;
