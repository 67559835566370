import { toast } from "react-toastify";

const renderToastIcon = () => (
  <img
    src="/logos/iconOnSplash.png"
    alt="usb-square"
    width={20}
    height={20}
    style={{ borderRadius: 5 }}
  />
);

const toastOptions = {
  position: "bottom-center",
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  icon: renderToastIcon,
};

const toasts = {
  error: (text, dismissFirst) => {
    if (dismissFirst) toast.dismiss();
    toast.error(text, toastOptions);
  },
  info: (text) => {
    toast.info(text, toastOptions);
  },
  success: (text) => {
    toast.success(text, toastOptions);
  },
  warn: (text) => {
    toast.warn(text, toastOptions);
  },
  dismissAll: () => {
    toast.dismiss();
  },
};

export default toasts;
