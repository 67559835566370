import React from "react";

import SoccerLeaderboardTable from "./soccer/soccerLeaderboardTable";
import FootballLeaderboardTable from "./football/footballLeaderboardTable";
import DiamondLeaderboardTable from "./diamond/diamondLeaderboardTable";
import MiniHeader from "../common/pageComponents/miniHeader";

const MyHistory = ({ historicalData }) => {
  if (!historicalData) return null;

  return (
    <div>
      {historicalData.totals?.Soccer && (
        <div>
          <MiniHeader iconName="soccer"> Soccer </MiniHeader>
          <div className="form-divided-section">
            <SoccerLeaderboardTable
              data={historicalData.totals.Soccer}
              searchQuery={null}
              onSelectTable={() => {}}
              spectate={true}
              isHistorical={true}
              keyProperty={"orgID"}
            />
          </div>
        </div>
      )}
      {historicalData.totals?.Football && (
        <div>
          <MiniHeader iconName="football"> Football </MiniHeader>
          <div className="form-divided-section">
            <FootballLeaderboardTable
              data={historicalData.totals.Football}
              searchQuery={null}
              onSelectTable={() => {}}
              spectate={true}
              isHistorical={true}
              keyProperty={"orgID"}
            />
          </div>
        </div>
      )}
      {historicalData.totals["Diamond Sports"] && (
        <div>
          <MiniHeader iconName="diamond">
            {" "}
            Baseball/Softball/Kickball{" "}
          </MiniHeader>
          <div className="form-divided-section">
            <DiamondLeaderboardTable
              data={historicalData.totals["Diamond Sports"]}
              searchQuery={null}
              onSelectTable={() => {}}
              spectate={true}
              isHistorical={true}
              keyProperty={"orgID"}
              isMyHistory={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyHistory;
