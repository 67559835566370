import React from "react";
import MiniHeader from "../../common/pageComponents/miniHeader";
import IconRender from "../../common/icons/iconRender";

const Customer = ({
  customer,
  onRemovePaymentMethod,
  onAddCustomer,
  onDeleteCustomer,
  setCardOpen,
}) => {
  if (!customer)
    return (
      <React.Fragment>
        <div className="text-center">
          It looks like your league isn't on our customer list. Click the button
          below to create an account to get started on a subscription.
        </div>
        <br />
        <button className="btn btn-info btn-block" onClick={onAddCustomer}>
          Add League to Customer List
        </button>
      </React.Fragment>
    );

  const card = customer.defaultCard ? customer.defaultCard.card : null;

  return (
    <div>
      <MiniHeader>Payment Method</MiniHeader>
      <div className="form-divided-section text-center">
        <b>Payment Card</b>
        <br />
        {card ? (
          <p>
            <IconRender name={card.brand} className="icon-mobile" />
            &nbsp;{card.brand.charAt(0).toUpperCase() +
              card.brand.slice(1)}{" "}
            ending in {card.last4}
            <br />
            Expires {card.exp_month}/{String(card.exp_year).slice(2)}
          </p>
        ) : (
          <p>Add a Payment Card to Subscribe</p>
        )}
        <div className="row">
          <div className="col">
            {card && (
              <button
                className="btn btn-sm btn-dark btn-block"
                onClick={onRemovePaymentMethod}
              >
                Remove Payment Method
              </button>
            )}
          </div>
          <div className="col">
            <button
              className="btn btn-sm btn-info btn-block"
              onClick={() => setCardOpen(true)}
            >
              {card ? "Replace" : "Add"} Card
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
