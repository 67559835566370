import React, { Component } from "react";
import Standings from "./standings";
import Leaderboards from "./leaderboard";
import Brackets from "./brackets";
import { getDivisions } from "../../services/divisionService";
import QuickLinks from "../common/pageComponents/quickLinks";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import allowables from "../../utils/allowables";

class Reports extends Component {
  state = {
    openComponent: null,
    divisionID: "",
    divisions: [],
  };

  async componentDidMount() {
    const { data: divisions } = await getDivisions();
    const openComponent = this.props.location.search
      ? this.props.location.search.split("=")[0].slice(1)
      : null;
    const divisionID = this.props.location.search
      ? this.props.location.search.split("=")[1]
      : "";
    this.setState({ openComponent, divisionID, divisions });
  }

  handleReportChange = (eventID) => {
    this.props.history.replace(`?${eventID}=${this.state.divisionID}`);
    this.setState({ openComponent: eventID });
  };

  handleDivisionChange = (divisionID) => {
    this.props.history.replace(`?${this.state.openComponent}=${divisionID}`);
    this.setState({ divisionID });
  };

  render() {
    const { openComponent, divisionID, divisions } = this.state;
    const qrLink =
      this.props.org &&
      this.props.org.spectatingAllowed &&
      this.props.org.spectatingAllowed.toLowerCase() !== "blocked"
        ? `${allowables.url}/deeplink?pathname=${this.props.org.spectatingAllowed}&division=`
        : null;

    return (
      <React.Fragment>
        <HorizontalButtons
          buttons={["standings", "brackets", "leaderboards"]}
          selectedType={openComponent}
          onClick={this.handleReportChange}
        />
        {openComponent === "brackets" && (
          <Brackets
            divisionID={divisionID}
            divisions={divisions}
            onDivisionChange={this.handleDivisionChange}
            path={
              this.props.history.location.pathname +
              this.props.history.location.search
            }
            qrLink={qrLink}
          />
        )}
        {openComponent === "leaderboards" && (
          <Leaderboards
            divisionID={divisionID}
            divisions={divisions}
            onDivisionChange={this.handleDivisionChange}
            qrLink={qrLink}
          />
        )}
        {openComponent === "standings" && (
          <Standings
            divisionID={divisionID}
            divisions={divisions}
            onDivisionChange={this.handleDivisionChange}
            org={this.props.org}
            qrLink={qrLink}
          />
        )}
        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
      </React.Fragment>
    );
  }
}

export default Reports;
