import React, { useState, useEffect } from "react";

import MultiSelect from "../common/pageComponents/multiSelect";
import MultiEdit from "../common/pageComponents/multiEdit";
import DateTimePicker from "../common/form/dateTimePicker";
import NonFormSelect from "./../common/form/nonFormSelect";
import SideBySideView from "../common/pageComponents/sideBySideView";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import allowables from "../../utils/allowables";

const MatchesButtonLineup = ({
  user,
  matches,
  multiSelectColumns,
  onMultiDelete,
  onBulkEdit,
  sendNotifications,
  setSendNotifications,
  referees,
  fields,
  toggleModal,
}) => {
  const [updatedMatches, setUpdatedMatches] = useState([]);

  useEffect(() => {
    let tempMatches = [];
    matches.forEach((m) => {
      let match = { ...m };
      match.refereeID = m.refereeID?._id || m.refereeID;
      match.fieldID = m.fieldID?._id || m.fieldID;
      tempMatches.push(match);
    });
    setUpdatedMatches(tempMatches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const handleUpdate = (prop, { _id }, value) => {
    let tempMatches = [...updatedMatches];
    let tempMatchIndex = tempMatches.findIndex((m) => m._id === _id);
    let tempMatch = { ...tempMatches[tempMatchIndex] };
    tempMatch[prop] =
      prop === "dateTime" && value
        ? allowables.formDate(value, false, true) + "Z"
        : value;
    tempMatches.splice(tempMatchIndex, 1, tempMatch);
    setUpdatedMatches(tempMatches);
  };

  const raiseSubmit = (selectedItems) => {
    return onBulkEdit(
      updatedMatches.filter((m) => selectedItems.includes(m._id))
    );
  };

  let buttons = [
    <TooltipButton
      buttonClass="btn-md btn-info"
      tooltipText="Add New Match"
      tooltipDirection="bottom"
      navigatePath={"/matches?q=new"}
    >
      <IconRender name="add" className="icon-md" />
    </TooltipButton>,
    <MultiEdit
      data={updatedMatches}
      keyValue="_id"
      columnsToShow={multiSelectColumns}
      editComponents={[
        {
          component: NonFormSelect,
          props: {
            name: "refereeID",
            label: "Select Referee:",
            options: referees,
            onChange: (event, match) =>
              handleUpdate("refereeID", match, event.target.value),
            value: "refereeID",
            noDefaultOption: true,
          },
        },
        {
          component: NonFormSelect,
          props: {
            name: "fieldID",
            label: "Select Field:",
            options: fields,
            onChange: (event, match) =>
              handleUpdate("fieldID", match, event.target.value),
            value: "fieldID",
          },
        },
        {
          component: DateTimePicker,
          props: {
            name: "dateTime",
            label: "Date and Time",
            options: fields,
            onChange: (event, match) => handleUpdate("dateTime", match, event),
            valueFunc: (m) =>
              m?.dateTime ? allowables.formDate(m.dateTime) : null,
          },
        },
      ]}
      onSubmit={raiseSubmit}
      title="Bulk Update Matches"
      type="matches"
      minRowHeight={30}
      buttonIcon={<IconRender name="edit" className="icon-md" />}
      buttonClass="btn-md btn-secondary"
      tooltipText="Bulk Update Matches"
      tooltipDirection={"bottom"}
      noHeaders={true}
    />,
    <MultiSelect
      data={matches}
      keyValue="_id"
      columnsToShow={multiSelectColumns}
      onSubmit={onMultiDelete}
      sendNotifications={sendNotifications}
      setSendNotifications={setSendNotifications}
      title="matches"
      type="delete"
      buttonIcon={<IconRender name="delete" className="icon-md" />}
      buttonClass="btn-md"
      tooltipText="Delete Multiple Matches"
    />,
  ];
  if (user.role.includes("owner"))
    buttons.splice(
      2,
      0,
      <TooltipButton
        buttonClass="btn-md btn-light"
        tooltipText="Send Schedule Emails"
        tooltipDirection="bottom"
        onClick={() => toggleModal("emailScheduleOpen")}
      >
        <IconRender name="send" className="icon-md" />
      </TooltipButton>
    );

  return (
    <SideBySideView
      Components={buttons}
      groupClassName="button-group-box"
      separateRows={true}
      minWidth={200}
    />
  );
};

export default MatchesButtonLineup;
