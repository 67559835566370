import React, { useState } from "react";
import { FaStar, FaClipboardList } from "react-icons/fa";

import MatchHeader from "../assignedMatch/matchHeader";
import ReviewInfoSegment from "../matchReview/reviewInfoSegment";
import HorizontalTabs from "../common/dataSorting/horizontalTabs";
import EventsTab from "../assignedMatch/eventsTab";

const LiveScorecard = ({
  matchData,
  liveData,
  teamOrder,
  isScoreboard,
  spectate,
}) => {
  const [selectedTab, setSelectedTab] = useState("info");
  const tabs = [{ name: "info", icon: FaStar }];
  if (!matchData || !liveData) return null;

  if (!matchData.sport.includes("simple"))
    tabs.push({ name: "events", icon: FaClipboardList });

  return (
    <div>
      {!isScoreboard && (
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={(tab) => setSelectedTab(tab.name)}
        />
      )}
      <MatchHeader
        match={matchData}
        teamOrder={teamOrder}
        goals={{ home: liveData.homeTeamScore, away: liveData.awayTeamScore }}
        penalties={{ home: liveData.homeTeamPKs, away: liveData.awayTeamPKs }}
        spectate={spectate}
      />
      <div className="pop-box">
        {selectedTab === "info" ? (
          <ReviewInfoSegment
            data={matchData}
            teams={teamOrder}
            liveEvents={liveData.events}
            alreadyPopped={true}
          />
        ) : selectedTab === "events" ? (
          <EventsTab
            chronoEventsNames={liveData.events}
            sport={matchData.sport}
            match={matchData}
            spectate={true}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LiveScorecard;
