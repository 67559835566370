import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser } from "../../../services/userService";
import OrgContext from "../../../context/orgContext";
import NotFound from "../../notFound";

const ProtectedRoute = ({
  path,
  component: Component,
  render,
  allowedRoles = [],
  ...rest
}) => {
  const user = getCurrentUser();

  if (user && !user.orgID) {
    // all protected routes that are valid for a user with no orgID to visit
    const validProtectedRoutes = ["/profile"];

    if (user?.role?.toLowerCase().includes("admin"))
      validProtectedRoutes.push("/league");

    // non-supers should be prevented from the superadminview route by the component itself
    // this is just another layer of protection
    if (
      user?.role?.toLowerCase().includes("super") ||
      user?.role?.toLowerCase().includes("internal")
    )
      validProtectedRoutes.push("/superadminview");

    let allowed = false;
    if (validProtectedRoutes.includes(path)) {
      allowed = true;
    }
    if (!allowed)
      return (
        <Redirect
          to={{
            pathname: "/profile",
          }}
        />
      );
  }

  let canAccess = false;
  if (
    path === "/profile" ||
    (user?.role &&
      (allowedRoles.includes("all") ||
        allowedRoles.includes(user.role.toLowerCase())))
  )
    canAccess = true;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        else if (canAccess)
          return Component ? (
            <OrgContext.Consumer>
              {(value) => <Component org={value} {...props} />}
            </OrgContext.Consumer>
          ) : (
            render(props)
          );
        else return <NotFound accessDenied={true} />;
      }}
    />
  );
};

export default ProtectedRoute;
