import React, { Component } from "react";
import ReactGA from "react-ga4";
import Pagination from "../common/dataSorting/pagination";
import { paginate } from "../../utils/paginate";
import SearchBox from "../common/dataSorting/searchBox";
import orderBy from "lodash/orderBy";
import toast from "../../utils/toast";
import { splitQuery } from "../../utils/splitQuery";
import CustomConfirm from "../common/customs/customConfirm";
import QuickLinks from "../common/pageComponents/quickLinks";
import { CLink } from "../common/customs/customLinks";
import ExcelDownload from "../common/pageComponents/excelDownload";
import TeamsButtonLineup from "./teamsButtonLineup";
import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import HeaderContext from "../../context/headerContext";
import { navigateTo } from "./../common/customs/customLinks";
import TeamsTableCardSwitcher from "./teamsTableCardSwitcher";
import NonFormSelect from "../common/form/nonFormSelect";

class Teams extends Component {
  static contextType = HeaderContext;
  state = {
    teams: [],
    divisions: [],
    pageSize: allowables.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedDivision: { _id: 0, name: "All Divisions" },
    multiEditDivision: "Make a selection...",
    enteredGroupName: "",
    sortColumn: { path: "name", order: "asc" },
    captainsTeam: null,
    deleteOpen: false,
    deleteDialog: "",
    purgeOneOpen: false,
    purgeTwoOpen: false,
    purgeDialog: "",
    selectedTeam: null,
    cardView: false,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await this.props.getTeams({
      callback: this.indicateProgress,
      bar: 0,
    });
    const user = this.props.getCurrentUser();
    let captainsTeam = null;
    if (response.status === 200) {
      response.data.forEach((t) => {
        if (t.teamCaptainID && t.teamCaptainID._id === user._id)
          captainsTeam = { _id: t._id, name: t.name };
      });
      this.setState({ teams: response.data, captainsTeam });
      const divisionsResponse = await this.props.getDivisions({
        callback: this.indicateProgress,
        bar: 1,
      });
      if (divisionsResponse.status === 200) {
        let dbDivisions = orderBy(divisionsResponse.data, ["name"], ["asc"]);
        dbDivisions.forEach((d) => {
          d.numberOfTeams = response.data.filter(
            (t) => t.divisionID === d._id
          ).length;
        });
        const divisions = [
          {
            _id: 0,
            name: "All Divisions",
            numberOfTeams: response.data.length,
          },
          ...dbDivisions,
        ];
        this.setState({ divisions });
      }
    }

    const queryString = splitQuery(this.props.location.search);
    let searchQuery = "";
    let currentPage = 1;
    let sortColumn = { ...this.state.sortColumn };
    let selectedDivision = this.state.selectedDivision;
    queryString.forEach((q) => {
      const each = q.split("=");
      searchQuery = each[0].includes("search") ? each[1] : searchQuery;
      currentPage = each[0].includes("page") ? Number(each[1]) : currentPage;
      selectedDivision._id =
        each[0].includes("id") && each[1] !== "0"
          ? each[1]
          : each[1] === "0"
          ? 0
          : selectedDivision._id;
      selectedDivision.name = each[0].includes("name")
        ? each[1].split("%20").join(" ")
        : selectedDivision.name;
      sortColumn.path = each[0].includes("path") ? each[1] : sortColumn.path;
      sortColumn.order = each[0].includes("order") ? each[1] : sortColumn.order;
    });
    if (
      (!selectedDivision._id && selectedDivision._id !== 0) ||
      !selectedDivision.name
    )
      selectedDivision = { _id: 0, name: "All Divisions" };
    this.setState({
      searchQuery,
      currentPage,
      selectedDivision,
      sortColumn,
    });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleCardClick = (team) => {
    navigateTo(`/teams?info&q=${team._id}`, this.props.history, this.context);
  };

  downloadColumns = [
    "name",
    "teamAbbreviation",
    "divisionName",
    "groupName",
    "sport",
    "teamCaptainName",
    "teamColor1",
    "teamColor2",
  ];

  pushHistoryString = (search, sortColumn, selectedDivision, currentPage) => {
    this.props.history.replace(
      `?search=${search}&path=${sortColumn.path}&order=${sortColumn.order}&name=${selectedDivision?.name}&id=${selectedDivision?._id}&page=${currentPage}`
    );
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  setDeleteDialog = (team) => {
    this.setState({
      deleteDialog: `Are you sure you want to delete ${team.name}?`,
      deleteOpen: true,
      selectedTeam: team,
    });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedTeam: team } = this.state;
    const response = await this.props.deleteTeam(team._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Team",
        action: "delete",
        label: "deleted team from teams table",
        nonInteraction: false,
      });
      const { data: teams } = await this.props.getTeams({
        callback: this.indicateProgress,
        bar: 1,
      });
      toast.success("Team deleted");
      this.setState({ teams });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setPurgeDialog = (team) => {
    this.setState({
      purgeDialog: `This will delete all of the players and matches from ${team.name}, along with the team itself.`,
      purgeOneOpen: true,
      selectedTeam: team,
    });
  };

  handlePurgeOne = () => {
    this.setState({ purgeTwoOpen: true });
  };

  handlePurge = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedTeam: team } = this.state;
    const response = await this.props.purgeTeam(team._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Team",
        action: "delete",
        label: "purged team from teams table",
        nonInteraction: false,
      });
      const { data: teams } = await this.props.getTeams({
        callback: this.indicateProgress,
        bar: 1,
      });
      toast.success("Team purged succesfully.");
      this.setState({ teams });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  handlePageChange = (page) => {
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      page
    );
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage - 1
    );
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage + 1
    );
    this.setState({ currentPage: currentPage + 1 });
  };

  handleDivisionSelection = (event) => {
    const division = this.state.divisions.find(
      (d) => String(d._id) === event.target.value
    );

    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      division,
      1
    );
    this.setState({
      selectedDivision: division,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    const resetDivision = { _id: 0, name: "All Divisions" };
    this.pushHistoryString(
      query,
      this.state.sortColumn,
      resetDivision,
      this.state.currentPage
    );
    this.setState({
      searchQuery: query,
      selectedDivision: resetDivision,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.pushHistoryString(
        this.state.searchQuery,
        sortColumn,
        this.state.selectedDivision,
        this.state.currentPage
      );
      this.setState({ sortColumn });
    }
  };

  multiSelectColumns = [
    {
      content: (t) => <TeamLogo _id={t.logo} name={t.name} size={35} />,
      label: "",
    },
    { content: (t) => t.name, label: "Name" },
    { content: (t) => t.divisionName, label: "Division" },
    { content: (t) => t.groupName, label: "Group" },
    { content: (t) => t.seed, label: "Seed" },
    {
      content: (t) => allowables.splitName(t.teamCaptainName),
      label: "Captain/Manager",
    },
  ];

  handleMultiEdit = async (teamIDs) => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { multiEditDivision, enteredGroupName } = this.state;

    if (
      !multiEditDivision ||
      multiEditDivision.toLowerCase() === "make a selection..."
    )
      toast.error("You must select a division.");
    else {
      const response = await this.props.editMultipleTeams(
        teamIDs,
        multiEditDivision,
        enteredGroupName,
        {
          callback: this.indicateProgress,
          bar: 0,
        }
      );
      if (response.status === 200) {
        toast.success(response.data);
        const { data: teams } = await this.props.getTeams({
          callback: this.indicateProgress,
          bar: 1,
        });
        this.setState({ teams });
      } else toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  getPageData = () => {
    const { selectedDivision, teams, sortColumn, searchQuery } = this.state;
    let filteredTeams = teams;
    if (searchQuery)
      filteredTeams = teams.filter((t) =>
        t.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    else if (selectedDivision && selectedDivision._id)
      filteredTeams = teams.filter(
        (t) => t.divisionID === selectedDivision._id
      );

    const sortedTeams = orderBy(
      filteredTeams,
      [sortColumn.path],
      [sortColumn.order]
    );
    const teamsByPage = paginate(
      sortedTeams,
      this.state.currentPage,
      this.state.pageSize
    );
    return { teamsByPage, filteredTeams, sortedTeams };
  };

  render() {
    const {
      pageSize,
      currentPage,
      selectedDivision,
      teams,
      divisions,
      sortColumn,
      searchQuery,
      captainsTeam,
      deleteOpen,
      deleteDialog,
      purgeOneOpen,
      purgeTwoOpen,
      purgeDialog,
      cardView,
    } = this.state;

    const { teamsByPage, filteredTeams, sortedTeams } = this.getPageData();
    const user = this.props.getCurrentUser();

    return (
      <div>
        {teams.length === 0 ? (
          <CLink
            path={"/teams?q=new"}
            button={true}
            buttonStyle="btn-lg btn-info btn-block"
          >
            Add New Team
          </CLink>
        ) : (
          <div>
            <div className="row">
              <div className="col text-center">
                <NonFormSelect
                  name="_id"
                  label=""
                  options={divisions}
                  onChange={this.handleDivisionSelection}
                  value={selectedDivision._id}
                  noDefaultOption={true}
                  testId="division-select"
                />
              </div>
              <div className="col">
                <p className="header-p">
                  Showing {teamsByPage.length} of {filteredTeams.length} teams.
                  There are {teams.length} total teams.
                </p>
              </div>
              <div className="col text-center">
                <TeamsButtonLineup
                  user={user}
                  captainsTeam={captainsTeam}
                  teams={sortedTeams}
                  divisions={divisions.slice(1)}
                  multiSelectColumns={this.multiSelectColumns}
                  onMultiEdit={this.handleMultiEdit}
                  selectedDivision={this.state.multiEditDivision}
                  setSelectedDivision={(multiEditDivision) =>
                    this.setState({ multiEditDivision })
                  }
                  enteredGroupName={this.state.enteredGroupName}
                  setEnteredGroupName={(enteredGroupName) =>
                    this.setState({ enteredGroupName })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search by team name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={teams}
                  columns={this.downloadColumns}
                  dataName="teams"
                  tooltipDirection="top-left"
                />
              </div>
            </div>
            <TeamsTableCardSwitcher
              cardView={cardView}
              teams={teamsByPage}
              onClick={this.handleCardClick}
              onDelete={this.setDeleteDialog}
              onSort={this.handleSort}
              sortColumn={sortColumn}
              onPurge={this.setPurgeDialog}
              thisID={user && user.teamID ? user.teamID : ""}
              getCurrentUser={this.props.getCurrentUser}
            />
            <Pagination
              itemsCount={filteredTeams.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPreviousPage={this.handlePreviousPage}
              onNextPage={this.handleNextPage}
            />
          </div>
        )}

        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
        <CustomConfirm
          dialog={deleteDialog}
          isOpen={deleteOpen}
          callback={this.handleDelete}
          close={this.toggleModal}
          id={"deleteOpen"}
          focused={true}
        />
        <CustomConfirm
          dialog={purgeDialog}
          isOpen={purgeOneOpen}
          callback={this.handlePurgeOne}
          close={this.toggleModal}
          id={"purgeOneOpen"}
          focused={true}
        />
        <CustomConfirm
          dialog={`Are you sure you wish to proceed? This cannot be undone...`}
          isOpen={purgeTwoOpen}
          callback={this.handlePurge}
          close={this.toggleModal}
          id={"purgeTwoOpen"}
          focused={true}
        />
      </div>
    );
  }
}

export default Teams;
