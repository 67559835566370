import React from "react";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import Form from "../common/form/form";
import HeaderContext from "../../context/headerContext";
import {
  getSeason,
  saveSeason,
  moveDataToNewSeason,
  archiveSeason,
} from "../../services/seasonsService";
import { getDivisionsBySeason } from "../../services/divisionService";
import CustomConfirm from "../common/customs/customConfirm";
import NonFormInput from "../common/form/nonFormInput";
import Switch from "../common/form/switch";
import Tooltip from "../common/tooltip/tooltip";
import WarningHeader from "../common/pageComponents/warningHeader";
import MatchesDivisionSelect from "../matches/matchesDivisionSelect";
import DivisionsTable from "../divisions/divisionsTable";

class SeasonForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      registrationEndDate: "",
      startDate: "",
      endDate: "",
      location: "",
    },
    baseState: {
      name: "",
      registrationEndDate: "",
      startDate: "",
      endDate: "",
      location: "",
      active: true,
    },
    moveToNewSeason: false,
    archiveOpen: false,
    newSeasonName: "",
    divisions: [],
    selectedDivisions: [],
    keepTeams: true,
    keepPlayers: true,
    keepCaptains: true,
    typedName: "",
    errors: {},
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    if (this.props.seasonID !== "new") {
      const res = await getSeason(this.props.seasonID, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (res.status === 200) {
        const divisionsRes = await getDivisionsBySeason(this.props.seasonID, {
          callback: this.indicateProgress,
          bar: 1,
        });
        if (divisionsRes.status === 200)
          this.setState({ divisions: divisionsRes.data });
        this.setState({
          data: this.mapToViewModel(res.data),
          baseState: {
            ...this.mapToViewModel(res.data),
            active: res.data.active,
          },
        });
      } else toast.error(res.data);
    }

    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  schema = {
    name: Joi.string().required().min(3).max(50).label("Season Name"),
    registrationEndDate: Joi.date()
      .optional()
      .allow("")
      .label("Registration End Date"),
    startDate: Joi.date().optional().allow("").label("Start Date"),
    endDate: Joi.date().optional().allow("").label("End Date"),
    location: Joi.string().optional().allow("").max(50).label("Location"),
  };

  mapToViewModel(season) {
    return {
      name: season.name || "",
      registrationEndDate: season.registrationEndDate || "",
      startDate: season.startDate || "",
      endDate: season.endDate || "",
      location: season.location || "",
    };
  }

  toggleModal = (id) => {
    this.setState({ [id]: !this.state[id], typedName: "" });
  };

  handleSelectDivision = (division) => {
    let { selectedDivisions } = this.state;
    const index = selectedDivisions.findIndex((d) => division._id === d);
    if (index >= 0) selectedDivisions.splice(index, 1);
    else selectedDivisions.push(division._id);
    this.setState({ selectedDivisions });
  };

  handleMoveDataToNewSeason = async () => {
    this.context.setLoading(true);
    this.context.setProgress([0]);
    const {
      newSeasonName,
      keepPlayers,
      keepTeams,
      keepCaptains,
      selectedDivisions,
    } = this.state;
    const options = {
      newSeason: {
        name: newSeasonName,
      },
      divisionIDs: selectedDivisions,
      keepTeams,
      keepPlayers,
      keepCaptains,
    };
    const res = await moveDataToNewSeason(this.props.seasonID, options, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success("Season archived");
      return this.props.history.replace("/seasons");
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleArchiveSeason = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await archiveSeason(this.props.seasonID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success("Season archived");
      return this.props.history.replace("/seasons");
    }
    toast.error(res.data);
    this.context.setLoading(false);
  };

  handleReset = () => {
    this.setState({ data: this.state.baseState });
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    let data = { ...this.state.data };
    data.orgID = this.props.org._id;
    let response;
    if (!this.props.seasonID !== "move") {
      delete data.keepTeams;
      delete data.keepPlayers;
      response = await saveSeason(this.props.seasonID, data, {
        callback: this.indicateProgress,
        bar: 0,
      });
    }
    if (response.status === 200) return this.props.history.replace("/seasons");
    this.setState({ apiError: response.data });
    toast.error(this.state.apiError);
    if (this.state.apiError) {
      this.setState({ data: this.mapToViewModel(this.state.data) });
    }
    this.context.setLoading(false);
  };

  render() {
    const { seasonID } = this.props;
    const {
      baseState,
      moveToNewSeason,
      archiveOpen,
      typedName,
      newSeasonName,
      divisions,
      selectedDivisions,
    } = this.state;
    return (
      <div className="centered-small-input-area">
        <div className="row">
          {seasonID !== "new" && this.state.baseState.active ? (
            <div className="col text-center">
              <button
                className="btn btn-md btn-secondary"
                onClick={() => this.toggleModal("archiveOpen")}
              >
                Archive Season
              </button>
            </div>
          ) : null}
          {seasonID !== "new" ? (
            <div className="col text-center">
              {this.state.baseState.active ? (
                <button
                  className="btn btn-md btn-info"
                  onClick={() => this.toggleModal("moveToNewSeason")}
                >
                  Move Data to New Season
                </button>
              ) : (
                <WarningHeader>Season is Archived</WarningHeader>
              )}
            </div>
          ) : null}
        </div>
        {this.renderWholeForm(
          "Season",
          "/seasons",
          {
            listHeader: "Required Fields",
            listItems: ["Season Name"],
            direction: "right",
            text: `A season is the foundation for your whole league.
            Using seasons is how you will organize your divisions, teams, players and matches.
            At the end of the season you will be able to easily move all your data to the next one.`,
          },
          <div>
            {this.renderInput(
              "name",
              "* Season Name",
              true,
              "",
              !this.state.baseState.active,
              "",
              "",
              {
                header: "Season Name",
                text: `There is no required convention for naming your seasons but it is recommended that you name them to show progression.
          Some examples would be: "Summer 2022", or "2021/2022"`,
                direction: "right",
                className: "icon-mobile",
              }
            )}
            {this.renderDateTimePicker(
              "registrationEndDate",
              "Registration End Date",
              !this.state.baseState.active,
              {
                header: "Registration End Date",
                text: `The date and time when registration closes for the season.
              After this date Free Agent registrations will no longer be allowed.
              This time is set based on your current time zone
              (e.g. closing registration at 5pm on the US East coast will mean players on the West coast cannot sign up past 2pm.).
              All active seasons are looked at. If a date is not set here then registration will never be restricted.`,
                direction: "right",
                className: "icon-mobile",
              },
              false
            )}
            {this.renderDateTimePicker(
              "startDate",
              "Start Date",
              !this.state.baseState.active,
              {
                header: "Start Date",
                text: `The date of the first match of your season`,
                direction: "right",
                className: "icon-mobile",
              },
              true
            )}
            {this.renderDateTimePicker(
              "endDate",
              "End Date",
              !this.state.baseState.active,
              {
                header: "End Date",
                text: `The date of the last match of your season`,
                direction: "right",
                className: "icon-mobile",
              },
              true
            )}
            {this.renderInput(
              "location",
              "Location",
              "",
              "",
              !this.state.baseState.active,
              "",
              "",
              {
                header: "Location",
                text: `If you have multiple seasons happening at the same time
           - either within this league or across different leagues -
           you should set a location where these matches will be played.`,
                direction: "right",
                className: "icon-mobile",
              }
            )}
          </div>,
          !this.state.baseState.active
        )}

        {this.props.seasonID !== "new" && (
          <div>
            {" "}
            <hr />
            {divisions.length > 0 ? (
              <DivisionsTable onDelete={null} divisionsByPage={divisions} />
            ) : (
              <b>There are no divisions in this season</b>
            )}
          </div>
        )}
        <CustomConfirm
          dialog={`Move ${baseState.name} to new season...%
          This will move all matches to the archive and set the payment status of all teams/players to unpaid.%
          Any unselected divisions will be deleted along with any teams, players and users attached to that division.%
          Hover over each "Keep" option to see more information on options for selected divisions.%
          Make your selections and then type the name of the season to continue.%
          Archiving cannot be undone so please make your selections carefully.%
          If you just want to archive the matches and reset payment status use the Archive Season button instead.`}
          id="moveToNewSeason"
          isOpen={moveToNewSeason}
          callback={this.handleMoveDataToNewSeason}
          close={this.toggleModal}
          holdSubmitButton={{
            enteredValue: typedName,
            mustMatchValue: baseState.name,
          }}
          split="%"
          focused={true}
        >
          <div className="text-center">
            <NonFormInput
              name="newSeasonName"
              type="text"
              label={"New Season Name"}
              value={newSeasonName}
              onChange={(event) =>
                this.setState({ newSeasonName: event.target.value })
              }
              boldLabel={true}
            />
            <MatchesDivisionSelect
              divisions={divisions}
              selectedDivisions={selectedDivisions}
              onSelectDivision={this.handleSelectDivision}
            />

            {[
              {
                value: "keepTeams",
                label: "Keep Teams",
                tooltipContent: (
                  <p>
                    If selected all teams in the selected divisions will be
                    transfered to the new season.
                  </p>
                ),
              },
              {
                value: "keepPlayers",
                label: "Keep Players",
                tooltipContent: (
                  <p>
                    If selected all players and users will
                    <br />
                    be transfered to the new season.
                    <br />
                    If you choose to keep the players but
                    <br />
                    not the teams then all players will be
                    <br />
                    transfered to Free Agents
                  </p>
                ),
              },
              {
                value: "keepCaptains",
                label: "Keep Captains",
                tooltipContent: (
                  <p>
                    If teams and/or players are not kept
                    <br />
                    you can still choose to keep your
                    <br />
                    team captains.
                    <br />
                    Each captain will remain as a user
                    <br />
                    and be able to create a new team
                    <br />
                    in the new season
                  </p>
                ),
              },
            ].map((e) => (
              <Tooltip
                key={e.value}
                direction="top"
                maxWidth={300}
                content={
                  <span>
                    <p>
                      <b>{e.label}</b>
                    </p>
                    {e.tooltipContent}
                  </span>
                }
              >
                <Switch
                  onChange={() =>
                    this.setState({ [e.value]: !this.state[e.value] })
                  }
                  value={this.state[e.value]}
                  label={e.label}
                />
              </Tooltip>
            ))}
            <b>Enter current season name to confirm archiving</b>
            <NonFormInput
              name="typedName"
              type="text"
              label={baseState.name}
              value={typedName}
              onChange={(event) =>
                this.setState({ typedName: event.target.value })
              }
            />
          </div>
        </CustomConfirm>
        <CustomConfirm
          dialog={`Confirm archiving of ${baseState.name}...%
          This will move all matches to the archive and set the payment status of all teams/players to unpaid.%
          All divisions, teams, players, and users will remain unchanged.
          If you want to automatically move data to a new season use the Move Data to New Season button instead.%
          This cannot be undone.%
          Please type the name of the season to confirm archiving.
          `}
          id="archiveOpen"
          isOpen={archiveOpen}
          callback={this.handleArchiveSeason}
          close={this.toggleModal}
          holdSubmitButton={{
            enteredValue: typedName,
            mustMatchValue: baseState.name,
          }}
          split="%"
          focused={true}
        >
          <NonFormInput
            name="typedName"
            type="text"
            label={baseState.name}
            value={typedName}
            onChange={(event) =>
              this.setState({ typedName: event.target.value })
            }
          />
        </CustomConfirm>
      </div>
    );
  }
}

export default SeasonForm;
