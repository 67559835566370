import React from "react";
import MiniHeader from "../pageComponents/miniHeader";

const LoadingPlaceholder = ({ header }) => {
  const mappedPlaceholders = new Array(9).fill(null);
  return (
    <div>
      <MiniHeader>{header}</MiniHeader>
      <div className="form-divided-section">
        <div className="loading-placeholder-background">
          {mappedPlaceholders.map((i, idx) => (
            <div key={idx} className="loading-placeholder-text" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingPlaceholder;
