import React from "react";
import PageBottom from "../pageComponents/pageBottom";
import Home from "../../homepage/home";
import Spinner from "react-bootstrap/Spinner";
import MainTitle from "./mainTitle";

const ContactingServer = ({ ready, refresh, cookies, upToDate }) => {
  return (
    <React.Fragment>
      {cookies && (
        <div className="text-center">
          <MainTitle />
        </div>
      )}
      <br />
      <h3 className="text-center">
        {ready && !cookies
          ? (upToDate
              ? "Could not contact server."
              : "Your app version is out of date.") + " Please refresh the page"
          : !cookies
          ? "Contacting server"
          : "Cookies must be accepted for the site to function"}
      </h3>

      {!ready ? (
        <div className="text-center">
          <Spinner animation="border" className="bg-info" />
        </div>
      ) : (
        <div className="text-center">
          <div style={{ height: 50 }} />
          <div className="container">
            <button
              className="btn btn-lg btn-info btn-block"
              onClick={() => refresh(true)}
            >
              {cookies ? "Ask Again" : "Refresh"}
            </button>
          </div>
          {cookies && (
            <div style={{ padding: "100px" }}>
              <PageBottom cookieOnly={true} />
            </div>
          )}
        </div>
      )}
      <Home />
    </React.Fragment>
  );
};

export default ContactingServer;
