import { useState, useEffect } from "react";

/*
    Extract and store query parameters from url
    Params will be returned as an object with key: value pairs of params
    Expected param format is key=value, separated by &
*/

const useSearchParams = (location) => {
  const [params, setParams] = useState({});

  useEffect(() => {
    let query = location.search;
    if (query.charAt(0) === "?") query = query.slice(1);
    let searchParams = {};
    for (let search of query.split("&")) {
      const split = search.split("=");
      searchParams[split[0]] = split[1];
    }
    setParams(searchParams);
  }, [location]);

  return params;
};

export default useSearchParams;
