import React, { useState, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const Input = ({
  name,
  label,
  error,
  type,
  tooltip,
  paidFeature,
  autoFocus,
  autocomplete,
  boldLabel,
  ...rest
}) => {
  const focusRef = useRef(null);
  const [updatedType, setUpdatedType] = useState(type);
  const [cName, setCName] = useState("pw-invisible");
  const showPassword = () => {
    setUpdatedType("text");
    setCName("pw-visible");
  };
  const hidePassword = () => {
    setUpdatedType("password");
    setCName("pw-invisible");
  };

  const labelText = boldLabel ? <b>{label}</b> : label;

  // this will set autofocus to the component after final render
  useEffect(() => {
    if (focusRef?.current && autoFocus) {
      setTimeout(() => {
        focusRef.current?.focus();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconContext.Provider
      value={{ className: "password-eye clickable " + cName }}
    >
      <div className="form-group">
        <FormLabel
          tooltip={tooltip}
          paidFeature={paidFeature}
          label={labelText}
          htmlFor={name}
          type={type}
          updatedType={updatedType}
          showPassword={showPassword}
          hidePassword={hidePassword}
        />
        <input
          name={name}
          id={name}
          ref={focusRef}
          type={updatedType}
          className="form-control custom-form"
          autoComplete={autocomplete}
          {...rest}
        />
        {error && <WarningHeader>{error}</WarningHeader>}
      </div>
    </IconContext.Provider>
  );
};

export default Input;
