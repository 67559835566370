import React from "react";

import useWindowDimensions from "../../../utils/useWindowDimensions";

// this view will display two or more components side by side if screen width is greater than 450
//    otherwise it will stack the components
const SideBySideView = ({
  Components,
  minWidth = 500,
  separateRows,
  colClassNames,
  groupClassName,
  reverse,
  doNotCenterOnStack,
  customDivider,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div className={groupClassName}>
      {(width > minWidth && !reverse) || (width < minWidth && reverse) ? (
        <div className="row">
          {Components.map((Component, i) => (
            <div
              className={"col " + (colClassNames ? colClassNames[i] : "")}
              key={i}
            >
              {Component}
            </div>
          ))}
        </div>
      ) : (
        Components.map((Component, i) => (
          <div
            key={i}
            className={doNotCenterOnStack ? "" : "text-center"}
            style={{ margin: 5 }}
          >
            {Component}
            {separateRows &&
              i !== Components.length - 1 &&
              (customDivider ? customDivider : <hr className="custom" />)}
          </div>
        ))
      )}
    </div>
  );
};

export default SideBySideView;
