import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function getCustomer(callback) {
  try {
    return await http.get(
      http.paymentEndpoint + "/customer",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getAccountLink() {
  try {
    return await http.get(http.paymentEndpoint + "/accountlink");
  } catch (ex) {
    return ex.response;
  }
}

export async function getCurrencies() {
  try {
    return await http.get(http.paymentEndpoint + "/currencies/all");
  } catch (ex) {
    return ex.response;
  }
}

export async function getAccountInfo() {
  try {
    return await http.get(http.paymentEndpoint + "/account");
  } catch (ex) {
    return ex.response;
  }
}

export async function getAccountEnabledInfo() {
  try {
    return await http.get(http.paymentEndpoint + "/accountbasic");
  } catch (ex) {
    return ex.response;
  }
}

export async function getPaymentData() {
  try {
    return await http.get(http.paymentEndpoint + "/paymentdata");
  } catch (ex) {
    return ex.response;
  }
}

export async function startCheckoutSession() {
  try {
    return await http.get(http.paymentEndpoint + "/checkout");
  } catch (ex) {
    return ex.response;
  }
}

export async function acceptPayment(successID) {
  try {
    return await http.put(
      http.paymentEndpoint + "/paymentsuccess/" + successID
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function markAsPaid(data) {
  try {
    return await http.post(http.paymentEndpoint + "/markpaid", { data });
  } catch (ex) {
    return ex.response;
  }
}

export async function giveRefund(data) {
  try {
    return await http.put(http.paymentEndpoint + "/refund", { data });
  } catch (ex) {
    return ex.response;
  }
}

export async function getSubscriptionPrices(callback) {
  try {
    return await http.get(
      http.paymentEndpoint + "/subscriptions",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getUpcomingInvoice(callback) {
  try {
    return await http.get(
      http.paymentEndpoint + "/invoice",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function addCustomer() {
  try {
    return await http.post(http.paymentEndpoint + "/customer");
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteCustomer() {
  try {
    return await http.delete(http.paymentEndpoint + "/customer");
  } catch (ex) {
    return ex.response;
  }
}

export async function updateDefaultPaymentMethod(paymentMethodID) {
  try {
    return await http.post(http.paymentEndpoint + "/paymentmethod", {
      paymentMethodID,
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function removePaymentMethod(paymentMethodID) {
  try {
    return await http.delete(http.paymentEndpoint + "/paymentmethod");
  } catch (ex) {
    return ex.response;
  }
}

export async function addSubscription(tier) {
  try {
    return await http.post(http.paymentEndpoint + "/subscription", {
      tier,
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function retryLastInvoice() {
  try {
    return await http.post(http.paymentEndpoint + "/retrylastcharge");
  } catch (ex) {
    return ex.response;
  }
}

export async function getSubscription() {
  try {
    return await http.get(http.paymentEndpoint + "/organization");
  } catch (ex) {
    return ex.response;
  }
}

export async function sendInvoice() {
  try {
    return await http.get(http.paymentEndpoint + "/emailinvoice");
  } catch (ex) {
    return ex.response;
  }
}

export async function getPrepaymentCheckout(orgID, userData, sourcePage) {
  try {
    return await http.post(
      http.paymentEndpoint + "/prepayment/checkout/" + orgID,
      { userData, sourcePage }
    );
  } catch (ex) {
    return ex.response;
  }
}
