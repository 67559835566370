import React, { useContext, useState } from "react";
import Table from "../../common/table/table";
import orderBy from "lodash/orderBy";
import { CLink } from "../../common/customs/customLinks";
import EventImage from "../../assignedMatch/eventImage";
import HorizontalButtons from "../../common/dataSorting/horizontalButtons";
import { CgInfinity } from "react-icons/cg";
import allowables from "../../../utils/allowables";
import { atBatName } from "../../../utils/diamondFunctions";
import OrgContext from "../../../context/orgContext";

const DiamondLeaderboardTable = ({
  data,
  searchQuery,
  spectate,
  onSelectTable,
  onSelectPlayer,
  isMyHistory,
}) => {
  const org = useContext(OrgContext);
  const [battingSortColumn, setBattingSortColumn] = useState({
    path: "rbis",
    order: "desc",
  });
  const [pitchingSortColumn, setPitchingSortColumn] = useState({
    path: "era",
    order: "asc",
  });
  const [selectedTable, setSelectedTable] = useState("batting");
  const [aboveAverage, setAboveAverage] = useState(false);

  if (!data) return null;

  const nameColumns = isMyHistory
    ? [{ path: "orgName", label: "League Name" }]
    : [
        {
          key: "image",
          content: (p) => <EventImage player={p} />,
        },
        {
          path: "name",
          label: "Player Name",
          content: (p) =>
            spectate ? (
              allowables.splitName(p.name)
            ) : (
              <CLink path={`/players?info&q=${p._id}`}>
                {allowables.splitName(p.name)}
              </CLink>
            ),
        },
        {
          path: "teamName",
          label: "Team Name",
          content: (p) =>
            spectate ? (
              p.teamName
            ) : (
              <CLink path={`/teams?info&q=${p.teamID._id}`}>{p.teamName}</CLink>
            ),
        },
      ];

  const battingColumns = [
    ...nameColumns,
    { path: "atBats", label: atBatName(org).abbr },
    { path: "hits", label: "H" },
    { path: "homeRuns", label: "HR" },
    { path: "rbis", label: "RBI" },
    { path: "walks", label: "BB" },
    { path: "strikeouts", label: "K" },
    {
      path: "battingAverage",
      label: "AVG",
      content: (p) => p.battingAverage?.toFixed(3) || "0.000",
    },
    {
      path: "onBasePct",
      label: "OBP",
      content: (p) => p.onBasePct?.toFixed(3) || "0.000",
    },
    {
      path: "slugging",
      label: "SLG",
      content: (p) => p.slugging?.toFixed(3) || "0.000",
    },
    {
      path: "onBasePlus",
      label: "OPS",
      content: (p) => p.onBasePlus?.toFixed(3) || "0.000",
    },
  ];

  const pitchingColumns = [
    ...nameColumns,
    { path: "inningsPitched", label: "IP" },
    { path: "hits", label: "H" },
    { path: "runs", label: "R" },
    { path: "walks", label: "BB" },
    { path: "strikeouts", label: "K" },
    { path: "homeRuns", label: "HR" },
    {
      path: "era",
      label: "ERA",
      content: (p) => (p.era === null ? <CgInfinity /> : p.era.toFixed(2)),
    },
    {
      path: "walksPlusHits",
      label: "WHIP",
      content: (p) =>
        p.walksPlusHits === null ? <CgInfinity /> : p.walksPlusHits.toFixed(2),
    },
  ];

  const handleSelectTable = (table) => {
    if (onSelectTable) onSelectTable(table.id);
    setSelectedTable(table.id);
  };

  const handleSort = (sortColumn) => {
    if (selectedTable === "batting") setBattingSortColumn(sortColumn);
    else setPitchingSortColumn(sortColumn);
  };

  const averageAtBats = Math.floor(
    data.batting.reduce((acc, cur) => {
      return acc + cur.atBats;
    }, 0) / data.batting.length
  );
  const averageInningsPitched = Math.floor(
    data.pitching.reduce((acc, cur) => {
      return acc + cur.outs;
    }, 0) / data.pitching.length
  );

  const filterData = () => {
    let filteredLeaderboard =
      selectedTable === "batting" ? [...data.batting] : [...data.pitching];
    if (searchQuery) {
      filteredLeaderboard = filteredLeaderboard.filter(
        (p) =>
          allowables
            .splitName(p.name.toLowerCase())
            .includes(searchQuery.toLowerCase()) ||
          p.teamName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (aboveAverage) {
      const average =
        selectedTable === "batting"
          ? { avg: averageAtBats, crit: "atBats" }
          : { avg: averageInningsPitched, crit: "outs" };
      filteredLeaderboard = filteredLeaderboard.filter(
        (p) => p[average.crit] >= average.avg
      );
    }
    return filteredLeaderboard;
  };

  const getPageData = () => {
    const filteredLeaderboard = filterData();
    const sortColumn =
      selectedTable === "batting" ? battingSortColumn : pitchingSortColumn;
    const sortedLeaderboard = orderBy(
      filteredLeaderboard,
      [sortColumn.path],
      [sortColumn.order]
    );
    return sortedLeaderboard;
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <HorizontalButtons
            buttons={[
              { id: "batting", name: atBatName(org).ing },
              { id: "pitching", name: "Pitching" },
            ]}
            selectedType={selectedTable}
            onClick={handleSelectTable}
          />
        </div>
        {!isMyHistory && (
          <div className="col-2">
            <button
              className={
                "btn btn-sm btn-block btn-" + (aboveAverage ? "info" : "light")
              }
              onClick={() => setAboveAverage(aboveAverage ? false : true)}
            >
              {aboveAverage
                ? "Show All Players"
                : "Show Above Average " +
                  (selectedTable === "batting" ? atBatName(org).abbr : "IP")}
            </button>
          </div>
        )}
      </div>
      <br />
      <Table
        columns={selectedTable === "batting" ? battingColumns : pitchingColumns}
        data={getPageData()}
        sortColumn={
          selectedTable === "batting" ? battingSortColumn : pitchingSortColumn
        }
        onSort={handleSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        onSelect={onSelectPlayer}
      />
      {getPageData().length === 0 && (
        <p>
          Only players{" "}
          {selectedTable === "batting" ? "with at bats" : "who have pitched"}{" "}
          are included.
        </p>
      )}
    </React.Fragment>
  );
};

export default DiamondLeaderboardTable;
