import React from "react";
import { MdMoneyOff, MdCheckBox } from "react-icons/md";

import { CLink } from "../../common/customs/customLinks";
import Table from "../../common/table/table";
import SearchBox from "../../common/dataSorting/searchBox";
import MailingList from "../../common/dataSorting/mailingList";
import { makeCustomTableButton, combineButtons } from "../../../utils/buttons";
import allowables from "../../../utils/allowables";

const PaymentTrackingTable = ({
  org,
  data,
  sortColumn,
  onSort,
  onSearch,
  searchQuery,
  selectedType,
  onMarkPaid,
  onRefund,
}) => {
  const columns = [
    {
      path: "name",
      label: "Name",
      content: (d) => (
        <CLink path={`/${org.stripe.whoPays.toLowerCase()}s?info&q=${d._id}`}>
          {allowables.splitName(d.name)}
        </CLink>
      ),
    },
    {
      path: "payment.amountPaid",
      label: "Amount Paid",
      content: (d) =>
        `${
          d.payment && d.payment.amountPaid
            ? d.payment.amountPaid.toFixed(2)
            : (0).toFixed(2)
        } ${org.stripe.currency.toUpperCase()}`,
    },
    {
      path: "payment.amountDue",
      label: "Amount Due",
      content: (d) =>
        `${
          d.payment && d.payment.amountDue
            ? d.payment.amountDue.toFixed(2)
            : org.stripe.amount.toFixed(2)
        } ${org.stripe.currency.toUpperCase()}`,
    },
    {
      path: "payment.datePaid",
      label: "Date Paid",
      content: (d) =>
        d.payment && d.payment.datePaid
          ? allowables.formDate(d.payment.datePaid, true)
          : "",
    },
  ];

  if (org.stripe.whoPays === "Team") {
    columns.splice(
      1,
      0,
      {
        path: "teamCaptainName",
        label: "Team Captain",
        content: (t) => (
          <CLink path={`/players?info&q=${t.teamCaptainID?.playerID}`}>
            {allowables.splitName(t.teamCaptainName)}
          </CLink>
        ),
      },
      {
        path: "teamCaptainEmail",
        label: "Team Captain Email",
        content: (t) => (
          <a href={`mailto:${t.teamCaptainID?.email}`}>
            {t.teamCaptainID?.email}
          </a>
        ),
      }
    );
  } else {
    columns.splice(
      1,
      0,
      {
        path: "email",
        label: "Email",
        content: (d) => (
          <a href={`mailto:${d.userID?.email}`}>{d.userID?.email}</a>
        ),
      },
      {
        path: "teamName",
        label: "Team Name",
      }
    );
  }

  let buttons = [];
  buttons.push(
    makeCustomTableButton(
      <MdCheckBox />,
      "Mark As Paid",
      "info btn-sm",
      "markPaid",
      onMarkPaid,
      "top-left",
      "",
      function (x) {
        return allowables.splitName(x.name);
      }
    )
  );
  buttons.push(
    makeCustomTableButton(
      <MdMoneyOff />,
      "Mark As Unpaid",
      "dark btn-sm",
      "refund",
      onRefund,
      "top-left",
      "",
      function (x) {
        return allowables.splitName(x.name);
      }
    )
  );
  columns.push(combineButtons(buttons));

  const getUsers = () => {
    if (org.stripe.whoPays === "Player")
      return data.filter((p) => p.userID).map((p) => p.userID);
    else if (org.stripe.whoPays === "Team")
      return data.filter((t) => t.teamCaptainID).map((t) => t.teamCaptainID);
    else return [];
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <SearchBox
            onChange={onSearch}
            placeholder={`Search by ${org.stripe.whoPays.toLowerCase()} name...`}
            value={searchQuery}
          />
        </div>
        <div className="col-2">
          <MailingList
            users={getUsers()}
            buttonTitle="Create Mailing List"
            secondaryTitle={
              selectedType.charAt(0).toUpperCase() + selectedType.slice(1)
            }
            margin="20px"
          />
        </div>
      </div>

      <Table
        columns={columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
      />
    </React.Fragment>
  );
};

export default PaymentTrackingTable;
