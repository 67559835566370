import React, { Component } from "react";
import ReactGA from "react-ga4";
import {
  getCurrentUser,
  updateUserInfo,
  deleteUserAndSwitchToNextAccount,
  logout,
  removeAvatar,
  refreshUser,
  getUserAccounts,
  searchForAccount,
  moveSelfToFreeAgents,
  updateNotificationSettings,
} from "../../services/userService";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import { getPlayer, getMyHistory } from "../../services/playerService";
import ProfilePic from "./profilePic";
import CustomConfirm from "../common/customs/customConfirm";
import CustomAlert from "../common/customs/customAlert";
import PageBottom from "../common/pageComponents/pageBottom";
import ProfileInfo from "./profileInfo";
import ProfileStatuses from "./profileStatuses";
import MyHistory from "../reports/myHistory";
import HeaderContext from "../../context/headerContext";
import { navigateTo } from "../common/customs/customLinks";
import MiniHeader from "../common/pageComponents/miniHeader";
import ReferralLink from "../common/pageComponents/referralLink";
import NotificationSettings from "./notificationSettings";
import { getNextMatch } from "../../services/matchService";

class Profile extends Component {
  static contextType = HeaderContext;
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
    },
    baseState: {
      name: "",
      email: "",
    },
    errors: {},
    user: null,
    password: "",
    deleteUserOpen: false,
    teamID: "",
    teamName: "",
    player: {},
    avatarOpen: false,
    hasAvatar: "",
    avatar: "",
    userAccounts: [],
    alertOpen: false,
    alertDialog: "",
    deleteOpen: false,
    searchOpen: false,
    picRemoveOpen: false,
    switchAccount: null,
    waiverWire: true,
    historicalData: null,
    nextMatch: null,
  };

  schema = {
    firstName: Joi.string().min(1).max(50).required().label("First Name"),
    lastName: Joi.string().min(1).max(50).required().label("Last Name"),
    email: Joi.string().required().email().label("Email"),
  };

  async componentDidMount() {
    this.context.setLoading(true);
    const refresh = await refreshUser({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (refresh.status === 409) return;
    let user = getCurrentUser();
    user.firstName = user.name.split("%20%")[0];
    user.lastName = user.name.split("%20%")[1];
    this.setState({
      data: this.mapToViewModel(user),
      user,
      baseState: user,
      hasAvatar: user.profileID.hasAvatar || user.hasAvatar || "",
    });
    if (user.playerID) {
      const playerResponse = await getPlayer(user.playerID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      if (playerResponse.status === 200)
        this.setState({
          teamID: playerResponse.data.teamID,
          teamName: playerResponse.data.teamName,
          player: playerResponse.data,
        });
      const nextMatchRes = await getNextMatch();
      if (nextMatchRes.status === 200) {
        this.setState({
          nextMatch: nextMatchRes.data,
        });
      }
    }
    let profileResponse = await getUserAccounts({
      callback: this.indicateProgress,
      bar: 2,
    });
    if (profileResponse.status === 200)
      this.setState({ userAccounts: profileResponse.data });
    else toast.error(profileResponse.data);
    this.context.setLoading(false);
  }

  retrieveHistoricalStats = async () => {
    this.context.setLoading(true);
    this.context.setProgress([0]);
    const res = await getMyHistory({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      this.setState({ historicalData: res.data });
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  setAlert = (dialog) => {
    this.setState({ alertOpen: true, alertDialog: dialog });
  };

  mapToViewModel(user) {
    return {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
    };
  }

  openDeleteUser = () => {
    if (this.state.deleteUserOpen) this.setState({ deleteUserOpen: false });
    this.setState({ deleteUserOpen: true });
  };

  openAvatar = (e) => {
    e.preventDefault();
    if (this.state.avatarOpen) this.setState({ avatarOpen: false });
    else this.setState({ avatarOpen: true });
  };

  setLoading = (bool, progress) => {
    this.context.setLoading(bool);
    this.context.setProgress(progress);
  };

  handleMoveToFreeAgents = async () => {
    this.context.setLoading(true);
    this.context.setProgress([0]);
    const response = await moveSelfToFreeAgents(this.state.waiverWire, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) return window.location.reload();
    else toast.error(response.data);
    this.context.setLoading(false);
  };

  deleteAccount = async () => {
    const { user } = this.state;
    if (user.role?.includes("owner") && user.orgID) {
      this.setState({ deleteUserOpen: false });
      return this.setAlert(
        `As the owner of ${user.orgName} you must first delete your league before you can delete your account.%You can do this on the League tab under Options.`
      );
    }
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await deleteUserAndSwitchToNextAccount({
      callback: this.indicateProgress,
      bar: 0,
    });

    if (response.status === 200) {
      ReactGA.event({
        category: "User",
        action: "delete",
        label: "deleted account, switched to next account",
        nonInteraction: false,
      });
      return window.location.reload();
    } else {
      ReactGA.event({
        category: "User",
        action: "delete",
        label: "deleted account, did not have another account",
        nonInteraction: false,
      });
      logout();
      return (window.location = "/login");
    }
  };

  removeAvatar = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { user } = this.state;
    user.hasAvatar = false;
    const response = await removeAvatar(user);
    if (response.status === 200) return window.location.reload();
    else toast.error(response.data);
    this.context.setLoading(false);
  };

  searchNewAccount = async () => {
    this.context.setLoading(true);
    this.context.setProgress([0]);
    const user = getCurrentUser();
    const { password } = this.state;
    const res = await searchForAccount(user.name, user.email, password, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success("Account added");
      return (window.location = "/profile");
    } else toast.error(res.data);
    this.context.setLoading(false);
    this.setState({ password: "" });
  };

  handleUpdateSettings = async (settings) => {
    this.context.setLoading(true);
    const res = await updateNotificationSettings(settings);
    if (res.status === 200) {
      this.componentDidMount();
      toast.success(res.data);
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleSubmit = async (data) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    data.name = data.firstName + "%20%" + data.lastName;
    const response = await updateUserInfo(data, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      toast.success("Profile updated.");
      this.setState({
        data: this.mapToViewModel(response.data),
        baseState: response.data,
      });
      window.location.reload();
    } else {
      toast.error(response.data);
      this.context.setLoading(false);
    }
  };

  render() {
    const {
      user,
      player,
      userAccounts,
      alertOpen,
      alertDialog,
      deleteOpen,
      deleteUserOpen,
      searchOpen,
      picRemoveOpen,
      waiverWire,
      nextMatch,
    } = this.state;

    return user ? (
      <React.Fragment>
        <div className="row">
          <div className="col text-left">
            <ProfileInfo
              user={user}
              player={player}
              data={this.state.data}
              onSubmit={this.handleSubmit}
              onMoveToFreeAgents={this.handleMoveToFreeAgents}
              waiverWire={waiverWire}
              setWaiverWire={() => this.setState({ waiverWire: !waiverWire })}
              userAccounts={userAccounts}
            />
          </div>
          <div className="col text-center">
            <ProfileStatuses
              org={this.props.org}
              user={user}
              player={player}
              nextMatch={nextMatch}
            />
            <ProfilePic
              user={user}
              org={this.props.org}
              indicateProgress={this.indicateProgress}
              hasAvatar={this.state.hasAvatar}
              openAvatar={this.openAvatar}
              avatarOpen={this.state.avatarOpen}
              removeAvatar={() => this.toggleModal("picRemoveOpen")}
            />
            <NotificationSettings
              user={user}
              onUpdateSettings={this.handleUpdateSettings}
            />
          </div>
        </div>
        {this.state.user?.role?.includes("player") && (
          <div>
            <MiniHeader
              onClick={
                this.state.historicalData ? null : this.retrieveHistoricalStats
              }
            >
              My Ultimate Scoreboard History
            </MiniHeader>
            {this.state.historicalData && (
              <div className="form-divided-section bg-light-m10">
                <MyHistory historicalData={this.state.historicalData} />
              </div>
            )}
          </div>
        )}
        <br />
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <ReferralLink />
          </div>
          <div className="col text-center">
            {!(user.role?.includes("admin") && !user.orgID) && (
              <button
                className="btn btn-info btn-block"
                onClick={() =>
                  navigateTo("/register", this.props.history, this.context)
                }
              >
                {user.role?.includes("owner") ||
                (user.role?.includes("admin") && !user.orgID)
                  ? "Start Another League"
                  : "Start your own League"}
              </button>
            )}
          </div>
        </div>
        <br />
        <div className="text-left">
          {!deleteUserOpen && (
            <button
              className="btn btn-dark btn-sm"
              onClick={this.openDeleteUser}
            >
              {user.role
                ? "Delete Active Account"
                : "Delete Ultimate Scoreboard Account"}
            </button>
          )}
          {deleteUserOpen && (
            <button
              className="btn btn-dark btn-sm"
              onClick={() => this.toggleModal("deleteOpen")}
            >
              Are you sure?
            </button>
          )}
        </div>
        <PageBottom />
        <CustomConfirm
          dialog="Are you sure you want to delete your account?"
          isOpen={deleteOpen}
          close={this.toggleModal}
          id="deleteOpen"
          callback={this.deleteAccount}
          negativeCallback={() => this.setState({ deleteUserOpen: false })}
          yesNo={true}
          focused={true}
        />
        <CustomConfirm
          dialog="Are you sure you want to remove your profile picture?"
          isOpen={picRemoveOpen}
          close={this.toggleModal}
          id="picRemoveOpen"
          callback={this.removeAvatar}
          yesNo={true}
          focused={true}
        />
        <CustomConfirm
          dialog="Please re-enter password"
          isOpen={searchOpen}
          close={this.toggleModal}
          id="searchOpen"
          callback={this.searchNewAccount}
        >
          <input
            type="password"
            className="form-control custom-form"
            onChange={(e) => this.setState({ password: e.currentTarget.value })}
          />
        </CustomConfirm>
        <CustomAlert
          dialog={alertDialog}
          isOpen={alertOpen}
          close={this.toggleModal}
          id="alertOpen"
          split="%"
        />
      </React.Fragment>
    ) : null;
  }
}

export default Profile;
