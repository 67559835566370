import React from "react";

import allowables from "../../../utils/allowables";
import PlayerPaymentHistory from "./playerPaymentHistory";
import MiniHeader from "../../common/pageComponents/miniHeader";
import WarningHeader from "../../common/pageComponents/warningHeader";

const PlayerPaymentForm = ({
  player,
  org,
  paymentData,
  startCheckoutSession,
  paymentDetails,
  paymentHistory,
  user,
  team,
}) => {
  const renderInfoLine = (label, value) => {
    return (
      <p>
        <small>{label}</small>
        <br />
        <b>{value}</b>
      </p>
    );
  };
  return (
    <div className="centered-small-input-area">
      <MiniHeader>{org.name} Dues</MiniHeader>
      <div className="form-divided-section">
        <div className="text-right">
          <PlayerPaymentHistory
            paymentData={paymentData}
            payment={paymentDetails}
            paymentHistory={paymentHistory}
          />
        </div>
        {renderInfoLine("Payment Type", paymentData.whoPays)}
        {renderInfoLine(
          "Name",
          paymentData.whoPays === "Team"
            ? player.teamName
            : allowables.splitName(player.name)
        )}
        {renderInfoLine(
          "Total Amount Due",
          (paymentDetails?.amountDue
            ? paymentDetails.amountDue.toFixed(2)
            : paymentData.amount.toFixed(2)) +
            " " +
            org.stripe.currency.toUpperCase()
        )}
        {renderInfoLine(
          "Amount Paid",
          (paymentDetails?.amountPaid
            ? paymentDetails.amountPaid.toFixed(2)
            : "0.00") +
            " " +
            org.stripe.currency.toUpperCase()
        )}
        {paymentDetails?.datePaid
          ? renderInfoLine(
              "Date Paid",
              allowables.dateTime(paymentDetails.datePaid, true).date
            )
          : null}

        {renderInfoLine(
          "Remaining Amount Due",
          (paymentDetails
            ? (
                (paymentDetails.amountDue || paymentData.amount) -
                (paymentDetails.amountPaid || 0)
              ).toFixed(2)
            : paymentData.amount.toFixed(2)) +
            " " +
            org.stripe.currency.toUpperCase()
        )}

        <div className="pop-box">
          <WarningHeader>
            <b>League Dues Refund Policy</b>
          </WarningHeader>
          <p
            className="text-left"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            {org?.misc?.refundPolicy ||
              "Your league has not set up a refund policy."}
          </p>
        </div>

        {paymentData.whoPays === "Team" && user._id !== team.teamCaptainID ? (
          <WarningHeader>
            Only the team captain can pay the invoice
          </WarningHeader>
        ) : !paymentDetails ||
          !paymentDetails.amountPaid ||
          (paymentDetails.amountPaid !== paymentDetails.amountDue &&
            paymentDetails.amountPaid < paymentData.amount) ? (
          <button
            className="btn btn-info btn-block"
            onClick={startCheckoutSession}
          >
            <b>Pay League Dues</b>
          </button>
        ) : (
          <WarningHeader>
            <b>No payment is due at this time</b>
          </WarningHeader>
        )}
      </div>
    </div>
  );
};

export default PlayerPaymentForm;
