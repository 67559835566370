import React, { useState, useEffect } from "react";

import allowables from "../../utils/allowables";
import SearchBox from "../common/dataSorting/searchBox";
import MailingList from "../common/dataSorting/mailingList";
import Table from "./../common/table/table";
import EventImage from "../assignedMatch/eventImage";
import CheckboxGroup from "../common/dataSorting/checkboxGroup";

const CustomFlagsTable = ({
  flagName,
  flagIndex,
  users,
  addUserToList,
  userList,
  onSubmitChanges,
  deselectAll,
}) => {
  const [search, setSearch] = useState("");
  const [selectedTypes, setSelectedTypes] = useState({
    on: false,
    off: true,
  });
  const [filteredUsers, setFilteredUsers] = useState([]);

  const columns = [
    { path: "image", label: "", content: (u) => <EventImage player={u} /> },
    {
      path: "name",
      label: "Name",
      content: (u) => allowables.splitName(u.name),
    },
    { path: "email", label: "Email" },
    { path: "teamName", label: "Team Name" },
    {
      path: "flag",
      label: flagName,
      content: (u) => (u["customFlag" + flagIndex] ? "true" : "false"),
    },
  ];

  const setFilters = () => {
    let pageUsers = [...users];
    if (search) {
      const query = search.toLowerCase();
      pageUsers = pageUsers.filter(
        (u) =>
          allowables.splitName(u.name).toLowerCase().includes(query) ||
          u.email.toLowerCase().includes(query) ||
          u.teamName?.toLowerCase().includes(query)
      );
    }
    if (!selectedTypes.off)
      pageUsers = pageUsers.filter((u) => u["customFlag" + flagIndex]);
    else if (!selectedTypes.on)
      pageUsers = pageUsers.filter((u) => !u["customFlag" + flagIndex]);

    setFilteredUsers(pageUsers);
  };

  useEffect(() => {
    setFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedTypes, users]);

  return (
    <React.Fragment>
      <CheckboxGroup
        header="Filter Data"
        onChange={(id) => {
          let currentTypes = { ...selectedTypes };
          currentTypes[id] = !currentTypes[id];
          setSelectedTypes(currentTypes);
        }}
        containerClassName="pop-box"
        colCount={2}
        checkboxes={[
          {
            id: "on",
            value: selectedTypes.on,
            label: `${flagName} On`,
          },
          {
            id: "off",
            value: selectedTypes.off,
            label: `${flagName} Off`,
          },
        ]}
      />
      <p className="text-center">
        Select all the users with information you have reviewed and click the
        Submit Changes button. Users currently marked as false will be marked as
        true, and vice-versa
      </p>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-block btn-sm btn-info"
            onClick={onSubmitChanges}
            disabled={userList.length === 0}
          >
            Submit Changes
          </button>
        </div>
        <div className="col-3">
          <button
            className="btn btn-block btn-sm btn-dark"
            onClick={deselectAll}
            disabled={userList.length === 0}
          >
            Deselect All
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SearchBox
            value={search}
            onChange={setSearch}
            placeholder="Search by name or email..."
          />
        </div>
        <div className="col-2">
          <MailingList
            users={filteredUsers}
            buttonTitle="Create Mailing List"
            margin="20px"
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={filteredUsers}
        keyProperty={"_id"}
        backgroundStyle="bg-info text-white"
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        onSelect={addUserToList}
        selectedRows={userList}
      />
    </React.Fragment>
  );
};

export default CustomFlagsTable;
