import React, { useEffect, useState, useContext } from "react";

import { getTeam } from "../../services/teamService";
import {
  getTactics,
  upsertTactic,
  deleteTactic,
} from "../../services/tacticsService";
import { getCurrentUser } from "../../services/userService";
import toast from "../../utils/toast";
import MiniHeader from "../common/pageComponents/miniHeader";
import TacticEditor from "./tacticEditor";
import HeaderContext from "../../context/headerContext";
import TacticGroup from "./tacticGroup";

const Tactics = () => {
  const { setLoading } = useContext(HeaderContext);
  const [team, setTeam] = useState(null);
  const [tactic, setTactic] = useState({});
  const [tactics, setTactics] = useState([]);
  const [field, setField] = useState({});
  const [players, setPlayers] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNew, setIsNew] = useState(true);

  const user = getCurrentUser();
  const getData = async () => {
    setLoading(true);
    const teamRes = await getTeam(user.teamID);
    if (teamRes.status === 200) {
      const tacticsRes = await getTactics();
      if (tacticsRes.status === 200) {
        setTeam(teamRes.data);
        setTactics(tacticsRes.data);
      } else toast.error(tacticsRes.data);
    } else toast.error(teamRes.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveTactic = async (id, tacticData, setter) => {
    setLoading(true);
    const fieldToSave = { ...field };
    delete fieldToSave.id;
    const res = await upsertTactic({
      _id: id,
      name: tacticData.name,
      description: tacticData.description,
      group: tacticData.group,
      ranking: tacticData.ranking,
      field: fieldToSave,
      players,
      lines,
    });
    if (res.status === 200) {
      setter(res.data);
      getData();
      toast.success("Tactic Saved");
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleDeleteTactic = async (tacticID) => {
    setLoading(true);
    const res = await deleteTactic(tacticID);
    if (res.status === 200) {
      toast.success("Tactic deleted");
      getData();
      return true;
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleRestart = () => {
    setTactic({});
    setCurrentPage(1);
    setPlayers([]);
    setLines([]);
    setSelectedPlayer(null);
  };

  return (
    <div>
      <MiniHeader>{team?.name} Tactics</MiniHeader>
      <div className="form-divided-section">
        {user.role.includes("captain") ? (
          <div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-sm btn-info btn-block"
                  onClick={() => {
                    handleRestart();
                    setCreateOpen(true);
                    setIsNew(true);
                  }}
                >
                  Create New Tactic
                </button>
              </div>
              {field.id ? (
                <div className="col-4">
                  <button
                    className="btn btn-sm btn-light btn-block"
                    onClick={() => {
                      setCreateOpen(true);
                    }}
                  >
                    Continue Editing
                  </button>
                </div>
              ) : null}
            </div>
            <TacticEditor
              team={team}
              tactic={tactic}
              setTactic={setTactic}
              onSave={handleSaveTactic}
              createOpen={createOpen}
              setCreateOpen={setCreateOpen}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isNew={isNew}
              field={field}
              setField={setField}
              players={players}
              setPlayers={setPlayers}
              lines={lines}
              setLines={setLines}
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              onRestart={handleRestart}
            />
          </div>
        ) : null}
        {tactics.map((group, idx) => (
          <div key={idx}>
            <TacticGroup
              group={group}
              key={idx}
              onEdit={(tactic) => {
                setTactic(tactic);
                setField({ ...tactic.field, id: "edit" });
                setPlayers(tactic.players);
                setLines(tactic.lines);
                setCreateOpen(true);
                setCurrentPage(3);
                setIsNew(false);
                setSelectedPlayer(null);
              }}
              onDelete={handleDeleteTactic}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tactics;
