import React, { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { IconContext } from "react-icons";
import { logout } from "../services/userService";
import { cancelMatchInProgress } from "../utils/liveScoresUtil";
import allowables from "../utils/allowables";
import { NavDropdown } from "react-bootstrap";
import {
  FaPlus,
  FaRegCalendarAlt,
  FaUserEdit,
  FaTable,
  FaQuestion,
  FaClipboardList,
  FaStripeS,
  FaFileSignature,
  FaFontAwesomeFlag,
} from "react-icons/fa";
import { CgBrackets } from "react-icons/cg";
import { FiUser, FiUsers } from "react-icons/fi";
import { TbSoccerField } from "react-icons/tb";
import {
  BiLogOut,
  BiMailSend,
  BiInfoCircle,
  BiUserCircle,
  BiLinkExternal,
} from "react-icons/bi";
import { GiTripleNeedle } from "react-icons/gi";
import { ImListNumbered, ImMakeGroup } from "react-icons/im";
import { MdCreditCard, MdScoreboard } from "react-icons/md";
import OrgLogo from "./common/pageComponents/orgLogo";
import CustomConfirm from "./common/customs/customConfirm";
import HeaderContext from "../context/headerContext";
import { navigateTo } from "./common/customs/customLinks";
import { BsArchiveFill } from "react-icons/bs";
import MainTitle from "./common/loading/mainTitle";
import IconRender from "./common/icons/iconRender";
import { getAccountText } from "../utils/textUtils";
import Tooltip from "./common/tooltip/tooltip";
import NewFeatureBadge from "./common/pageComponents/newFeatureBadge";

const NavBar = (props) => {
  const header = useContext(HeaderContext);
  let history = useHistory();
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
  const [clickedAccount, setClickedAccount] = useState(null);

  const user = props.user;
  if (!user)
    return (
      <div className="text-center light-bg cardview-pop">
        <MainTitle maxWidth={1000} minWidthForPortrait={500} />
      </div>
    );
  let role = "none";
  let orgID = "";
  if (user) {
    role = user.role;
    orgID = user.orgID;
    user.firstName = user.firstName
      ? user.firstName
      : user.name.split("%20%")[0];
    user.lastName = user.lastName ? user.lastName : user.name.split("%20%")[1];
  }

  const switchAccountCheck = (account) => {
    if (localStorage.getItem("matchStarted")) {
      setClickedAccount(account);
      return setSwitchAccountOpen(true);
    }
    header.switchAccountInUse(account);
  };

  const navigate = (path) => {
    navigateTo(path, history, header);
  };

  const renderLogo = () => {
    return (
      <Tooltip direction="right" content={<span>{props.org.name}</span>}>
        <OrgLogo _id={props.org.logo} name={props.org.name} />
      </Tooltip>
    );
  };

  const renderName = () => {
    return (
      <i>
        {user.name ? user.name.split("%20%")[0] : ""} {allowables.ballIcon()}
      </i>
    );
  };

  const renderHeader = () => {
    return header.pageHeader ? (
      <div className="navbar-header navbar-title">
        <h4>
          <b>{header.pageHeader}</b>
        </h4>
      </div>
    ) : null;
  };

  const restickyTops = () => {
    header.setAllowStickyItems(true);
  };

  const doLogout = async () => {
    await cancelMatchInProgress();
    logout();
    ReactGA.event({
      category: "User",
      action: "logged out",
      label: "clicked logout from navbar",
      nonInteraction: false,
    });
    window.location = "/";
  };

  return (
    <IconContext.Provider value={{ className: "icon-raised" }}>
      <nav className={"navbar nav-tabs light-bg cardview-pop"}>
        {user &&
          !user.role?.includes("admin") &&
          (props.org?.spectatingAllowed ? (
            <NavLink
              className="nav-item nav-link"
              to={`/${props.org?.spectatingAllowed}`}
              onClick={() => navigate(`/${props.org?.spectatingAllowed}`)}
            >
              {props.org && props.org.logo
                ? renderLogo()
                : user.orgName || "Create League"}
            </NavLink>
          ) : (
            <div className="navbar-header">
              {props.org && props.org.logo ? renderLogo() : user.orgName}
            </div>
          ))}
        {user && role?.includes("admin") && (
          <NavLink
            className="nav-item nav-link"
            to={"/league"}
            onClick={() => navigate("/league")}
          >
            {props.org && props.org.logo
              ? renderLogo()
              : user.orgName || "Create League"}
          </NavLink>
        )}
        {user && header.pageHeader && renderHeader()}

        {user && role && !role.includes("referee") && orgID && (
          <NavDropdown title={"View"} id="info-dropdown">
            {role.includes("admin") && (
              <div>
                <NavDropdown.Item
                  className="high-z nav-item"
                  onSelect={() => navigate("/fields")}
                >
                  <TbSoccerField /> Fields
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="high-z nav-item"
                  onSelect={() => navigate("/seasons")}
                >
                  <ImMakeGroup /> Seasons
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="high-z nav-item"
                  onSelect={() => navigate("/divisions")}
                >
                  <IconRender name="division" /> Divisions
                </NavDropdown.Item>
              </div>
            )}
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/teams")}
            >
              <FiUsers /> Teams
            </NavDropdown.Item>
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/players")}
            >
              <FiUser /> Players
            </NavDropdown.Item>
            {role.includes("admin") && (
              <NavDropdown.Item
                className="high-z nav-item"
                onSelect={() => navigate("/scoreboard")}
              >
                <MdScoreboard /> Scoreboards
              </NavDropdown.Item>
            )}
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/reports?standings=")}
            >
              <FaTable /> Standings
            </NavDropdown.Item>
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/reports?brackets=")}
            >
              <CgBrackets /> Brackets
            </NavDropdown.Item>
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/reports?leaderboards=")}
            >
              <ImListNumbered /> Leaderboards
            </NavDropdown.Item>
            <NavDropdown.Item
              className="high-z nav-item"
              onSelect={() => navigate("/archive?type=standings")}
            >
              <BsArchiveFill /> Archive
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {user && orgID && user.role?.includes("referee") && (
          <NavLink
            className="nav-item nav-link"
            to={"/matches?type=my%20matches"}
            onClick={() => navigate("/matches?type=my%20matches")}
          >
            Upcoming Matches
          </NavLink>
        )}
        {user && user.role && !user.role.includes("referee") && orgID && (
          <NavDropdown title="Matches" id="match-dropdown">
            {user.role?.includes("admin") && (
              <NavDropdown.Item
                className="high-z nav-item"
                onSelect={() => navigate("/create")}
              >
                <FaPlus /> Create Schedule
              </NavDropdown.Item>
            )}
            {props.org?.misc?.liveScorecards && (
              <NavDropdown.Item
                className="high-z nav-item"
                onSelect={() => navigate("/scorecards")}
              >
                <IconRender name="live" /> In Progress
              </NavDropdown.Item>
            )}
            {!user.role?.includes("referee") && (
              <div>
                <NavDropdown.Item
                  className="high-z nav-item"
                  onSelect={() => navigate("/matches")}
                >
                  <FaRegCalendarAlt /> Upcoming
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="high-z nav-item"
                  onSelect={() => navigate("/matchreview")}
                >
                  <FaClipboardList /> Played
                </NavDropdown.Item>
              </div>
            )}
          </NavDropdown>
        )}
        {user && (
          <NavDropdown title={renderName()} id="user-dropdown">
            <NavDropdown.Item
              onSelect={() => navigate("/profile")}
              className="high-z nav-item"
            >
              <FaUserEdit /> Profile
            </NavDropdown.Item>
            {user.role?.includes("owner") && (
              <NavDropdown.Item
                onSelect={() => navigate("/subscriptions")}
                className="high-z nav-item"
              >
                <FaStripeS /> Subscription
              </NavDropdown.Item>
            )}
            {user._id && header.userAccounts.length > 1 && (
              <NavDropdown
                title={<i>{<IconRender name="switch" />} Switch Account</i>}
                id="accounts-dropdown"
              >
                {header.userAccounts.map((ua, idx) => {
                  if (ua._id === user._id) return null;
                  return (
                    <NavDropdown.Item
                      onSelect={() => switchAccountCheck(ua)}
                      className="high-z nav-item"
                      key={ua._id}
                      style={{ minWidth: 250 }}
                    >
                      {getAccountText(ua, idx)}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            )}
            {user.role?.includes("player") &&
            user.teamID &&
            user.teamName !== "Free Agents" ? (
              <div>
                <NavDropdown.Item
                  onSelect={() => navigate(`/teams?info&q=${user.teamID}`)}
                  className="high-z nav-item"
                >
                  <FiUsers /> My Team
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={() => navigate(`/messages`)}
                  className="high-z nav-item"
                >
                  <IconRender name="email" /> Message Center <NewFeatureBadge />
                </NavDropdown.Item>
                {user.sport === "Soccer" ? (
                  <NavDropdown.Item
                    onSelect={() => navigate(`/tactics`)}
                    className="high-z nav-item"
                  >
                    <IconRender name="soccerField" /> Tactics{" "}
                    <NewFeatureBadge />
                  </NavDropdown.Item>
                ) : null}
              </div>
            ) : null}
            {user._id && user.orgID && (
              <NavDropdown
                title={<i>{<BiInfoCircle />} User Info</i>}
                id="info-dropdown"
              >
                {user.role &&
                  !user.role.includes("referee") &&
                  user.role !== "admin" &&
                  props.org && (
                    <NavDropdown.Item
                      onSelect={() => navigate("/payments")}
                      className="high-z nav-item"
                      style={{ minWidth: 250 }}
                    >
                      <MdCreditCard /> Payments
                    </NavDropdown.Item>
                  )}
                {(user.role?.includes("player") &&
                  props.org &&
                  props.org.codeOfConduct &&
                  props.org.codeOfConduct.length > 0) ||
                (user.role?.includes("admin") && props.org) ? (
                  <NavDropdown.Item
                    onSelect={() => navigate("/codeofconduct")}
                    className="high-z nav-item"
                  >
                    <FaFileSignature /> Code of Conduct
                  </NavDropdown.Item>
                ) : null}
                {props.org &&
                  props.org.requireVaccine &&
                  props.org.requireVaccine === "Yes" && (
                    <NavDropdown.Item
                      onSelect={() => navigate("/vaccine")}
                      className="high-z nav-item"
                    >
                      <GiTripleNeedle /> Vaccine
                    </NavDropdown.Item>
                  )}
                {user.role?.includes("admin") &&
                props.org &&
                props.org.misc &&
                props.org.misc.userImages === "Yes" ? (
                  <NavDropdown.Item
                    onSelect={() => navigate("/userimages")}
                    className="high-z nav-item"
                  >
                    <BiUserCircle /> Images
                  </NavDropdown.Item>
                ) : null}
                {user.role?.includes("admin") &&
                  ["customFlag1", "customFlag2"].map((f, i) => {
                    if (props.org?.misc && props.org.misc[f])
                      return (
                        <NavDropdown.Item
                          key={f}
                          onSelect={() => navigate("/customflags?q=" + (i + 1))}
                          className="high-z nav-item"
                        >
                          <FaFontAwesomeFlag /> {props.org.misc[f]}
                        </NavDropdown.Item>
                      );
                    else return null;
                  })}
              </NavDropdown>
            )}
            {props.org?.misc?.externalLinks?.length > 0 && (
              <NavDropdown
                title={<i>{<BiLinkExternal />} External Links</i>}
                id="info-dropdown"
              >
                {props.org.misc.externalLinks.map((l, i) => (
                  <NavDropdown.Item
                    onSelect={() => window.open(l.url)}
                    className="high-z nav-item"
                    key={l.text}
                    style={{ minWidth: 250 }}
                  >
                    <BiLinkExternal /> {l.text}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            )}

            <NavDropdown.Item
              onSelect={header.toggleContactForm}
              className="high-z nav-item"
            >
              <BiMailSend /> Contact
            </NavDropdown.Item>
            <NavDropdown.Item
              onSelect={() => navigate("/help")}
              className="high-z nav-item"
            >
              <FaQuestion /> Help and FAQs
            </NavDropdown.Item>
            <NavDropdown.Item
              onSelect={() => {
                header.setAllowStickyItems(false);
                setDialog("Are you sure you want to log out?");
                setConfirmOpen(true);
              }}
              className="high-z nav-item"
            >
              <BiLogOut /> Log Out
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </nav>
      <CustomConfirm
        dialog={dialog}
        callback={doLogout}
        isOpen={confirmOpen}
        close={setConfirmOpen}
        focused={true}
        yesNo={true}
        negativeCallback={restickyTops}
        alsoRunOnClose={restickyTops}
      />
      <CustomConfirm
        dialog="You have a match in progress. Switching accounts will cause all data to be lost. Are you sure?"
        callback={() => header.switchAccountInUse(clickedAccount)}
        isOpen={switchAccountOpen}
        close={setSwitchAccountOpen}
        focused={true}
        yesNo={true}
      />
    </IconContext.Provider>
  );
};

export default NavBar;
