import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import NotFound from "./notFound";
import LoginForm from "./loginLogoutRegister/loginForm";
import CodeEntryForm from "./loginLogoutRegister/codeEntryForm";
import ProtectedRoute from "./common/routing/protectedRoute";
import Profile from "./profile/profile";
import Reports from "./reports/reports";
import CreateHome from "./create/createHome";
import SubscriptionHome from "./payment/subscriptions/subscriptionHome";
import HelpPageMain from "./instructions/helpPageMain";
import DivisionRedirect from "./divisions/divisionRedirect";
import SeasonsRedirect from "./seasons/seasonsRedirect";
import TeamRedirect from "./teams/teamRedirect";
import PlayerRedirect from "./players/playerRedirect";
import MatchRedirect from "./matches/matchRedirect";
import MatchReviewRedirect from "./matchReview/matchReviewRedirect";
import AssignedMatchRedirect from "./assignedMatch/assignedMatchRedirect";
import CodeofConductInfo from "./codeOfConduct/codeOfConductInfo";
import VaccineMain from "./vaccine/vaccineMain";
import LiveScorecardsMain from "./scorecards/liveScorecardsMain";
import PaymentsRedirect from "./payment/playerPayments/paymentsRedirect";
import HomeRedirect from "./homepage/homeRedirect";
import SuperAdminHome from "./superadmin/superAdminHome";
import OrgRedirect from "./organization/orgRedirect";
import FreeAgentRegistrationPage from "./loginLogoutRegister/freeAgentRegistrationPage";
import ImageView from "./imageView/imageView";
import CustomFlagsView from "./customFlags/customFlagsView";
import PrepaymentSuccess from "./payment/playerPayments/prepaymentSuccess";
import ArchiveHome from "./archive/archiveHome";
import RegisterForm from "./loginLogoutRegister/registerForm";
import FinePrint from "./contactPolicies/finePrint";
import Unsubscribe from "./unsubscribe";
import DeepLinkRedirect from "./deepLinkRedirect";
import ScoreboardsHome from "./scoreboards/scoreboardsHome";
import FieldsRedirect from "./fields/fieldsRedirect";
import InvitationRegistrationPage from "./loginLogoutRegister/invitationRegistrationPage";
import MessageCenter from "./messages/messageCenter";
import Tactics from "./teamTactics/tactics";
import QuickStartForm from "./organization/quickStartForm";

const MainSwitchRouter = ({ org }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/subscriptions"
        component={SubscriptionHome}
        allowedRoles={["adminowner"]}
      />
      <ProtectedRoute
        exact
        path="/payments"
        component={PaymentsRedirect}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/superadminview"
        component={SuperAdminHome}
        allowedRoles={["superadmin", "internal"]}
      />

      <ProtectedRoute
        exact
        path="/gettingstarted"
        component={QuickStartForm}
        allowedRoles={["adminowner"]}
      />

      <Route exact path="/deeplink" component={DeepLinkRedirect} />

      <Route exact path="/privacy">
        <FinePrint openAll={true} standalone={true} />
      </Route>

      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/loginwithcode" component={CodeEntryForm} />

      <Route exact path="/help" component={HelpPageMain} />

      {/* signup using link from admin invitation */}
      <Route exact path="/signup" component={InvitationRegistrationPage} />

      {/* signup using link from player creation/invitation */}
      <Route exact path="/signupteam" component={InvitationRegistrationPage} />

      <Route exact path="/freeagent" component={FreeAgentRegistrationPage} />

      <Route exact path="/prepayment" component={PrepaymentSuccess} />

      <Route exact path="/unsubscribe" component={Unsubscribe} />

      <ProtectedRoute
        exact
        path="/archive"
        component={ArchiveHome}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <Route exact path="/register" component={RegisterForm} />

      <ProtectedRoute
        exact
        path="/matchreview"
        component={MatchReviewRedirect}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/league"
        component={OrgRedirect}
        allowedRoles={["admin", "adminowner"]}
      />

      <ProtectedRoute
        exact
        path="/codeofconduct"
        component={CodeofConductInfo}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/vaccine"
        component={VaccineMain}
        allowedRoles={["all"]}
      />

      <ProtectedRoute
        exact
        path="/scorecards"
        component={LiveScorecardsMain}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/scoreboard"
        component={ScoreboardsHome}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/userimages"
        component={ImageView}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/customflags"
        component={CustomFlagsView}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/divisions"
        component={DivisionRedirect}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/seasons"
        component={SeasonsRedirect}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/teams"
        component={TeamRedirect}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/tactics"
        component={Tactics}
        allowedRoles={["player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/fields"
        component={FieldsRedirect}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/players"
        component={PlayerRedirect}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/reports"
        component={Reports}
        allowedRoles={["adminowner", "admin", "player", "playercaptain"]}
      />

      <ProtectedRoute
        exact
        path="/assignedmatches"
        component={AssignedMatchRedirect}
        allowedRoles={["adminowner", "admin", "referee"]}
      />

      <ProtectedRoute
        exact
        path="/matches"
        component={MatchRedirect}
        allowedRoles={["all"]}
      />

      <ProtectedRoute
        exact
        path="/create"
        component={CreateHome}
        allowedRoles={["adminowner", "admin"]}
      />

      <ProtectedRoute
        exact
        path="/profile"
        component={Profile}
        allowedRoles={["all"]}
      />

      <ProtectedRoute
        exact
        path="/messages"
        component={MessageCenter}
        allowedRoles={["player", "playercaptain"]}
      />

      <Route exact path="/not-found" component={NotFound} />

      <Route exact path="/:leaguePageName" component={HomeRedirect} />
      <Route exact path="/" component={HomeRedirect} />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default MainSwitchRouter;
