import React from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

const SimpleTeamSegment = ({ match, players, teamOrder, onEvent }) => {
  return (
    <div className="row">
      {teamOrder.map((t) => {
        return (
          <div className="col text-center" key={t}>
            <button
              className="btn btn-lg btn-block btn-info"
              onClick={() => onEvent(null, { increment: 1, team: t })}
              aria-label="add-point"
            >
              <FaPlusCircle />
            </button>
            <br />
            <button
              className="btn btn-lg btn-block btn-dark"
              onClick={() => onEvent(null, { increment: -1, team: t })}
              aria-label="subtract-point"
            >
              <FaMinusCircle />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default SimpleTeamSegment;
