import React, { Component } from "react";
import orderBy from "lodash/orderBy";
import TableMultiHeader from "../../common/table/tableMultiHeader";
import { CLink } from "../../common/customs/customLinks";
import EventImage from "../../assignedMatch/eventImage";
import HorizontalButtons from "./../../common/dataSorting/horizontalButtons";
import Table from "../../common/table/table";
import TeamLogo from "../../common/pageComponents/teamLogo";
import allowables from "../../../utils/allowables";

class FootballLeaderboardTable extends Component {
  state = {
    sortColumn: { path: "points", order: "desc" },
    selectedType: "basic",
    columns: [],
    headerColumns: [],
    basicColumns: [],
  };

  componentDidMount() {
    const columns = [
      {
        key: "image",
        content: (p) => <EventImage player={p} />,
      },
      {
        path: "name",
        label: "Player Name",
        colspan: 1,
        content: (p) =>
          this.props.spectate ? (
            allowables.splitName(p.name)
          ) : (
            <CLink path={`/players?info&q=${p._id}`}>
              {allowables.splitName(p.name)}
            </CLink>
          ),
      },
      {
        path: "logo",
        label: "",
        content: (p) => (
          <TeamLogo team={p.teamID} name={p.teamName} size={35} />
        ),
      },
      {
        path: "teamName",
        label: "Team Name",
        colspan: 1,
        content: (p) =>
          this.props.spectate ? (
            p.teamName
          ) : (
            <CLink path={`/teams?info&q=${p.teamID._id}`}>{p.teamName}</CLink>
          ),
      },
      {
        path: "points",
        label: "Points",
        colspan: 1,
        content: (p) => p.events["points"] || 0,
      },
      {
        path: "Games",
        label: "Played",
        colspan: 1,
        content: (p) => p.events.Games,
      },
      {
        path: "Touchdown-passing",
        label: "TD",
        colspan: 1,
        content: (p) => p.events["Touchdown-passing"] || 0,
      },
      {
        path: "Extra Point-passing",
        label: "XP",
        colspan: 1,
        content: (p) => p.events["Extra Point-passing"] || 0,
      },
      {
        path: "Touchdown-receiving",
        label: "TD",
        colspan: 1,
        content: (p) => p.events["Touchdown-receiving"] || 0,
      },
      {
        path: "Extra Point-receiving",
        label: "XP",
        colspan: 1,
        content: (p) => p.events["Extra Point-receiving"] || 0,
      },
      {
        path: "Touchdown-rushing",
        label: "TD",
        colspan: 1,
        content: (p) => p.events["Touchdown-rushing"] || 0,
      },
      {
        path: "Extra Point-rushing",
        label: "XP",
        colspan: 1,
        content: (p) => p.events["Extra Point-rushing"] || 0,
      },
      {
        path: "Field Goal-kicking",
        label: "FG",
        colspan: 1,
        content: (p) => p.events["Field Goal-kicking"] || 0,
      },
      {
        path: "Extra Point-kicking",
        label: "XP",
        colspan: 1,
        content: (p) => p.events["Extra Point-kicking"] || 0,
      },
      {
        path: "Defense-interception",
        label: "Int",
        colspan: 1,
        content: (p) => p.events["Defense-interception"] || 0,
      },
      {
        path: "Defense-sack",
        label: "Sk",
        colspan: 1,
        content: (p) => p.events["Defense-sack"] || 0,
      },
      {
        path: "Defense-fumble",
        label: "FR",
        colspan: 1,
        content: (p) => p.events["Defense-fumble"] || 0,
      },
      {
        path: "Defense-puntReturn",
        label: "Ret",
        colspan: 1,
        content: (p) => p.events["Defense-puntReturn"] || 0,
      },
      {
        path: "Penalty-penalty",
        label: "#",
        colspan: 1,
        content: (p) => p.events["Penalty-penalty"] || 0,
      },
      {
        path: "penaltyYards",
        label: "Yards",
        colspan: 1,
        content: (p) => p.events["penaltyYards"] || 0,
      },
    ];

    let headerColumns = [
      [
        { path: "image", label: "", colspan: 1, rowspan: 2 },
        {
          path: "name",
          label: "Player Name",
          colspan: 1,
          rowspan: 2,
        },
        {
          path: "logo",
          label: "",
          colspan: 1,
          rowspan: 2,
        },
        {
          path: "teamName",
          label: "Team Name",
          colspan: 1,
          rowspan: 2,
        },
        { label: "Totals", colspan: 2, path: "points" },
        { label: "Passing", colspan: 2, path: "passing" },
        { label: "Receiving", colspan: 2, path: "receiving" },
        { label: "Rushing", colspan: 2, path: "rushing" },
        { label: "Kicking", colspan: 2, path: "kicking" },
        { label: "Defense", colspan: 4, path: "defense" },
        { label: "Penalties", colspan: 2, path: "penalty" },
      ],
      columns,
    ];

    const basicColumns = [
      { path: "image", content: (p) => <EventImage player={p} /> },
      {
        path: "name",
        label: "Player Name",
        content: (p) =>
          this.props.spectate ? (
            p.name
          ) : (
            <CLink path={`/players?info&q=${p._id}`}>
              {allowables.splitName(p.name)}
            </CLink>
          ),
      },
      {
        path: "teamName",
        label: "Team Name",
        content: (p) =>
          this.props.spectate ? (
            p.teamName
          ) : (
            <CLink path={`/teams?info&q=${p.teamID}`}>{p.teamName}</CLink>
          ),
      },
      { path: "touchdowns", label: "Touchdowns" },
      { path: "extraPoints", label: "Extra Points" },
      { path: "fieldGoals", label: "Field Goals" },
      {
        path: "defense",
        label: "Defensive Plays",
      },

      { path: "points", label: "Total Points" },
      { path: "games", label: "Played" },
      {
        path: "penaltyYards",
        label: "Penalty Yards",
      },
    ];

    if (this.props.isHistorical) {
      const leagueCol = {
        path: "orgName",
        label: "League Name",
      };
      columns.splice(0, 4);
      columns.splice(1, 1);
      columns.unshift(leagueCol);

      headerColumns[0].splice(0, 3);
      headerColumns[0].splice(0, 1, {
        label: "Totals",
        colspan: 2,
        path: "points",
      });
      headerColumns = [[...headerColumns[0]], columns];
      basicColumns.splice(0, 3);
      basicColumns.splice(4, 1);
      basicColumns.splice(0, 0, leagueCol);
    }

    this.setState({ columns, headerColumns, basicColumns });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSelect = (selectedType) => {
    this.setState({ selectedType });
  };

  filterForSearch = (leaderboard) => {
    const { searchQuery } = this.props;
    let filteredLeaderboard = [...leaderboard];
    if (searchQuery) {
      filteredLeaderboard = leaderboard.filter(
        (p) =>
          allowables
            .splitName(p.name.toLowerCase())
            .includes(searchQuery.toLowerCase()) ||
          p.teamName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return filteredLeaderboard;
  };

  getPageDataBasic = () => {
    const { sortColumn } = this.state;
    const { data: leaderboard } = this.props;

    let basicLeaderboard = [];
    leaderboard.forEach((p) => {
      let newPlayer = {
        ...p,
        touchdowns: p.events.touchdowns || 0,
        fieldGoals: 0,
        extraPoints: 0,
        penaltyYards: p.events.penaltyYards,
        points: p.events.points,
        games: p.events.Games,
        defense: 0,
      };
      for (let property in p.events) {
        const lcProp = property.toLowerCase();

        if (lcProp.includes("extra point"))
          newPlayer.extraPoints = newPlayer.extraPoints + p.events[property];
        if (lcProp.includes("field goal"))
          newPlayer.fieldGoals = newPlayer.fieldGoals + p.events[property];
        if (lcProp.includes("defense"))
          newPlayer.defense =
            (newPlayer.defense || 0) + (p.events[property] || 0);
      }
      basicLeaderboard.push(newPlayer);
    });

    let filteredLeaderboard = this.filterForSearch(basicLeaderboard);

    const sortedLeaderboard = orderBy(
      filteredLeaderboard,
      [sortColumn.path],
      [sortColumn.order]
    );
    return sortedLeaderboard;
  };

  getPageData = () => {
    const { sortColumn } = this.state;
    const { data: leaderboard } = this.props;

    let filteredLeaderboard = this.filterForSearch(leaderboard);
    let sortedLeaderboard = filteredLeaderboard;
    if (["name", "teamName"].includes(sortColumn.path))
      sortedLeaderboard = orderBy(
        filteredLeaderboard,
        [sortColumn.path],
        [sortColumn.order]
      );
    else
      sortedLeaderboard = orderBy(
        filteredLeaderboard,
        [(item) => item.events[sortColumn.path]],
        [
          sortColumn.path.includes("-")
            ? sortColumn.order === "asc"
              ? "desc"
              : "asc"
            : sortColumn.order,
        ]
      );

    return sortedLeaderboard;
  };

  render() {
    const data = this.getPageData();
    const basicData = this.getPageDataBasic();
    const { selectedType, sortColumn } = this.state;

    return (
      <React.Fragment>
        <HorizontalButtons
          buttons={["basic", "detail"]}
          onClick={this.handleSelect}
          selectedType={selectedType}
          custom={true}
        />
        <br />
        {selectedType === "basic" ? (
          <Table
            columns={this.state.basicColumns}
            data={basicData}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            keyProperty={"_id"}
            tableClass="table-custom"
            headerClass="thead-custom"
            bodyClass="tbody-custom"
            highlightOnHover={true}
            onSelect={this.props.onSelectPlayer}
            initialSortOrder="desc"
          />
        ) : (
          <TableMultiHeader
            headerColumns={this.state.headerColumns}
            columns={this.state.columns}
            tableData={data}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            keyProperty={"_id"}
            tableClass="table-custom"
            headerClass="thead-custom"
            bodyClass="tbody-custom"
            highlightOnHover={true}
            onSelect={this.props.onSelectPlayer}
            initialSortOrder="desc"
          />
        )}
        {data.length === 0 && this.props.data.length !== 0 && (
          <p>Only players with points or penalty yards are included.</p>
        )}
      </React.Fragment>
    );
  }
}

export default FootballLeaderboardTable;
