import http from "./httpService";

async function getVersion() {
  try {
    return await http.get(http.versionEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

async function getVersionByFull(full) {
  try {
    return await http.get(http.versionEndpoint + "/" + full);
  } catch (ex) {
    return ex.response;
  }
}

export { getVersion, getVersionByFull };
