import React, { Component } from "react";
import { getPlayoffMatches } from "../../services/matchService";
import NonFormSelect from "../common/form/nonFormSelect";
import InfoTooltip from "../common/tooltip/infoTooltip";
import { getCurrentUser } from "./../../services/userService";
import QRCodeRender from "../common/pageComponents/qrCode";
import HeaderContext from "../../context/headerContext";
import PlayoffBracketCanvas from "../brackets/playoffBracketCanvas";
import SeasonDisplay from "./seasonDisplay";
import WarningHeader from "../common/pageComponents/warningHeader";

class Brackets extends Component {
  static contextType = HeaderContext;
  state = {
    divisionPlayoffMatches: [],
    divisions: this.props.divisions,
    selectedDivision: this.props.divisionID,
  };

  async componentDidMount() {
    if (this.props.divisionID)
      await this.handleChange({ target: { value: this.props.divisionID } });
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleChange = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { value: divisionID } = event.target;
    if (divisionID === "") return this.context.setLoading(false);
    const response = await getPlayoffMatches(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.props.onDivisionChange(divisionID);
      this.setState({
        divisionPlayoffMatches: response.data,
        selectedDivision: divisionID,
      });
    }
    this.context.setLoading(false);
  };

  render() {
    const user = getCurrentUser();
    const { selectedDivision, divisions } = this.state;
    const currentDivision = divisions.find((d) => d._id === selectedDivision);

    return (
      <div>
        <br />
        <SeasonDisplay season={currentDivision?.seasonID} />
        <div className="row">
          <div className="col">
            <NonFormSelect
              name="division"
              label="Select Division:&nbsp;"
              options={this.state.divisions}
              onChange={this.handleChange}
              value={this.state.selectedDivision}
            />
          </div>
          {this.state.divisionPlayoffMatches.length !== 0 && (
            <React.Fragment>
              <div className="col-2">
                <InfoTooltip
                  header="Playoff Brackets"
                  className="icon-md icon-info"
                  direction="bottom"
                  text={`All scheduled playoff matches are displayed. 
                If the winning team is not in bold then the match has not yet been accepted and the result may be subject to change. ${
                  user.role.includes("admin")
                    ? `If your bracket does not display correctly please 
                      check that all matches have a Round Number and Match Number.`
                    : `If the bracket does not display correctly
                      please let your division admin know.`
                }`}
                />
              </div>
            </React.Fragment>
          )}
          {this.props.qrLink && (
            <div className="col-3">
              <QRCodeRender
                value={`${this.props.qrLink}${this.state.selectedDivision}&option=brackets`}
                size={100}
              />
            </div>
          )}
        </div>
        <div>
          {this.state.divisionPlayoffMatches.length === 0 &&
            this.state.selectedDivision && (
              <WarningHeader>
                There are no brackets available for this division.
              </WarningHeader>
            )}
          {this.state.divisionPlayoffMatches.length !== 0 && (
            <PlayoffBracketCanvas matches={this.state.divisionPlayoffMatches} />
          )}
        </div>
      </div>
    );
  }
}

export default Brackets;
