import React from "react";
import MiniHeader from "../common/pageComponents/miniHeader";
import IconRender from "../common/icons/iconRender";
import WebpageDivider from "./webpageDivider";

const LeagueContactInfo = ({
  org,
  bgColor = "",
  bgTextColor = "",
  textColor = "",
  highlightColor = "",
}) => {
  if (!org) return null;

  const info = org.misc || {};
  const email = info.customerServiceEmail || org.ownerEmail;

  return (
    <div>
      <MiniHeader
        overrideStyle={{ backgroundColor: highlightColor, color: textColor }}
      >
        Contact this League
      </MiniHeader>
      <div
        className="form-divided-section text-center"
        style={{
          borderColor: highlightColor,
          backgroundColor: bgColor,
          color: bgTextColor,
        }}
      >
        <a
          href={`mailto:${email}`}
          className="clink"
          style={{ color: bgTextColor }}
        >
          <h6>
            <IconRender name="email" />
            &nbsp;{email}
          </h6>
        </a>
        <WebpageDivider color={highlightColor} />
        <a
          href={`tel:${info.customerServicePhoneNumber}`}
          className="clink"
          style={{ color: bgTextColor }}
        >
          <h6>
            <IconRender name="phone" />
            &nbsp;{info.customerServicePhoneNumber}
          </h6>
        </a>
        <WebpageDivider color={highlightColor} />
        <a
          href={info.leagueWebsiteAddress}
          target="_blank"
          rel="noopener noreferrer"
          className="clink"
          style={{ color: bgTextColor }}
        >
          <h6>
            <IconRender name="web" />
            &nbsp;{info.leagueWebsiteAddress}
          </h6>
        </a>
      </div>
    </div>
  );
};

export default LeagueContactInfo;
