import React from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { VscOpenPreview } from "react-icons/vsc";

import { CLink } from "../common/customs/customLinks";

const VaccineStatus = ({ org, user }) => {
  const checkVaccineSubmission = () => {
    return user.vaccine && user.vaccine.date && user.vaccine.date;
  };

  return org && org.requireVaccine === "Yes" ? (
    <React.Fragment>
      {checkVaccineSubmission() && user.vaccine.reviewed ? (
        <CLink path="vaccine" style={{ margin: 5 }}>
          <ImCheckboxChecked />
          &nbsp;&nbsp;&nbsp;Vaccine information submitted - accepted
        </CLink>
      ) : checkVaccineSubmission() ? (
        <CLink path="/vaccine" style={{ margin: 5 }}>
          <VscOpenPreview />
          &nbsp;&nbsp;&nbsp;Vaccine information submitted - under review
        </CLink>
      ) : (
        <CLink path="/vaccine" style={{ margin: 5 }}>
          <ImCheckboxUnchecked />
          &nbsp;&nbsp;&nbsp;Submit your vaccine information information
        </CLink>
      )}
    </React.Fragment>
  ) : null;
};

export default VaccineStatus;
