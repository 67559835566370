import React from "react";

import { CLink } from "../common/customs/customLinks";
import ActiveSubscription from "../payment/subscriptions/activeSubscription";
import PaidFeature from "../common/tooltip/paidFeature";

const OrgHeader = ({ org, subscription, customer, onClick }) => {
  return (
    <div>
      <div className="row">
        <div className="col clickable" onClick={onClick}>
          <ActiveSubscription
            currentTier={subscription}
            buttons={false}
            defaultCard={customer?.defaultCard}
          />
        </div>
      </div>
      <br />
      {org.spectatingAllowed && org.spectatingAllowed !== "blocked" && (
        <CLink path={`/${org.spectatingAllowed}?edit`} button={true}>
          <div className="row">
            <div className="col-xs">
              <PaidFeature />
            </div>
            <div className="col">View/Edit my League Home Page</div>
          </div>
        </CLink>
      )}
    </div>
  );
};

export default OrgHeader;
