import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import toast from "../../utils/toast";
import { loadStripe } from "@stripe/stripe-js";

import { getOrgbyID } from "../../services/orgService";
import {
  registerFreeAgent,
  registerTeamCaptain,
} from "../../services/userService";
import OrgLogo from "../common/pageComponents/orgLogo";
import CombinedRegistrationForm from "./combinedRegistrationForm";
import HeaderContext from "../../context/headerContext";
import { getPrepaymentCheckout } from "../../services/paymentService";
import { stripeKey } from "../../utils/paymentsUtil";
import NonFormSelect from "../common/form/nonFormSelect";
import MiniHeader from "../common/pageComponents/miniHeader";
import QRCodeRender from "../common/pageComponents/qrCode";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";

class FreeAgentRegistrationPage extends Component {
  static contextType = HeaderContext;
  state = {
    league: {
      name: "",
      logo: "",
    },
    noInfo: false,
    regTypes: [],
    regType: "",
    regNames: {
      freeAgent: "Free Agent",
      teamCaptain: "Team Captain",
    },
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.location.search) return this.setState({ noInfo: true });
    const queryParams = this.props.location.search.split("&");
    const orgID = queryParams.find((q) => q.includes("id")).split("=")[1];
    const orgRes = await getOrgbyID(orgID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (orgRes.status === 200) {
      let regTypes = [];
      const activeLinks = orgRes.data.misc?.activeRegistrationLinks;
      if (activeLinks) {
        Object.keys(activeLinks).forEach((k) => {
          if (activeLinks[k])
            regTypes.push({ _id: k, name: this.state.regNames[k] });
        });
      }
      this.setState({ league: orgRes.data, regTypes });
    } else this.setState({ noInfo: true });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleSubmit = async (data, formType) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    let userData = { ...data };
    userData.orgID = this.state.league._id;
    userData.name = data.firstName + "%20%" + data.lastName;
    delete userData.firstName;
    delete userData.lastName;
    if (this.state.regType === "freeAgent") {
      const res = await registerFreeAgent(userData, formType, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (res.status === 200) {
        toast.success("Player added");
        return (window.location = "/profile");
      } else if (res.status === 402) {
        // redirect to payment page
        toast.info(
          `${this.state.league.name} requires payment of league dues before completing registration. Please wait to be redirected to make your payment.`
        );
        const checkoutRes = await getPrepaymentCheckout(
          this.state.league._id,
          userData,
          this.props.location.pathname + this.props.location.search
        );
        if (checkoutRes.status === 200) {
          const stripePromise = await loadStripe(stripeKey(), {
            stripeAccount: checkoutRes.data.accountID,
          });
          return setTimeout(() => {
            stripePromise.redirectToCheckout({
              sessionId: checkoutRes.data.sessionID,
            });
          }, 1000);
        } else toast.error(checkoutRes.data);
      } else {
        toast.error(res.data);
      }
    } else if (this.state.regType === "teamCaptain") {
      // register a new team captain
      const res = await registerTeamCaptain(userData, formType, {
        callback: this.indicateProgress,
        bar: 0,
      });

      if (res.status === 200) {
        return (window.location = "/teams?q=new");
      } else toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  render() {
    const { league, noInfo } = this.state;

    return noInfo ? (
      <Redirect
        to={{
          pathname: "/not-found",
        }}
      />
    ) : (
      <div className="centered-small-input-area">
        <div className="text-center">
          {league.logo && (
            <OrgLogo _id={league.logo} name={league.name} width={300} />
          )}
          <MiniHeader>
            Register for
            <br />
            <b>{league.name}</b>
          </MiniHeader>
          <div className="form-divided-section">
            {this.state.regTypes.length === 0 ? (
              <WarningHeader>
                This league does not allow direct player registration. Please
                contact your league admin or team captain to register.
              </WarningHeader>
            ) : (
              <div>
                <NonFormSelect
                  name="type"
                  label="I am a:&nbsp;"
                  options={this.state.regTypes}
                  onChange={(e) => this.setState({ regType: e.target.value })}
                  value={this.state.regType}
                  overrideDefaultPlaceholder="..."
                />

                {this.state.regType === "freeAgent" ? (
                  <div>
                    <h5>
                      <b>Register as a Free Agent</b>
                    </h5>
                    <p>
                      Register as an individual player and wait to be placed on
                      a team. A team captain may recruit you or a league admin
                      may place you on your final team.
                      <br />
                      If you are already part of an existing team playing in
                      this league please do not use this page and instead ask
                      your team captain to send you a registration link.
                    </p>
                  </div>
                ) : this.state.regType === "teamCaptain" ? (
                  <div>
                    <h5>
                      <b>Register your Team</b>
                    </h5>
                    <p>
                      After signing up you will be able to create your team and
                      invite your players.
                    </p>
                  </div>
                ) : null}
                {league.misc?.requirePaymentBeforeSignup === "Yes" && (
                  <h6>
                    <b>
                      You will be redirected to pay your league dues online
                      prior to your registration completing
                    </b>
                  </h6>
                )}
                <br />
              </div>
            )}

            {(this.state.regType === "teamCaptain" ||
              this.state.regType === "freeAgent") && (
              <CombinedRegistrationForm onSubmit={this.handleSubmit} />
            )}
          </div>
        </div>
        <QRCodeRender
          value={`${allowables.url}${this.props.location.pathname}${this.props.location.search}`}
        />
      </div>
    );
  }
}

export default FreeAgentRegistrationPage;
