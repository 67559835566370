import React, { Component } from "react";
import Table from "../../common/table/table";
import orderBy from "lodash/orderBy";
import { CLink } from "../../common/customs/customLinks";
import EventImage from "../../assignedMatch/eventImage";
import TeamLogo from "../../common/pageComponents/teamLogo";
import allowables from "../../../utils/allowables";
import OrgContext from "../../../context/orgContext";

class SoccerLeaderboardTable extends Component {
  static contextType = OrgContext;

  state = {
    sortColumn: { path: "Goals", order: "desc" },
    columns: [],
  };

  componentDidMount() {
    const columns = [
      {
        key: "image",
        content: (p) => <EventImage player={p} />,
      },
      {
        path: "name",
        label: "Player Name",
        content: (p) =>
          this.props.spectate ? (
            allowables.splitName(p.name)
          ) : (
            <CLink path={`/players?info&q=${p._id}`}>
              {allowables.splitName(p.name)}
            </CLink>
          ),
      },
      {
        path: "logo",
        label: "",
        content: (p) => (
          <TeamLogo team={p.teamID} name={p.teamName} size={35} />
        ),
      },
      {
        path: "teamName",
        label: "Team Name",
        content: (p) =>
          this.props.spectate ? (
            p.teamName
          ) : (
            <CLink path={`/teams?info&q=${p.teamID._id}`}>{p.teamName}</CLink>
          ),
      },
      { path: "Goals", label: "Goals", content: (p) => p.events.Goals },
      { path: "Assists", label: "Assists", content: (p) => p.events.Assists },
      {
        path: "RedCards",
        label: "Red Cards",
        content: (p) => p.events.RedCards,
      },
      {
        path: "YellowCards",
        label: "Yellow Cards",
        content: (p) => p.events.YellowCards,
      },
      { path: "Games", label: "Played", content: (p) => p.events.Games },
    ];

    if (!this.context.misc?.soccerSportOptions?.trackAssists)
      columns.splice(5, 1);

    if (this.props.isHistorical) {
      columns.splice(0, 4);
      columns.splice(0, 0, {
        path: "orgName",
        label: "League Name",
      });
      columns.splice(-1, 1);
    }

    this.setState({ columns });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPageData = () => {
    const { sortColumn } = this.state;
    const { data: leaderboard, searchQuery } = this.props;

    let filteredLeaderboard = leaderboard;
    if (searchQuery) {
      filteredLeaderboard = leaderboard.filter(
        (p) =>
          allowables
            .splitName(p.name.toLowerCase())
            .includes(searchQuery.toLowerCase()) ||
          p.teamName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    let sortedLeaderboard = filteredLeaderboard;
    if (["name", "teamName", "orgName"].includes(sortColumn.path))
      sortedLeaderboard = orderBy(
        filteredLeaderboard,
        [sortColumn.path],
        [sortColumn.order]
      );
    else
      sortedLeaderboard = orderBy(
        filteredLeaderboard,
        [(item) => item.events[sortColumn.path], "points"],
        [sortColumn.order, sortColumn.order === "asc" ? "desc" : "asc"]
      );

    return sortedLeaderboard;
  };

  render() {
    const data = this.getPageData();

    return (
      <React.Fragment>
        <Table
          columns={this.state.columns}
          data={data}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          keyProperty={this.props.keyProperty || "_id"}
          tableClass="table-custom"
          headerClass="thead-custom"
          bodyClass="tbody-custom"
          highlightOnHover={true}
          onSelect={this.props.onSelectPlayer}
        />
        {data.length === 0 && this.props.data.length !== 0 && (
          <p>Only players with a goal or a card are included.</p>
        )}
      </React.Fragment>
    );
  }
}

export default SoccerLeaderboardTable;
