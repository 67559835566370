import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";
import allowables from "../../utils/allowables";
import HeaderContext from "../../context/headerContext";
import { navigateBack, navigateTo } from "../common/customs/customLinks";
import CustomConfirm from "./../common/customs/customConfirm";
import SideBySideView from "./../common/pageComponents/sideBySideView";
import MiniHeader from "../common/pageComponents/miniHeader";

class MatchForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      divisionName: "",
      homeTeamID: "",
      placeholderHomeTeamName: "",
      awayTeamID: "",
      placeholderAwayTeamName: "",
      dateTime: "",
      refereeEmail: "",
      fieldID: "",
      type: "",
      groupName: "",
      round: "",
      matchNumber: "",
      sendNotifications: false,
    },
    divisions: [],
    teams: {},
    teamsByGroup: [],
    groups: [],
    fields: [],
    referees: [],
    errors: {},
    currentMatch: null,
    baseState: {
      divisionName: "",
      homeTeamID: "",
      placeholderHomeTeamName: "",
      awayTeamID: "",
      placeholderAwayTeamName: "",
      dateTime: "",
      refereeEmail: "",
      fieldID: "",
      type: "",
      groupName: "",
      round: "",
      sendNotifications: false,
    },
    apiError: "",
    types: [
      { _id: "regularSeason", name: "Regular Season" },
      { _id: "playoff", name: "Playoff" },
      { _id: "group", name: "Group" },
      { _id: "seeding", name: "Seeding" },
      { _id: "friendly", name: "Friendly" },
    ],
    deleteOpen: false,
    // start with all sections open, there are required selections in every section
    infoSectionOpen: true,
    refereeSectionOpen: true,
  };

  schema = {
    divisionName: Joi.string().required().label("Division"),
    homeTeamID: Joi.string().required().label("Home Team"),
    placeholderHomeTeamName: Joi.string()
      .optional()
      .allow("")
      .label("Home Team Placeholder"),
    awayTeamID: Joi.string()
      .required()
      .label("Away Team")
      .invalid(Joi.ref("homeTeamName")),
    placeholderAwayTeamName: Joi.string()
      .optional()
      .allow("")
      .label("Away Team Placeholder"),
    dateTime: Joi.date().optional().allow("").label("Date & Time"),
    refereeEmail: Joi.string()
      .required()
      .label(allowables.refOrUmp(this.props.org?.sport)),
    fieldID: Joi.string().optional().allow("").label("Field"),
    type: Joi.string()
      .required()
      .valid(allowables.matchTypes)
      .label("Match Type"),
    groupName: Joi.string().optional().allow("").label("Group Name"),
    round: Joi.number().integer().optional().allow("").min(0).label("Round"),
    matchNumber: Joi.number()
      .integer()
      .optional()
      .allow("")
      .min(0)
      .label("Match Number"),
    sendNotifications: Joi.boolean().label("Send Push Notifications"),
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1, 1]);
    const user = this.props.getCurrentUser();
    if (!user || !user.role.includes("admin"))
      return navigateBack(this.props.history, this.context);
    let divisionsResponse = await this.props.getDivisions({
      callback: this.indicateProgress,
      bar: 0,
    });
    let teamsResponse = await this.props.getTeams({
      callback: this.indicateProgress,
      bar: 1,
    });
    let usersReponse = await this.props.getUsersForOrg({
      callback: this.indicateProgress,
      bar: 2,
    });
    const fieldsRes = await this.props.getFields({
      callback: this.indicateProgress,
      bar: 4,
    });
    let referees = [];
    if (usersReponse.status === 200) {
      usersReponse.data.forEach((u) => {
        if (u.role.includes("admin") || u.role.includes("referee"))
          referees.push(u);
      });
      if (divisionsResponse.status === 200) {
        if (fieldsRes.status === 200) {
          let divisions = orderBy(divisionsResponse.data, ["name"], ["asc"]);
          if (teamsResponse.status === 200) {
            let teamsByDivision = {};
            divisions.forEach((l) => {
              let divisionTeams = [];
              teamsResponse.data.forEach((t) => {
                if (t.divisionID === l._id) divisionTeams.push(t);
              });
              teamsByDivision[l.name] = divisionTeams;
            });
            let groups = [];
            teamsResponse.data.forEach((t) => {
              if (!groups.includes(t.groupName)) groups.push(t.groupName);
            });
            let teamsByGroup = {};
            groups.forEach((g) => {
              let groupTeams = [];
              teamsResponse.data.forEach((t) => {
                if (t.groupName === g) groupTeams.push(t);
              });
              teamsByGroup[g] = groupTeams;
            });
            groups = groups.filter((g) => {
              return g !== undefined;
            });
            let groupsObj = [];
            groups.forEach((g) => {
              let newGroup = {
                name: g,
                _id: g,
                divisionID: teamsResponse.data.find((t) => t.groupName === g)
                  .divisionID,
                divisionName: teamsResponse.data.find((t) => t.groupName === g)
                  .divisionName,
              };
              groupsObj.push(newGroup);
            });
            this.setState({
              referees,
              divisions,
              teams: teamsByDivision,
              teamsByGroup,
              groups: groupsObj,
              fields: fieldsRes.data,
            });

            const { matchID } = this.props;
            if (matchID === "new") return this.context.setLoading(false);

            const matchResponse = await this.props.getMatch(matchID, {
              callback: this.indicateProgress,
              bar: 4,
            });

            if (matchResponse.status === 404)
              return this.props.history.replace("/not-found");
            if (matchResponse.status === 200) {
              teamsResponse.data.forEach((t) => {
                if (t.name === matchResponse.data.homeTeamName)
                  matchResponse.data.divisionName = t.divisionName;
              });

              this.setState({
                data: this.mapToViewModel(matchResponse.data),
                currentMatch:
                  matchResponse.data.homeTeamName +
                  " vs " +
                  matchResponse.data.awayTeamName,
                baseState: matchResponse.data,
              });
            } else toast.error(matchResponse.data);
          } else toast.error(teamsResponse.data);
        } else toast.error(fieldsRes.data);
      } else toast.error(divisionsResponse.data);
    } else toast.error(usersReponse.data);
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  mapToViewModel(match) {
    return {
      divisionName: match.divisionName || "",
      homeTeamID: match.homeTeamID || "",
      placeholderHomeTeamName:
        match.homeTeamID === allowables.dummyTeam._id ? match.homeTeamName : "",
      awayTeamID: match.awayTeamID || "",
      placeholderAwayTeamName:
        match.awayTeamID === allowables.dummyTeam._id ? match.awayTeamName : "",
      dateTime: allowables.formDate(match.dateTime) || "",
      refereeEmail: match.refereeEmail || "",
      fieldID: match.fieldID || "",
      type: match.type || "",
      groupName: match.groupName || "",
      round: match.round || (match.round === 0 ? 0 : ""),
      matchNumber: match.matchNumber || "",
      sendNotifications: false,
    };
  }

  handleReset = (event) => {
    event.preventDefault();
    this.setState({ data: this.mapToViewModel(this.state.baseState) });
  };

  handleCancel = (event) => {
    event.preventDefault();
    navigateTo("/matches", this.props.history, this.context);
  };

  handleDateSelect = (selectedDate) => {
    const data = { ...this.state.data };
    data.dateTime = selectedDate;
    this.setState({ data });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await this.props.deleteMatch(this.props.matchID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      return navigateBack(this.props.history, this.context);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { matchID } = this.props;
    let homeTeamName;
    let awayTeamName;
    let homeTeamAbbreviation;
    let awayTeamAbbreviation;
    let homeTeamCaptainID;
    let awayTeamCaptainID;
    let refereeID;
    let refereeName;
    let divisionID;
    let sport;
    let { teams, referees, divisions } = this.state;
    let data = { ...this.state.data };

    teams[data.divisionName].forEach((t) => {
      if (t._id === data.homeTeamID) {
        homeTeamName = t.name;
        homeTeamAbbreviation = t.teamAbbreviation;
        homeTeamCaptainID = t.teamCaptainID ? t.teamCaptainID._id : null;
      }
      if (t._id === data.awayTeamID) {
        awayTeamName = t.name;
        awayTeamAbbreviation = t.teamAbbreviation;
        awayTeamCaptainID = t.teamCaptainID ? t.teamCaptainID._id : null;
      }
    });

    // if dummy team change team name to match input from placeholder
    if (data.homeTeamID === allowables.dummyTeam._id) {
      homeTeamName = data.placeholderHomeTeamName || allowables.dummyTeam.name;
      homeTeamAbbreviation = "";
    }
    if (data.awayTeamID === allowables.dummyTeam._id) {
      awayTeamName = data.placeholderAwayTeamName || allowables.dummyTeam.name;
      awayTeamAbbreviation = "";
    }
    delete data.placeholderHomeTeamName;
    delete data.placeholderAwayTeamName;

    divisions.forEach((l) => {
      if (l.name === data.divisionName) {
        divisionID = l._id;
        sport = l.sport;
      }
    });
    referees.forEach((r) => {
      if (r.email === data.refereeEmail) {
        refereeID = r._id;
        refereeName = r.name;
      }
    });

    const fieldName =
      this.state.fields.find((f) => f._id === data.fieldID)?.name || "";
    if (!fieldName) data.fieldID = null;

    // dummy matches cannot be set in match form after updating to allow custom placeholder names
    data.dummyMatch = false;

    data.homeTeamName = homeTeamName;
    data.awayTeamName = awayTeamName;
    data.homeTeamAbbreviation = homeTeamAbbreviation;
    data.awayTeamAbbreviation = awayTeamAbbreviation;
    data.fieldName = fieldName;
    data.refereeID = refereeID;
    data.refereeName = refereeName;
    data.matchComplete = 0;
    data.matchAccepted = 0;
    data.divisionID = divisionID;
    data.homeTeamGoals = "";
    data.homeTeamPKs = "";
    data.awayTeamGoals = "";
    data.awayTeamPKs = "";
    data.homeTeamProtest = true;
    data.awayTeamProtest = true;
    data.homeTeamCaptainID = homeTeamCaptainID;
    data.awayTeamCaptainID = awayTeamCaptainID;
    data.sport = sport;
    data.refereeComments = "";

    // if date is not changed when editing a match the formdate will error as the date is already in the correct format
    try {
      data.dateTime = allowables.formDate(data.dateTime, false, true);
    } catch (ex) {
      //
    }

    const response = await this.props.saveMatch(matchID, data, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) return this.props.history.replace("/matches");
    this.setState({ apiError: response.data });
    toast.error(this.state.apiError);
    if (this.state.apiError) {
      delete data.dateTime;
      this.setState({ data: this.mapToViewModel(data) });
    }
    this.context.setLoading(false);
  };

  render() {
    const { currentMatch, baseState, deleteOpen } = this.state;
    const teams = allowables.teams(this.props.getCurrentUser().sport);

    const renderTypeAndGroup = () => {
      const type = [
        this.renderSelect("type", "* Match Type", this.state.types, "", {
          header: "Match Type",
          text: `Which match type you select will affect where the match is displayed once completed.`,
          direction: "right",
          className: "icon-mobile",
        }),
      ];
      if (this.state.data.type === "Group")
        type.push(
          this.renderSelect(
            "groupName",
            "* Group Name",
            this.state.groups.filter(
              (g) => g.divisionName === this.state.data.divisionName
            ),
            "",
            {
              header: "Group Name",
              text: `If selecting the group match type this field is required.
        Only teams in the same group can play in group matches against each other.`,
              direction: "right",
              className: "icon-mobile",
            }
          )
        );

      return type;
    };

    let { dateTime } = this.state.data;
    if (dateTime) dateTime = new Date(Date.parse(this.state.data.dateTime));
    return (
      <div className="centered-small-input-area">
        <div className="row" style={{ zIndex: 1 }}>
          {this.props.matchID !== "new" && (
            <div className="col">
              <button
                className="btn btn-sm btn-dark"
                onClick={() => this.toggleModal("deleteOpen")}
              >
                Delete Match
              </button>
            </div>
          )}
        </div>

        <form onSubmit={this.handleSubmit}>
          <MiniHeader
            tooltip={{
              header: (!currentMatch ? "New" : "Edit") + " Match",
              listHeader: "Required Fields",
              listItems: [
                "Division",
                "Match Type",
                "Home Team",
                "Away Team",
                `${allowables.refOrUmp(this.props.org?.sport)} Name`,
                "Group Name (if Match Type is Group)",
              ],
              text: `Round and Match Number are optional but are required for playoff brackets to display correctly.
                Standings and brackets are calculated separately for Regular Season, Group and Playoff Match Types,
                 but leaderboards are always calculated together for each Division.`,
            }}
          >
            {!currentMatch ? (
              "Create New Match"
            ) : (
              <div>
                <small>Edit:</small> {currentMatch}
              </div>
            )}
          </MiniHeader>
          <div className="form-divided-section">
            <SideBySideView
              Components={[
                this.renderSelect(
                  "divisionName",
                  "* Division",
                  this.state.divisions,
                  "",
                  {
                    header: "Division",
                    text: `This field is required. Every match must be in a division.
                The selected division will affect which teams are available to select.`,
                    direction: "right",
                    className: "icon-mobile",
                  },
                  null,
                  {
                    onClick: () =>
                      navigateTo(
                        "/divisions?q=new",
                        this.props.history,
                        this.context
                      ),
                    tooltipText: "Add New Division",
                    icon: "add",
                    class: "btn-info",
                  }
                ),
                ...renderTypeAndGroup(),
              ]}
            />
          </div>
          <MiniHeader marginTop={20}>Team Info</MiniHeader>
          <div className="form-divided-section">
            {this.state.data.divisionName ? (
              <SideBySideView
                Components={teams.map((t) => (
                  <div key={t}>
                    {this.renderSelectByValueOption(
                      t + "TeamID",
                      `* ${allowables.capLetterOne(t)} Team`,
                      [
                        ...(this.state.data.type === "Group"
                          ? this.state.teamsByGroup[this.state.data.groupName]
                            ? this.state.teamsByGroup[this.state.data.groupName]
                            : []
                          : this.state.teams[this.state.data.divisionName]
                          ? this.state.teams[this.state.data.divisionName]
                          : []),
                        allowables.dummyTeam,
                      ],
                      "_id",
                      "",
                      null,
                      null,
                      {
                        onClick: () =>
                          navigateTo(
                            "/teams?q=new",
                            this.props.history,
                            this.context
                          ),
                        tooltipText: "Add New Team",
                        icon: "add",
                        class: "btn-info",
                      }
                    )}
                    {allowables.dummyTeam._id ===
                      this.state.data[t + "TeamID"] &&
                      this.renderInput(
                        "placeholder" + allowables.capLetterOne(t) + "TeamName",
                        allowables.capLetterOne(t) + " Team Placeholder Name",
                        "",
                        "",
                        "",
                        "",
                        "",
                        {
                          header: "Placeholder Team Name",
                          text: `If you do not know the team that will be playing in this spot yet you can enter a placeholder name to display on the schedule.
                            For example: "Winner of Division One". If this is left blank the default "Select Team" will be displayed`,
                          direction: "right",
                          className: "icon-mobile",
                        }
                      )}
                  </div>
                ))}
              />
            ) : (
              <p className="text-center">
                Select a Division to show team options
              </p>
            )}
          </div>
          <MiniHeader
            iconName={this.state.infoSectionOpen ? "chevronup" : "chevrondown"}
            marginTop={20}
            onClick={() =>
              this.setState({
                infoSectionOpen: !this.state.infoSectionOpen,
              })
            }
            tabIndex="0"
            id="infoSection"
            scrollOnClick={!this.state.infoSectionOpen}
          >
            Match Info
          </MiniHeader>
          {this.state.infoSectionOpen && (
            <div className="form-divided-section">
              <SideBySideView
                Components={[
                  this.renderDateTimePicker("dateTime", "Date & Time", "", {
                    header: "Date & Time",
                    text: `Date and time are not required but are recommended. All times are local, no adjustment is made for time zones.
                        If no date is provided the match will not display on the match calendar.
                        The datetime-local date picker is not currently supported by Firefox or Safari.
                        If using either of those browsers please enter your date in the format "MM/dd/yyyy HH:mm"`,
                    direction: "right",
                    className: "icon-mobile",
                  }),
                  this.renderSelectByValueOption(
                    "fieldID",
                    `Field`,
                    [...this.state.fields],
                    "_id",
                    "",
                    {
                      header: "Field",
                      text: `Pick the field on which the match will be played.`,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    null,
                    {
                      onClick: () =>
                        navigateTo(
                          "/fields?q=new",
                          this.props.history,
                          this.context
                        ),
                      tooltipText: "Add New Field",
                      icon: "add",
                      class: "btn-info",
                    }
                  ),
                ]}
              />
              <SideBySideView
                Components={[
                  this.renderInput(
                    "round",
                    "Round",
                    "",
                    "number",
                    "",
                    "",
                    "0",
                    {
                      header: "Round",
                      text: `Round is mostly used for playoff brackets, but can be used to sort regular season or group matches.
                  If you are manually creating a playoff bracket please review the help section on that subject.`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  ),
                  this.renderInput(
                    "matchNumber",
                    "Match Number",
                    "",
                    "number",
                    "",
                    "",
                    "0",
                    {
                      header: "Match Number",
                      text: `Match number is only used for playoff brackets.
                  If you are manually creating a playoff bracket please review the help section on that subject.`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  ),
                ]}
              />
              {this.renderSwitch(
                "sendNotifications",
                "Send Push Notifications",
                "",
                {
                  header: "Send Push Notifications",
                  text: `Check this box to send push notifications about this match to players and ${allowables.refOrUmp(
                    this.props.org?.sport
                  )}s using the mobile app.
              Notifications are only sent if you set a Date & Time for this match.
              By default they are not sent to allow for admins to adjust schedules peacefully.
              `,
                  direction: "right",
                  className: "icon-mobile",
                },
                true
              )}
            </div>
          )}
          <MiniHeader
            iconName={
              this.state.refereeSectionOpen ? "chevronup" : "chevrondown"
            }
            marginTop={20}
            onClick={() =>
              this.setState({
                refereeSectionOpen: !this.state.refereeSectionOpen,
              })
            }
            tabIndex="0"
            id="refereeSection"
            scrollOnClick={!this.state.refereeSectionOpen}
          >
            {allowables.refOrUmp(this.props.org?.sport)} Info
          </MiniHeader>
          {this.state.refereeSectionOpen && (
            <div className="form-divided-section">
              <SideBySideView
                Components={[
                  this.renderSelectByValueOption(
                    "refereeEmail",
                    `* ${allowables.refOrUmp(this.props.org?.sport)} Name`,
                    this.state.referees,
                    "email",
                    "",
                    {
                      header: allowables.refOrUmp(this.props.org?.sport),
                      text: `This field is required. A match must always be assigned to a ${allowables.refOrUmp(
                        this.props.org?.sport
                      )}.
                  Only the ${allowables.refOrUmp(
                    this.props.org?.sport
                  )} chosen will be able to access the page to take charge of this match.
                  This can be updated at any time,
                  but if the ${allowables.refOrUmp(
                    this.props.org?.sport
                  )} is changed when the match has already been started then the ${allowables.refOrUmp(
                        this.props.org?.sport
                      )} will not be able to submit their scorecard.`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  ),
                  this.state.data.refereeEmail && (
                    <div>
                      <p>{allowables.refOrUmp(this.props.org?.sport)} Email:</p>
                      <a href={`mailto:${this.state.data.refereeEmail}`}>
                        {this.state.data.refereeEmail}
                      </a>
                    </div>
                  ),
                ]}
              />
            </div>
          )}
          <div className="row">
            <div className="col text-left">
              {this.renderValidatedButton("Save")}
            </div>
            <div className="col text-center">
              {this.renderResetButton("Reset")}
            </div>
            <div className="col text-right">
              {this.renderCancelButton("Cancel")}
            </div>
          </div>
          {this.state.apiError && (
            <div className="alert alert-warning">{this.state.apiError}</div>
          )}
        </form>
        <CustomConfirm
          dialog={`Are you sure you want to delete ${baseState.homeTeamName} vs ${baseState.awayTeamName}`}
          callback={this.handleDelete}
          isOpen={deleteOpen}
          close={this.toggleModal}
          id={"deleteOpen"}
          focused={true}
          yesNo={true}
        />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default MatchForm;
