import http from "./httpService";

export async function startLiveScorecard(matchID) {
  try {
    return await http.post(http.liveScoreEndpoint + "/byauth/" + matchID);
  } catch (ex) {
    return ex.response;
  }
}

export async function removeLiveScorecard(matchID) {
  try {
    return await http.put(
      http.liveScoreEndpoint + "/removescorecard/byauth/" + matchID
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function sendEventToLiveScorecard(matchID, scorecard) {
  try {
    return await http.put(http.liveScoreEndpoint + "/event/byauth/" + matchID, {
      scorecard,
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function removeEventFromLiveScorecard(matchID, scorecard) {
  try {
    return await http.put(
      http.liveScoreEndpoint + "/removeevent/byauth/" + matchID,
      {
        scorecard,
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function syncLiveScorecard(matchID, scorecard) {
  try {
    return await http.put(http.liveScoreEndpoint + "/sync/byauth/" + matchID, {
      scorecard,
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function getLiveScorecard(matchID, authed, extension) {
  try {
    if (authed)
      return await http.get(http.liveScoreEndpoint + "/byauth/" + matchID);
    else
      return await http.get(
        http.liveScoreEndpoint + "/byextension/" + extension + "/" + matchID
      );
  } catch (ex) {
    return ex.response;
  }
}

export async function getAllLiveScorecards() {
  try {
    return await http.get(http.liveScoreEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function getAllLiveScorecardsByExtension(extension) {
  try {
    return await http.get(
      http.liveScoreEndpoint + "/all/byextension/" + extension
    );
  } catch (ex) {
    return ex.response;
  }
}
