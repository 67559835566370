import React, { useState, useEffect } from "react";
import MiniHeader from "../common/pageComponents/miniHeader";
import NonFormSelect from "../common/form/nonFormSelect";

const defaultOptions = [
  {
    _id: "all",
    name: "All",
    text: "Receive all emails on this topic.",
  },
  {
    _id: "none",
    name: "None",
    text: "Do not receive any emails on this topic.",
  },
];

const NotificationSettings = ({ user, onUpdateSettings }) => {
  const [settings, setSettings] = useState([
    {
      playerOnly: true,
      name: "team",
      label: "Team Message Notifications",
      value: "all",
      options: [
        {
          _id: "daily",
          name: "Daily",
          text: "Receive an email once per day only if there are new messages.",
        },
        {
          _id: "captain",
          name: "Captain",
          text: "Only receive an email if the team captain posts a message.",
        },
        {
          _id: "all",
          name: "All",
          text: "Receive an email whenever a new message is posted (limited to one email per hour).",
        },
        defaultOptions[1],
      ],
      tooltipHeader:
        "Notifications sent for the team messaging system. All messages will be available in the message center, this setting only controls when you receive emails.",
    },
    {
      name: "admin",
      label: "League Admin Notifications",
      value: "all",
      options: defaultOptions,
      tooltipHeader:
        "Emails initiated by your league admins, such as schedule notifications and changes.",
    },
    {
      name: "ultimateScoreboard",
      label: "Ultimate Scoreboard Emails",
      value: "all",
      options: defaultOptions,
      tooltipHeader:
        "Emails sent by Ultimate Scoreboard staff, such as new feature or promotional information. This setting will not effect important information about your account or support responses.",
    },
  ]);

  useEffect(() => {
    settings.forEach((setting) => {
      handleChangeSetting({
        target: {
          name: setting.name,
          value: user?.notificationSettings?.[setting.name] || setting.value,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChangeSetting = (event) => {
    const currentSettings = [...settings];
    const idx = currentSettings.findIndex((s) => s.name === event.target.name);
    const thisSetting = currentSettings[idx];
    thisSetting.value = event.target.value;
    currentSettings.splice(idx, 1, thisSetting);
    setSettings(currentSettings);
  };

  return (
    <div>
      <MiniHeader>Email Notification Settings</MiniHeader>
      <div className="form-divided-section">
        {settings.map((setting, idx) => {
          if (setting.playerOnly && !user?.role?.includes("player"))
            return null;
          return (
            <div key={idx}>
              <NonFormSelect
                noDefaultOption={true}
                name={setting.name}
                label={setting.label}
                options={setting.options}
                onChange={handleChangeSetting}
                value={setting.value}
                tooltip={{
                  header: setting.label,
                  direction: "right",
                  text: (
                    <div>
                      <p>{setting.tooltipHeader}</p>
                      <ul>
                        {setting.options.map((o, idx) => (
                          <li key={idx}>
                            <p>
                              <b>{o.name}</b>: {o.text}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
                }}
              />
            </div>
          );
        })}
        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            let mapped = {};
            settings.forEach((setting) => {
              mapped[setting.name] = setting.value;
            });
            onUpdateSettings(mapped);
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default NotificationSettings;
