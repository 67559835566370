import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <React.Fragment>
      <div
        className="bg-danger text-white"
        style={{
          borderRadius: "5px",
          paddingTop: "5px",
          paddingBottom: "2px",
        }}
      >
        <h4 className="text-center">
          <b>{message}</b>
        </h4>
        <div
          className="clickable text-center text-white"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </div>
      </div>
    </React.Fragment>
  );
};

export default ErrorMessage;
