import React from "react";

import BasicModal from "../common/pageComponents/basicModal";
import MatchInfoView from "../matches/matchInfoView";
import { popupStyle } from "../../utils/styleUtil";

const SpectateMatchModal = ({ id, org, match, popupOpen, closeModal }) => {
  if (!match) return null;

  return (
    <BasicModal
      id={id}
      isOpen={popupOpen}
      onClose={closeModal}
      style={{
        content: {
          ...popupStyle.content,
        },
      }}
    >
      <MatchInfoView
        matchID={match._id}
        org={org}
        authed={false}
        spectate={true}
      />
    </BasicModal>
  );
};

export default SpectateMatchModal;
