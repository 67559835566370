import React from "react";

import allowables from "../../../utils/allowables";
import MiniHeader from "../pageComponents/miniHeader";
import IconRender from "../icons/iconRender";
import { CLink } from "../customs/customLinks";

const InfoComponent = ({
  data,
  fields,
  userRole = "",
  handleSwitchToEditMode,
}) => {
  if (!data || fields.length === 0) return null;

  const renderData = (field) => {
    let prop = field.property;
    let text = data[prop];
    if (prop.includes(".")) {
      const props = prop.split(".");
      if (data[props[0]]) text = data[props[0]][props[1]];
    }
    if (text === undefined) return null;

    let info;

    if (field.type === "name") {
      text = allowables.splitName(text);
    }
    info = <b>{text}</b>;

    if (field.type === "color")
      info = (
        <div
          className="col color-square-large text-left"
          style={{
            backgroundColor: data[prop],
            height: 20,
            marginTop: 2,
          }}
        />
      );
    else if (field.type === "checkbox")
      info = (
        <IconRender
          name={(text ? "" : "un") + "checkedbox"}
          className="icon-raised"
        />
      );
    else if (field.type === "email") {
      info = <a href={`mailto:${text}`}>{text}</a>;
    } else if (
      field.link &&
      (!field.linkIfRole || userRole.includes(field.linkIfRole))
    ) {
      info = <CLink path={field.link}>{text}</CLink>;
    }

    return (
      <div className="row" key={prop}>
        <div className="col text-right">{field.name}</div>
        <div className="col text-left">{info}</div>
      </div>
    );
  };

  return (
    <div className="centered-small-input-area">
      <MiniHeader
        onClick={handleSwitchToEditMode || null}
        iconName={handleSwitchToEditMode ? "edit" : null}
      >
        {allowables.splitName(data.name)}
      </MiniHeader>
      <div className="form-divided-section">
        {fields.map((field, idx) => renderData(field))}
      </div>
    </div>
  );
};

export default InfoComponent;
