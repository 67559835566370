import React from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

import { CLink } from "../common/customs/customLinks";

const CodeOfConductStatus = ({ org, user }) => {
  if (
    !org ||
    !user ||
    !user.playerID ||
    !user.role ||
    !user.role.includes("player")
  )
    return null;
  const checkCocAcceptance = () => {
    return (
      org.codeOfConduct &&
      org.codeOfConduct.length > 0 &&
      user.codeOfConduct &&
      user.codeOfConduct.accepted
    );
  };

  return (
    <React.Fragment>
      {checkCocAcceptance() ? (
        <CLink path="/codeofconduct" style={{ margin: 5 }}>
          <ImCheckboxChecked />
          &nbsp;&nbsp;&nbsp;Code of conduct accepted
        </CLink>
      ) : org.codeOfConduct && org.codeOfConduct.length > 0 ? (
        <CLink path="/codeofconduct" style={{ margin: 5 }}>
          <ImCheckboxUnchecked />
          &nbsp;&nbsp;&nbsp;Read and accept the code of conduct
        </CLink>
      ) : null}
    </React.Fragment>
  );
};

export default CodeOfConductStatus;
