import React from "react";

import MatchHeader from "./../assignedMatch/matchHeader";
import MatchInfoHeader from "./matchInfoHeader";

const ReviewHeaderSegment = ({ data, teams, spectate, hideCompletionInfo }) => {
  if (!data) return null;

  return (
    <React.Fragment>
      <MatchInfoHeader
        match={data}
        spectate={spectate}
        hideCompletionInfo={hideCompletionInfo}
      />
      <MatchHeader
        match={data}
        teamOrder={teams}
        goals={{ home: data.homeTeamGoals, away: data.awayTeamGoals }}
        penalties={{ home: data.homeTeamPKs, away: data.awayTeamPKs }}
        spectate={spectate}
      />
    </React.Fragment>
  );
};

export default ReviewHeaderSegment;
