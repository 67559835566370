import React from "react";

import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";

const MatchesCardView = ({ matches, onClick, liveScorecards }) => {
  if (!matches.length)
    return (
      <p className="text-center card-padding">
        There are no matches to display
      </p>
    );

  const teamOrder = allowables.teams(matches[0].sport);

  const addMatchInfo = (match) => {
    match.winner =
      match.homeTeamGoals > match.awayTeamGoals
        ? "home"
        : match.awayTeamGoals > match.homeTeamGoals
        ? "away"
        : match.homeTeamPKs > match.awayTeamPKs
        ? "home"
        : match.awayTeamPKs > match.homeTeamPKs
        ? "away"
        : "tie";
  };

  const renderTeam = (match, team) => {
    const showScore = match.matchComplete || liveScorecards;
    let score = (
      <div style={{ flex: "15%" }}>
        {match[team + (liveScorecards ? "TeamScore" : "TeamGoals")]}
      </div>
    );
    if (match.homeTeamPKs || match.awayTeamPKs) {
      score = (
        <div style={{ flex: "15%" }}>
          {match[team + (liveScorecards ? "TeamScore" : "TeamGoals")]}{" "}
          <small>({match[team + "TeamPKs"]})</small>
        </div>
      );
    }
    const fontWeight =
      match.matchAccepted && match.winner === team ? "bold" : "regular";
    const teamColor1 = match[team + "TeamLogo"]?.teamColor1;
    const teamColor2 = match[team + "TeamLogo"]?.teamColor2;
    const color = allowables.getTextColor(teamColor1);

    return (
      <div
        className="cardview-text cardview-pop"
        style={{
          display: "flex",
          fontWeight,
        }}
      >
        <div
          className="round-corners"
          style={{
            flex: "5%",
            backgroundColor: teamColor1,
            color,
            margin: 2,
            height: 25,
            width: 25,
          }}
        />
        <div style={{ flex: "10%" }}>
          {match[team + "TeamLogo"]?.logo && (
            <TeamLogo
              team={match[team + "TeamLogo"]}
              name={match[team + "TeamName"]}
              size={30}
            />
          )}
        </div>
        <div style={{ flex: "70%", marginLeft: 5 }}>
          {match[team + "TeamName"]}
        </div>
        {showScore ? score : <div style={{ flex: "10%" }} />}
        <div
          className="round-corners"
          style={{
            flex: "5%",
            backgroundColor: teamColor2,
            color,
            margin: 2,
            height: 25,
            width: 25,
          }}
        />
      </div>
    );
  };

  const renderDate = (match) => {
    const date = allowables.dateTime(match.dateTime);
    return (
      <div className="cardview-text">
        <IconRender name="calendar" className="icon-raised" />
        &nbsp;{date.date}&nbsp;{date.time}
      </div>
    );
  };

  const renderMoreInfo = (match) => {
    return (
      <div>
        <div className="cardview-text row">
          <div className="col">
            <IconRender name="division" className="icon-raised" />
            &nbsp;{match.divisionName}
          </div>
          <div className="col">
            <IconRender name="star" className="icon-raised" />
            &nbsp;{match.type}
          </div>
        </div>
        {match.fieldName && (
          <div className="col">
            <IconRender name="map" className="icon-raised" />
            &nbsp;
            {match.fieldName}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="cardview">
      {matches.map((m, idx) => {
        addMatchInfo(m);
        return (
          <div key={idx}>
            <div
              onClick={onClick ? () => onClick(m) : null}
              className="cardview-card clickable"
            >
              {renderTeam(m, teamOrder[0])}
              {m.dateTime && renderDate(m)}
              {renderMoreInfo(m)}
              {renderTeam(m, teamOrder[1])}
            </div>
            <div className="card-separator" />
          </div>
        );
      })}
    </div>
  );
};

export default MatchesCardView;
