import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import TextArea from "./textArea";
import Select from "./select";
import ConditionalSelect from "./conditionalSelect";
import SelectByValueOption from "./selectByValueOption";
import DateTimePicker from "./dateTimePicker";
import MultiFieldInput from "./multiFieldInput";
import ColorPicker from "./colorPicker";
import Checkbox from "./checkBox";
import Switch from "./switch";
import MiniHeader from "../pageComponents/miniHeader";
import allowables from "../../../utils/allowables";
import { navigateTo } from "../customs/customLinks";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return this.state.disabled || null;
    const errors = {};
    for (let detail of error.details) errors[detail.path[0]] = detail.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }, isMultiSelect) => {
    const errors = { ...this.state.errors };
    const value = isMultiSelect
      ? this.state.data[input.name].find((d) => d === input.value)
        ? this.state.data[input.name].filter((d) => d !== input.value)
        : [...this.state.data[input.name], input.value]
      : input.value;
    const errorMessage = this.validateProperty({
      name: input.name,
      value,
    });
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = value;
    this.setState({ data, errors, apiError: null });
  };

  handleMultiFieldInputChange = (e, title, clearAll) => {
    let current;
    let data = { ...this.state.data };
    if (clearAll) data[title] = [];
    else if (title) {
      current = [...this.state.data[title]];
      if (current.length < 2) return;
      current.splice(current.length - 1, 1);
      data[title] = current;
    } else {
      const [section, sectionNumber, title] = e.target.id.split(",");
      const value = e.target.value;
      current = [...this.state.data[title]];
      let currentSection = { ...current[sectionNumber] };
      if (currentSection) currentSection[section] = value;
      else currentSection = { [section]: value };
      current[sectionNumber] = currentSection;
      data[title].forEach((m) => {
        let empty = true;
        Object.keys(m).forEach((k) => {
          if (m[k]) empty = false;
        });
        if (empty && current.length > 1) current.splice(sectionNumber, 1);
      });
      data[title] = current;
    }
    this.setState({ data });
  };

  renderValidatedButton(label) {
    return (
      <button name={label} disabled={this.validate()} className="btn btn-info">
        {label}
      </button>
    );
  }

  renderCancelButton(label, onClickOverride) {
    return (
      <button
        name={label}
        className="btn btn-dark"
        onClick={onClickOverride || this.handleCancel}
      >
        {label}
      </button>
    );
  }

  renderResetButton(label, onClickOverride) {
    return (
      <button
        name={label}
        className="btn btn-light"
        onClick={onClickOverride || this.handleReset}
      >
        {label}
      </button>
    );
  }

  renderInput(
    name,
    label,
    autoFocus = "",
    type = "text",
    disabled = "",
    max = "",
    min = "",
    tooltip,
    step,
    paidFeature,
    placeholder,
    autocomplete,
    boldLabel
  ) {
    const { data, errors } = this.state;

    return (
      <Input
        name={name}
        type={type}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        autoFocus={autoFocus}
        error={errors[name]}
        disabled={disabled}
        max={max}
        min={min}
        tooltip={tooltip}
        step={step}
        paidFeature={paidFeature}
        placeholder={placeholder}
        autocomplete={autocomplete}
        boldLabel={boldLabel}
      />
    );
  }

  renderTextArea(
    name,
    label,
    disabled = "",
    rows = "5",
    tooltip,
    paidFeature,
    placeholder,
    boldLabel
  ) {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
        tooltip={tooltip}
        paidFeature={paidFeature}
        rows={rows}
        placeholder={placeholder}
        boldLabel={boldLabel}
      />
    );
  }

  renderColorPicker(name, label, disabled = "", tooltip, paidFeature) {
    const { data, errors } = this.state;
    const onChange = (value) => {
      this.handleChange({ currentTarget: { name, value } });
    };
    return (
      <ColorPicker
        name={name}
        value={data[name]}
        label={label}
        onChange={onChange}
        error={errors[name]}
        disabled={disabled}
        tooltip={tooltip}
        paidFeature={paidFeature}
      />
    );
  }

  renderCheckbox(name, label, disabled = "", tooltip, paidFeature) {
    const { data, errors } = this.state;
    const onChange = () => {
      if (disabled) return;
      this.handleChange({
        currentTarget: { name, value: !data[name] },
      });
    };
    return (
      <Checkbox
        onChange={onChange}
        value={data[name]}
        label={label}
        tooltip={tooltip}
        paidFeature={paidFeature}
        disabled={disabled}
        error={errors[name]}
        id={name}
      />
    );
  }

  renderSwitch(name, label, disabled = "", tooltip, paidFeature) {
    const { data, errors } = this.state;
    const onChange = () => {
      this.handleChange({
        currentTarget: { name, value: !data[name] },
      });
    };
    return (
      <Switch
        onChange={onChange}
        value={data[name]}
        label={label}
        tooltip={tooltip}
        paidFeature={paidFeature}
        disabled={disabled}
        error={errors[name]}
      />
    );
  }

  renderSelect(
    name,
    label,
    options,
    disabled,
    tooltip,
    paidFeature,
    labelButton
  ) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
        tooltip={tooltip}
        paidFeature={paidFeature}
        labelButton={labelButton}
      />
    );
  }

  renderSelectByValueOption(
    name,
    label,
    options,
    valueOption,
    disabled,
    tooltip,
    paidFeature,
    labelButton,
    multiSelect
  ) {
    const { data, errors } = this.state;
    return (
      <SelectByValueOption
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={(event) => this.handleChange(event, multiSelect)}
        error={errors[name]}
        disabled={disabled}
        valueOption={valueOption}
        tooltip={tooltip}
        paidFeature={paidFeature}
        labelButton={labelButton}
        multiSelect={multiSelect}
      />
    );
  }

  renderConditionalSelect(
    name,
    label,
    options,
    condition,
    disabled,
    extraOptions,
    paidFeature
  ) {
    const { data, errors } = this.state;
    return (
      <ConditionalSelect
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        condition={condition}
        disabled={disabled}
        extraOptions={extraOptions}
        paidFeature={paidFeature}
      />
    );
  }

  renderMultiSelect(multiSelect) {
    const ms = multiSelect.map((m) => (
      <div className="form-group" key={m.name}>
        <label htmlFor={m.name}>{m.label}</label>
        <select name={m.name} className="form-control">
          <option />
          {m.options.map((o) => (
            <option key={o.name} value={o.name}>
              {o.name}
            </option>
          ))}
        </select>
      </div>
    ));
    return ms;
  }

  renderMultiFieldInput(
    title,
    properties,
    dataProperty,
    hasOwnHeader,
    sectionName,
    maxFields
  ) {
    const { data } = this.state;

    return (
      <MultiFieldInput
        onChange={this.handleMultiFieldInputChange}
        title={title}
        dataProperty={dataProperty}
        properties={properties}
        values={data[dataProperty]}
        hasOwnHeader={hasOwnHeader}
        sectionName={sectionName}
        maxFields={maxFields}
      />
    );
  }

  renderDateTimePicker(name, label, disabled, tooltip, noTime) {
    const { data, errors } = this.state;
    const onChange = (value) => {
      this.handleChange({ currentTarget: { name, value } });
    };
    return (
      <DateTimePicker
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        disabled={disabled}
        onChange={onChange}
        tooltip={tooltip}
        noTime={noTime}
      />
    );
  }

  renderWholeForm(
    title,
    navigateToOnCancel,
    mainTooltip,
    inputs,
    shouldHideButtons,
    settings = {
      noHeader: false,
      formClass: "centered-small-input-area",
      inputDivClass: "form-divided-section",
    }
  ) {
    const isNew = this.props?.location?.search?.includes("new");
    return (
      <form onSubmit={this.handleSubmit} className={settings.formClass}>
        {settings.noHeader ? null : (
          <MiniHeader
            tooltip={
              mainTooltip
                ? {
                    header: (isNew ? "Create" : "Edit") + " " + title,
                    ...mainTooltip,
                  }
                : null
            }
          >
            {isNew ? (
              `Create New ${title}`
            ) : (
              <div>
                <small>Edit: </small>
                {allowables.splitName(this.state.baseState.name)}
              </div>
            )}
          </MiniHeader>
        )}
        <div className={settings.inputDivClass}>
          {inputs}
          {this.state.apiError ? (
            <div className="api-error">{this.state.apiError}</div>
          ) : null}
          {!shouldHideButtons && (
            <div className="row">
              <div className="col text-left">
                {this.renderValidatedButton("Save")}
              </div>
              <div className="col text-center">
                {this.renderResetButton("Reset", (event) => {
                  event.preventDefault();
                  this.setState({
                    data: this.mapToViewModel(this.state.baseState),
                    errors: {},
                  });
                })}
              </div>
              <div className="col text-right">
                {navigateToOnCancel
                  ? this.renderCancelButton("Cancel", (event) => {
                      event.preventDefault();
                      navigateTo(
                        navigateToOnCancel,
                        this.props.history,
                        this.context
                      );
                    })
                  : null}
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default Form;
