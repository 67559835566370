import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { navigateTo } from "../customs/customLinks";
import Tooltip from "./tooltip";
import HeaderContext from "../../../context/headerContext";

const TooltipButton = ({
  onClick,
  tooltipText,
  tooltipDirection,
  buttonClass,
  children,
  navigatePath,
  disabled,
  testId,
  style,
}) => {
  const header = useContext(HeaderContext);
  const history = useHistory();

  const raiseClick = () => {
    if (navigatePath) navigateTo(navigatePath, history, header);
    else onClick();
  };

  if (!tooltipText)
    return (
      <button className={buttonClass} onClick={onClick}>
        {children}
      </button>
    );

  return (
    <Tooltip
      content={<span>{tooltipText}</span>}
      direction={tooltipDirection || "top-right"}
    >
      <button
        type="button"
        className={"btn " + buttonClass}
        onClick={raiseClick}
        disabled={disabled}
        data-testid={testId}
        style={style}
      >
        {children}
      </button>
    </Tooltip>
  );
};

export default TooltipButton;
