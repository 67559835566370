import React, { Component } from "react";
import toast from "../../utils/toast";

import { getDivisions, getCombinedStats } from "../../services/spectateService";
import { splitQuery } from "../../utils/splitQuery";
import SpectateTeams from "./spectateTeams";
import SpectatePlayers from "./spectatePlayers";
import SpectateMatches from "./spectateMatches";
import SpectateMatchModal from "./spectateMatchModal";
import SpectateStandings from "./spectateStandings";
import SpectateBrackets from "./spectateBrackets";
import SpectateLeaderboard from "./spectateLeaderboard";
import SpectatePlayerModal from "./spectatePlayerModal";
import SpectateQR from "./spectateQR";
import SideBySideView from "../common/pageComponents/sideBySideView";
import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import LoadingPlaceholder from "../common/loading/loadingPlaceholder";
import ModalPicker from "../common/dataSorting/modalPicker";

class SpectateHome extends Component {
  static contextType = HeaderContext;
  state = {
    selectedDivision: "",
    selectedOption: "scorecards",
    selectedTeam: { _id: "all" },
    selectedType: "all",
    matchStatus: "upcoming",
    selectedPlayer: null,
    playerModalOpen: false,
    selectedMatch: null,
    matchModalOpen: false,
    divisions: [],
    teams: [],
    players: [],
    matches: [],
    standings: {
      groupStandings: {},
      groups: [],
      standings: [],
    },
    bracket: [],
    leaderboard: [],
    liveScorecardSelected: false,
    matchID: "",
    localLoading: true,
    selectOptionOpen: false,
    selectedDiamondBoard: "batting",
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const query = splitQuery(this.props.location.search);
    let selectedDivision = this.state.selectedDivision;
    let selectedOption = this.state.selectedOption;
    let selectedTeam = this.state.selectedTeam;
    let selectedType = this.state.selectedType;
    let matchStatus = this.state.matchStatus;
    query.forEach((q) => {
      const each = q.split("=");
      selectedDivision = each[0].includes("division")
        ? each[1]
        : selectedDivision;
      selectedOption = each[0].includes("option") ? each[1] : selectedOption;
      selectedTeam = each[0].includes("teamID") ? each[1] : selectedTeam;
      selectedType = each[0].includes("type") ? each[1] : selectedType;
      matchStatus = each[0].includes("status") ? each[1] : matchStatus;
    });
    this.setState({
      selectedOption,
      selectedType,
      matchStatus,
    });
    const divisionsRes = await getDivisions(this.props.pageOrg._id, {
      callback: this.indicateProgress,
      bar: 1,
    });
    if (divisionsRes.status === 200) {
      this.setState({ divisions: divisionsRes.data, localLoading: false });
      if (!selectedDivision && divisionsRes.data.length > 0) {
        selectedDivision = divisionsRes.data[0]._id;
        selectedTeam = "all";
      }
      if (selectedDivision) {
        this.context.setLoading(false);
        await this.handleSelectDivision(
          selectedDivision,
          selectedTeam,
          divisionsRes.data
        );
      }
    } else toast.error(divisionsRes.data);

    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  pushQueryString = (divisionID, option, teamID, type, status) => {
    const {
      selectedDivision,
      selectedOption,
      selectedTeam,
      selectedType,
      matchStatus,
    } = this.state;
    this.props.history.replace(
      `?division=${
        divisionID ? divisionID : selectedDivision ? selectedDivision._id : ""
      }&option=${option ? option : selectedOption}&teamID=${
        teamID ? teamID : selectedTeam ? selectedTeam._id : ""
      }&matchtype=${type ? type : selectedType}&status=${
        status ? status : matchStatus
      }`
    );
  };

  handleToggle = (component) => {
    this.setState({ [component]: this.state[component] ? false : true });
  };

  toggleSelectOptionOpen = () => {
    return [
      this.state.selectOptionOpen,
      (bool) => this.setState({ selectOptionOpen: bool }),
    ];
  };

  handleSelectDivision = async (divisionId, selectedTeamId, divisions) => {
    const division = (divisions || this.state.divisions).find(
      (d) => d._id === divisionId
    );
    if (!division) return;

    this.context.setLoading(true);
    this.context.setProgress([1]);
    this.setState({
      selectedDivision: division,
    });
    const res = await getCombinedStats(division._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      const selectedTeam = res.data.teams.find(
        (t) => t._id === selectedTeamId
      ) || { _id: "all" };
      this.setState({
        teams: res.data.teams,
        selectedTeam: { _id: "all" },
        players: res.data.players,
        matches: res.data.matches,
        standings: res.data.standings,
        leaderboard: res.data.leaderboard,
      });
      this.pushQueryString(division._id, null, selectedTeam._id);
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleSelectOption = (option) => {
    this.setState({ selectedOption: option });
    this.pushQueryString(null, option);
  };

  handleSelectTeam = (team) => {
    this.setState({ selectedTeam: team });
    this.pushQueryString(null, null, team._id);
  };

  handleSelectType = (type) => {
    this.setState({ selectedType: type });
    this.pushQueryString(null, null, null, type);
  };

  handleSelectMatchStatus = (status) => {
    this.setState({ matchStatus: status });
    this.pushQueryString(null, null, null, null, status);
  };

  handleSelectPlayer = (player) => {
    const playerDoc = this.state.players.find((p) => p._id === player?._id);
    if (!playerDoc) return;
    const sport = this.state.selectedDivision?.sport.toLowerCase();
    let leaderboardPlayer;
    if (sport.includes("diamond")) {
      const batting = this.state.leaderboard.batting.find(
        (p) => p._id === playerDoc._id
      );
      const pitching = this.state.leaderboard.pitching.find(
        (p) => p._id === playerDoc._id
      );
      leaderboardPlayer = {
        batting,
        pitching,
        ...playerDoc,
      };
    } else
      leaderboardPlayer = this.state.leaderboard.find(
        (p) => p._id === playerDoc._id
      );

    const selectedPlayer = leaderboardPlayer ? leaderboardPlayer : playerDoc;
    selectedPlayer.gamesPlayed = this.state.matches.filter(
      (m) =>
        [m.homeTeamID, m.awayTeamID].includes(player.teamID) &&
        m.matchAccepted === 1
    ).length;

    selectedPlayer.teamLogo = this.state.teams.find(
      (t) => t._id === selectedPlayer.teamID
    );

    this.setState({
      selectedPlayer,
      playerModalOpen: true,
    });
  };

  setSelectedDiamondBoard = (board) => {
    this.setState({ selectedDiamondBoard: board });
  };

  handleSelectMatch = (match) => {
    this.setState({
      selectedMatch: match,
      matchModalOpen: true,
    });
  };

  render() {
    const {
      divisions,
      matches,
      teams,
      players,
      standings,
      leaderboard,
      selectedDivision,
      selectedOption,
      selectedType,
      selectedMatch,
      matchModalOpen,
      selectedPlayer,
      selectedDiamondBoard,
      playerModalOpen,
      matchStatus,
      localLoading,
    } = this.state;

    if (localLoading) return <LoadingPlaceholder header={"League Stats"} />;

    return (
      <div>
        <React.Fragment>
          <MiniHeader
            iconName="refresh"
            onClick={() => this.handleSelectDivision(selectedDivision?._id)}
          >
            {this.props.pageOrg?.name} Stats
          </MiniHeader>
          <div className="form-divided-section">
            <SideBySideView
              colClassNames={["col", "col-4 text-center"]}
              minWidth={350}
              Components={[
                <div>
                  <ModalPicker
                    buttonLabel="Division"
                    label="Select Division"
                    options={divisions}
                    onSelect={(division) =>
                      this.handleSelectDivision(division._id)
                    }
                    selectedValue={selectedDivision}
                  />
                  {selectedDivision && (
                    <div>
                      <div style={{ height: 5 }} />
                      <ModalPicker
                        buttonLabel="View"
                        label="Select View"
                        options={[
                          { _id: "scorecards", name: "Scorecards" },
                          { _id: "teams", name: "Teams" },
                          { _id: "players", name: "Players" },
                          { _id: "standings", name: "Standings" },
                          { _id: "brackets", name: "Brackets" },
                          { _id: "leaderboards", name: "Leaderboards" },
                        ]}
                        onSelect={(option) =>
                          this.handleSelectOption(option._id)
                        }
                        selectedValue={selectedOption}
                        externalOpenState={this.toggleSelectOptionOpen()}
                      />
                    </div>
                  )}
                </div>,
                <SpectateQR division={selectedDivision} />,
              ]}
            />
          </div>
          <React.Fragment>
            {selectedDivision && (
              <React.Fragment>
                {selectedOption === "scorecards" && (
                  <SpectateMatches
                    matches={matches.filter((m) => !m.dummyMatch)}
                    sport={selectedDivision?.sport}
                    selectedType={selectedType}
                    onSelectType={this.handleSelectType}
                    onSelectMatch={this.handleSelectMatch}
                    status={matchStatus}
                    onSelectStatus={this.handleSelectMatchStatus}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                  />
                )}
                {selectedOption === "teams" && (
                  <SpectateTeams
                    teams={teams}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                  />
                )}
                {selectedOption === "players" && (
                  <SpectatePlayers
                    players={players}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                    onSelectPlayer={this.handleSelectPlayer}
                  />
                )}
                {selectedOption === "standings" && (
                  <SpectateStandings
                    standings={standings}
                    sport={selectedDivision?.sport.toLowerCase()}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                  />
                )}
                {selectedOption === "brackets" && (
                  <SpectateBrackets
                    matches={matches.filter((m) => m.type === "Playoff")}
                    onSelectMatch={this.handleSelectMatch}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                  />
                )}
                {selectedOption === "leaderboards" && (
                  <SpectateLeaderboard
                    leaderboard={leaderboard}
                    sport={selectedDivision?.sport.toLowerCase()}
                    onHeaderClick={() => this.handleToggle("selectOptionOpen")}
                    onSelectPlayer={this.handleSelectPlayer}
                    selectedDiamondBoard={selectedDiamondBoard}
                    setSelectedDiamondBoard={this.setSelectedDiamondBoard}
                  />
                )}
                <SpectateMatchModal
                  id="match"
                  match={selectedMatch}
                  popupOpen={matchModalOpen}
                  closeModal={() => this.handleToggle("matchModalOpen")}
                  org={this.props.pageOrg}
                />
                <SpectatePlayerModal
                  id="player"
                  player={selectedPlayer}
                  sport={selectedDivision?.sport.toLowerCase()}
                  popupOpen={playerModalOpen}
                  closeModal={() => this.handleToggle("playerModalOpen")}
                  selectedTable={selectedDiamondBoard}
                  setSelectedTable={this.setSelectedDiamondBoard}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </React.Fragment>
      </div>
    );
  }
}

export default SpectateHome;
