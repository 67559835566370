import React from "react";

import MiniHeader from "../../common/pageComponents/miniHeader";
import TooltipButton from "../../common/tooltip/tooltipButton";
import Tooltip from "../../common/tooltip/tooltip";
import { freeTier } from "../../../utils/paymentsUtil";

const Tier = ({
  tiers,
  onSelectTier,
  selectedTier,
  onClickSubscribe,
  nonAuthed,
  buttonDisabled,
}) => {
  const featureMap = {
    free: [
      {
        name: "Schedule Creator",
        description: (
          <p>
            <b>
              Automatically create full league, group, or playoff schedules with
              the click of a button.
            </b>
            <br />
            No matter if you're playing a single day tournament, or a weekly
            league, the scheduler can handle your needs.
          </p>
        ),
      },
      {
        name: "Automated Free Agent Registration",
        description: (
          <p>
            <b>Allow Free Agents to register directly for your league.</b>
            <br />
            Place them on teams at your leisure, or activate our Waiver Wire
            system to allow Team Captains to recruit and move their own Free
            Agents.
          </p>
        ),
      },
      {
        name: "Automated Team Captain Registration",
        description: (
          <p>
            <b>Allow Team Captains to register directly for you league.</b>
            <br />
            Remove the bulk of the signup burden from your shoulders. Team
            Captains can register their players, manage their team information,
            and submit comments on scorecards. Just send the link out, sit back,
            and watch the registrations come in.
          </p>
        ),
      },
      {
        name: "Accept League Dues",
        description: (
          <p>
            <b>Collect your league dues directly on Ultimate Scoreboard</b>
            <br />
            Set your price and away you go. You can choose to collect a bulk
            payment from Team Captains, or individual payments from players.
            Either way you can track payment status right here, and funds are
            deposited to your bank account daily.
          </p>
        ),
      },
      {
        name: "Player Payment before Signup",
        description: (
          <p>
            <b>Require players to pay their league dues before signing up.</b>
            <br />
            If collecting league dues from players, you can require every player
            to pay those dues before they are able to complete their
            registration. No more spending time chasing down payments.
          </p>
        ),
      },
      {
        name: "Virtual Scorecards",
        description: (
          <p>
            <b>Virtual scorecards with simple approval process</b>
            <br />
            Completely cut out any data entry with our virtual scorecards.
            Scorekeepers submit their results directly on the app for admin
            review and acceptance.
          </p>
        ),
      },
      {
        name: "Automatically Tracked League Stats",
        description: (
          <p>
            <b>Tracked standings, brackets, and leaderboards</b>
            <br />
            All division standings, playoff brackets, and individual
            leaderboards are tracked from the results submitted on the virtual
            scorecards. No need for manual calculations or data entry.
          </p>
        ),
      },
      {
        name: "Mailing Lists",
        description: (
          <p>
            <b>
              Create mailing and distribution lists with just a couple clicks
            </b>
            <br />
            Filter your data down to form super targeted mailing lists. Send
            payment reminders to only unpaid players, let all Team Captains know
            about a rule update, email your admins important internal
            information, and so much more.
          </p>
        ),
      },
      {
        name: "External Links",
        description: (
          <p>
            <b>Link to your external sites from Ultimate Scoreboard.</b>
            <br />
            These links will be easily accessible to all your users from their
            Profile drop down, or on your league webpage.
          </p>
        ),
      },
      {
        name: "Vaccine Tracking",
        description: (
          <p>
            <b>Collect user vaccination information.</b>
            <br />
            Review and accept the information before your league starts to
            ensure a safe tournement.
          </p>
        ),
      },
      {
        name: "Custom Flags",
        description: (
          <p>
            <b>Create your own named flags to track user status.</b>
            <br />
            Use these flags to check people in at the fields, distribute swag
            and prizes, or anything else you can think of.
          </p>
        ),
      },
    ],
    basic: [
      {
        name: (
          <p>
            <b>Includes everything from the Free Tier.</b>
          </p>
        ),
      },
      {
        name: "Scorecard Approval",
        description: (
          <p>
            <b>Track team approval of scorecards.</b>
            <br />
            Team Captains can notify admins of any disputes with the final
            scorecard prior to submission, without having to hold the discussion
            on the field.
          </p>
        ),
      },
      {
        name: "Captain Comments",
        description: (
          <p>
            <b>
              Allow Team Captains to leave comments on completed scorecards.
            </b>
            <br />
            If the Team Captain has a disagreement with any aspect of a game
            they can leave their comments on the completed scorecard for your
            review. Along with any scorekeeper comment you will be able to
            review any issues in relative peace.
          </p>
        ),
      },
      {
        name: "League Webpage",
        description: (
          <p>
            <b>
              Create a simple single page website hosted by Ultimate Scoreboard.
            </b>
            <br />
            This website can contain images, embedded video, links to your
            merchandise pages, whatever you need. No coding required.
          </p>
        ),
      },
      {
        name: "Open League Stats",
        description: (
          <p>
            <b>
              Allow anyone, not just your league users, to access your league
              stats from anywhere.
            </b>
            <br />
            Coupled with your league webpage you can make your stats public for
            all fans to be able to watch the action.
          </p>
        ),
      },
      {
        name: "User Images",
        description: (
          <p>
            <b>
              Allow your users to upload their profile picture for easier
              identification on the field.
            </b>
          </p>
        ),
      },
      {
        name: "Code of Conduct",
        description: (
          <p>
            <b>
              Create and track player acceptance of your league code of conduct.
            </b>
          </p>
        ),
      },
      {
        name: "Referee/Umpire Login",
        description: (
          <p>
            <b>
              Create a single login credential to distribute to all your
              referees/umpires.
            </b>
            <br />
            This allows for maximum flexibility when scheduling matches.
          </p>
        ),
      },
    ],
    ultimate: [
      {
        name: (
          <p>
            <b>Includes everything from the Free and Basic Tiers</b>
          </p>
        ),
      },
      {
        name: "Live Scorecards",
        description: (
          <p>
            <b>
              Allow all users to access the live score and receive updates as
              the officials make them.
            </b>
            <br />
            Set up a central screen at your tournament, or display our handy QR
            code and users can access the live score directly on their own
            devices.
          </p>
        ),
      },
      {
        name: "Custom Advertising",
        description: (
          <p>
            <b>Replace our mobile app banner ads with your own.</b>
            <br />
            If you have sponsorship from local businesses, or just want to
            cross-promote your other activities, you can display your ads
            directly in our mobile app, putting them in front of a relevant and
            engaged audience.
            <br />
            <br />
            <small>
              All ads are subject to review and approval by Ulimate Scoreboard,
              contact our customer support team to get started.
            </small>
          </p>
        ),
      },
      {
        name: "Automatic Suspension Tracking",
        description: (
          <p>
            <b>
              Automatically track and suspend players when they receive cards.
            </b>
            <br />
            Set up your suspension rules and don't worry about tracking cards
            any more. Violating players will show as suspended on the scorecard
            for the scorekeeper to see.
          </p>
        ),
      },
      {
        name: "Waiver Wire",
        description: (
          <div>
            <p>
              <b>
                The waiver wire system cuts out the admin work of moving players
                from team to team.
              </b>
            </p>
            <ul>
              <li>
                Players can remove themselves from their team so they can be
                picked up by other teams.
              </li>
              <li>
                Captains can recruit Free Agents and players on the waiver wire,
                settled on a schedule you decide.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "Local Map",
        description: (
          <p>
            <b>
              Display an interactive map of the local area on our mobile app.
            </b>
            <br />
            Set your coordinates for an interactive map of the local area to
            display, complete with location information. Couple this with our
            custom advertising to get your local businesses on your side.
          </p>
        ),
      },
    ],
  };

  return (
    <React.Fragment>
      <MiniHeader>Subscription Features</MiniHeader>
      <div className="form-divided-section">
        <div className="row">
          {[freeTier, ...tiers].map((t, i) => {
            const features = featureMap[t.name.toLowerCase()];
            return (
              <div className="col text-left" key={t.id}>
                <MiniHeader>{t.name}</MiniHeader>
                <div className="form-divided-section">
                  <h5 className="text-center">
                    <b>Register {t.description}</b>
                  </h5>
                  <ul className="usb-logo-ul">
                    {features.map((f, idx) => (
                      <Tooltip
                        content={
                          <span>
                            <div style={{ width: 250, textWrap: "wrap" }}>
                              {f.description}
                            </div>
                          </span>
                        }
                        direction={i === tiers.length ? "left" : "right"}
                        isNull={!f.description}
                        key={idx}
                      >
                        <li>{f.name}</li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <p className="text-center">
          <b>
            Need more teams, have a specific feature request, or need us to add
            a new sport? Contact our sales team at{" "}
            <a href="mailto:sales@ultimatescoreboard.com">
              sales@ultimatescoreboard.com
            </a>
            .
          </b>
        </p>
      </div>
      {!nonAuthed && (
        <div>
          <MiniHeader>Select Your Subscription</MiniHeader>
          <div className="form-divided-section">
            <table className="table table-custom">
              <thead className="thead-custom">
                <tr>
                  <th>Subscription Cycle</th>
                  {tiers.map((t) => (
                    <th key={t.id}>{t.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-custom">
                <tr>
                  <td>Monthly</td>
                  {tiers.map((t) => (
                    <td
                      key={t.id}
                      className={
                        "clickable" +
                        (selectedTier.id === t.id &&
                        selectedTier.cycle.includes("monthly")
                          ? " bg-info text-white"
                          : "")
                      }
                      onClick={() => onSelectTier(t, "monthly")}
                    >
                      ${(t.unit_amounts.monthly.unit_amount / 100).toFixed(2)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Yearly</td>
                  {tiers.map((t) => (
                    <td
                      key={t.id}
                      className={
                        "clickable" +
                        (selectedTier.id === t.id &&
                        selectedTier.cycle.includes("yearly")
                          ? " bg-info text-white"
                          : "")
                      }
                      onClick={() => onSelectTier(t, "yearly")}
                    >
                      ${(t.unit_amounts.yearly.unit_amount / 100).toFixed(2)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>

            <div className="text-right">
              <TooltipButton
                tooltipText={
                  buttonDisabled ? (
                    <div style={{ width: 200 }} className="text-center">
                      <p>
                        <b>You are already subscribed to this tier</b>
                      </p>
                      <p>
                        Please select a different tier to change your
                        subscription
                      </p>
                    </div>
                  ) : (
                    ""
                  )
                }
                tooltipDirection="top-left"
                onClick={onClickSubscribe}
                buttonClass="btn btn-sm btn-info"
                disabled={buttonDisabled}
              >
                Go to confirmation page
              </TooltipButton>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Tier;
